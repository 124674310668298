@import '/src/ui/index.scss';

.control {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__label {
    font-size: 15px;
    font-weight: $font-semi-bold;
    color: $black-100;
  }

  &__description {
    font-size: 14px;
    font-weight: $font-normal;
    color: $black-100;
    margin-bottom: 15px;
  }

  & .ui-select {
    width: 100%;
  }

  &__date-picker {
    width: 230px;
    height: 48px;
  }

  & p {
    all: unset;
  }
}
