.my-files-filter {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;

  &__filters {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    
    &-left {
      display: flex;
      align-items: center;
    }
  }

  &__search-input {
    width: 241px !important;
  }

  &__upload-button {
    display: flex;
    align-items: center;
    overflow: visible;
    white-space: nowrap;
    & svg {
      margin-right: 6px;
    }
  }
}