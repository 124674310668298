@import '/src/ui/index.scss';

body .editor-subject {
  background: $gray-50;
  border: 1px solid $gray-200;
  padding: 11px 20px;
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--focus {
    border-color: $purple-200 !important;
    transition: 200ms ease-out !important;
    box-shadow: $purple-200 0 0 0 1px !important;
  }

  &__cell {
    &--name {
      width: 30%;
    }

    &--weight {
      width: 20%;
    }

    &--benchmark {
      width: 20%;
    }

    &--questions {
      width: 15%;
    }

    &--visible {
      width: 10%;
    }

    &--actions {
      width: 5%;
      display: flex;
      justify-content: flex-end;
    }

    & .ui-select {
      width: auto;
      & .ant-select-arrow {
        right: 3px !important;
      }
    }
  }

  &__remove-subject {
    font-size: 24px;
    padding: 0;
    color: $gray-500;
  }

  &--customer-view {
    .editor-subject__cell--questions {
      margin-left: 55%;
    }
  }

  &__weight-selector,
  &__benchmark-selector {
    .ant-select {
      min-width: 94px;
    }
  }
}
