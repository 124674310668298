@import '/src/ui/index.scss';

.my-files {
  position: relative;
  padding: 30px;

  &__wrapper {
    position: relative;
    background: $white;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
    height: calc(100vh - 200px);
  }

  &__filters {
    padding: px2rem(30);
    border-bottom: 1px solid $gray-200;
    position: sticky;
    top: 0;
    z-index: 9;
    background: white;
  }
}
