@import '/src/ui/index.scss';

$search-icon-size: 36px;

body .editor-subject-list-subject {
  padding: 8px 8px;
  color: $black-100;
  width: 100%;
  height: auto;
  text-align: left;
  display: flex;
  align-items: center;
  border: 1px solid transparent;

  &:nth-child(odd):not(.editor-subject-list__subject--selected, :hover, :focus, :active) {
    background: $gray-50;
  }

  &__drag-icon-wrapper {
    width: 20px;
    opacity: 0;
    cursor: move;
    transition: $regular-transition;
  }

  &__remove-subject {
    font-size: 20px;
    padding: 0;
    color: $gray-500;
    opacity: 0;
  }

  &:focus,
  &:active {
    background: $white;
    color: $black-100;

    &:nth-child(odd) {
      background: $gray-50;
    }
  }

  &--selected {
    background: $blue-50 !important;
    font-weight: $font-semi-bold;
  }

  &--active {
    border: 1px solid $blue-500 !important;
    border-left: 4px solid $blue-500 !important;
    padding-left: 10px;
    background: $white !important;

    &:hover {
      border: 1px solid $blue-500 !important;
      border-left: 4px solid $blue-500 !important;
      background: $white !important;
    }
  }

  &:hover {
    .editor-subject-list-subject__remove-subject {
      opacity: 1;
    }
  }

  &__name {
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &--active-edit {
      text-overflow: unset;
    }
  }

  &__weight {
    width: 30%;
    padding-left: 10px;
  }

  &__title-input {
    background: white;
    max-width: 250px !important;
  }

  &__title-input-placeholder {
    color: $gray-400;
    font-weight: $font-light;
  }

  &--drop-active {
    border: 1px dashed $gray-250;
  }

  &--drop-over,
  &--is-dragging {
    background: $blue-200 !important;
    border: 1px solid $blue-500;

    &--disables {
      cursor: not-allowed;
    }
  }

  &__remove-wrapper {
    margin-left: auto;
    width: 20px;
  }
}
