@import '/src/ui/index.scss';

body .editor-answer-bank-list {
  height: calc(100vh - 380px);
  overflow: auto;
  padding-right: 10px;

  &__group {
    background: $white;
    display: flex;
    font-size: 11px;
    font-weight: 600;
    padding: 10px 0;
    margin-right: 10px;
  }

  &__group-title {
    width: 90%;
    color: $purple-400;
  }

  &__group-weight {
    width: 70px;
    color: $gray-400;
    text-align: center;
  }
}
