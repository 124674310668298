@import '../../../../ui/index';

.upload-logo-empty-state {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 144px;
  height: 60px;
  background: $white;
  border: 1px dashed $gray-400;
  border-radius: 5px;
  justify-content: center;
  margin-top: 6px;
  margin-right: 10px;

  span {
    color: $gray-250;
    font-size: 12px;
    font-weight: $font-medium;
  }
  .ui-icon {
    font-size: 20px !important;
  }

  &:hover {
    cursor: pointer;
  }
}
