@import '/src/ui/index.scss';

.evidence-metadata-editor {
  &__header {
    padding: 0 24px 20px 24px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-200;
    align-items: center;
  }

  &__remove-button {
    height: 32px;
  }

  &__title {
    display: flex;
    flex-direction: column;

    strong {
      font-size: $font-16;
      color: $purple-500;
    }
  }

  &__content {
    padding: 0 24px;
  }

  &__category {
    &:not(:last-child) {
      margin-bottom: 36px;
    }
  }

  &__footer {
    padding: 0 24px 24px 24px;
    display: flex;
    justify-content: space-between;
  }

  &__modal {
    .ant-modal-footer {
      justify-content: space-between;
      flex-direction: row-reverse !important;
    }
  }
}
