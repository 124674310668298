@import '/src/ui/index.scss';

body .invite-team-member-button {
  &__button {
    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    color: $dark-blue-800;

    &:hover {
      color: $blue-700;
    }

    &:active {
      color: $dark-blue-400;
    }

    &--purple {
      color: $purple-400;

      &:hover {
        color: $purple-300;
      }

      &:active {
        color: $purple-200;
      }
    }
  }

  &__button-icon {
    font-size: 26px;
    margin-right: 7px;
  }

  &__popup {
    & .ui-popup__title-icon {
      top: 24px !important;
    }

    .ant-modal-body {
      padding: 0 24px 24px;
    }

    .ant-modal-header {
      padding-bottom: 5px;
    }
  }

  &__popup-subtitle {
    padding-left: 22px;
    margin-bottom: 20px;
  }

  &__popup-organization {
    font-weight: 600;
  }
}
