@import '/src/ui/index.scss';

.ui-textarea {
  &-control {
    background: $gray-50;
    border: 1px solid $gray-200;
    border-radius: $border-radius-xs;
    box-sizing: border-box;
    margin: 5px 0;
    outline: none;
    padding: 18px;
    transition: $regular-transition;
    width: 100%;

    & textarea {
      all: unset;
    }

    &:focus {
      border-color: $purple-200;
      transition: $regular-transition;
      box-shadow: $purple-200 0 0 0 1px;
    }

    &:disabled {
      border: 1px solid $gray-200;
      color: $gray-250;
      cursor: not-allowed;
    }

    &--error {
      border: 1px solid $error;

      &:focus {
        border-color: $error;
        transition: $regular-transition;
        box-shadow: $error 0 0 0 1px;
      }
    }
  }
  &__error {
    color: $error;
    font-size: 13px;
    font-weight: 600;
  }

  &__label {
    color: $black-100;
    font-size: 15px;
    font-weight: 500;
  }

  &__wrapper {
    position: relative;
  }

  &__loader {
    position: absolute !important;
    top: 13px;
    right: 10px;

    .anticon {
      color: $gray-250;
    }
  }
}
