@import '/src/ui/index.scss';

body .base-layout-my-vendors-my-customers-switcher {
  &__button {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $black-100;
    font-size: 19px;

    &:hover,
    &:focus,
    &.ant-dropdown-open {
      color: $black-100;
      opacity: 0.9;

      .base-layout-my-vendors-my-customers-switcher__button-arrow {
        margin-top: 2px;
      }
    }
  }

  &__button-text {
    font-weight: 500;
  }

  &__button-ico {
    width: 40px;
    height: 40px;
  }

  &__button-arrow {
    transition: $regular-transition;
    font-size: 14px;
  }

  &__dropdown-menu {
    padding-left: 54px;
  }

  &__organization-name {
    font-weight: $font-light;
    font-size: $font-18;
  }
}
