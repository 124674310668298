@import '../../ui';

.assessment-item {
  height: 74px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid #e0dede;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-semi-bold;
  font-size: 16px;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  max-width: 348px;
  min-width: 0;
  background: $white;
  position: relative;
  line-height: 20px;

  > p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }

  & svg {
    margin-right: 13px;
    color: $black-100;
  }

  &--active {
    border: 2px solid $blue-500;
    & > svg {
      color: $blue-500;
    }
  }
}
