@import '/src/ui/index.scss';

body .assessment-controls-control-note {
  background: $orange-50;
  padding: 14px;
  margin-bottom: 10px;
  border-radius: 5px;
  max-width: 800px;

  &--view-mode {
    cursor: pointer;
    transition: $regular-transition;

    &:hover {
      background: $orange-100;
    }
  }

  &__title {
    font-size: $font-15;
    font-weight: 600;
  }

  &__description {
    margin-bottom: 5px;
  }

  &__view-mode-title-wrapper {
    font-size: $font-12;
    display: flex;
    margin-bottom: 20px;
  }

  &__view-mode-title {
    color: $orange-500;
    font-weight: 600;
    margin-right: 4px;

    &:after {
      content: '|';
      margin-left: 4px;
      color: $gray-200;
    }
  }

  &__internal {
    margin-left: auto;
    color: $orange-500;
    font-style: italic;
  }

  &__view-mode-description {
    padding: 0 15px;
  }
}
