@import '/src/ui/index.scss';

$after-icon-color: #828282;
$after-icon-size: 35px;

.ui-input-control {
  background: $gray-50;
  border: 1px solid $gray-200;
  border-radius: $border-radius-xs;
  box-sizing: border-box;
  height: 48px;
  margin: 5px 0;
  outline: none;
  padding: 18px;
  transition: $regular-transition;

  &:hover {
    border-color: $purple-200;
  }

  &:focus {
    border-color: $purple-200;
    transition: $regular-transition;
    box-shadow: $purple-200 0 0 0 1px;
  }

  &:disabled {
    border: 1px solid $gray-200;
    color: $gray-250;
    cursor: not-allowed;
  }

  &--error {
    border: 1px solid $error !important;

    &:focus {
      border-color: $error;
      transition: $regular-transition;
      box-shadow: $error 0 0 0 1px;
    }
  }

  &--rounded {
    border-radius: $border-radius-lg;
  }

  &--icon {
    padding-right: 45px;
  }

  &--block {
    width: 100%;
  }

  &--size-small {
    height: 30px;
  }

  &--size-medium {
    height: 38px;
  }

  &--inline {
    border: 0;
    height: auto;
    margin: 0;
    padding: 0;

    &:hover,
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
}

body .ui-input {
  display: inline-flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  font-weight: $font-normal;
  position: relative;

  &--block {
    display: block;
  }

  &--filled {
    input {
      font-weight: $font-medium !important;
      color: $black-100;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    & input {
      width: 100%;
    }
  }

  &__after-icon {
    align-content: center;
    align-items: center;
    color: $after-icon-color;
    display: flex;
    height: $after-icon-size;
    justify-content: center;
    justify-items: center;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translate(0, -50%);
    transition: $regular-transition;
    width: $after-icon-size;

    &:hover:not(&--search) {
      background-color: $gray-200;
      border-radius: 100px;
      cursor: pointer;
      transition: $regular-transition;
    }

    &--search {
      font-size: 20px;
      right: 12px;
    }
  }

  &__label {
    color: $black-100;
    font-size: 15px;
    font-weight: 500;
  }

  &__error {
    color: $error;
    font-size: 13px;
    font-weight: 600;
  }

  &__error-checkbox {
    position: absolute;
    top: 30px;
    min-width: 300px;
  }

  &__loader {
    position: absolute;
    top: 16px;
    right: 10px;

    .anticon {
      color: $gray-250;
    }
  }
}

.ui-input--inline {
  .ui-input__loader {
    top: 0;
    right: 5px;
  }
}
