@import '/src/ui/index.scss';

body .assessment-finalized-add-recommendation {
  &__button {
    color: $blue-500;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 4px;

    &:hover,
    &:focus {
      color: $blue-700;
    }

    .ui-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}
