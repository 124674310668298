@import '/src/ui/index.scss';

body .assessment-finalized-status-bar {
  display: flex;
  margin-bottom: 40px;
  height: 76px;

  &__score-benchmark {
    width: 200px;

    .assessment-score-benchmark__score-value {
      font-size: 24px;
      line-height: 35px;
    }

    .assessment-score-benchmark__progress-bar {
      margin-top: 40px;
    }

    .assessment-score-benchmark__benchmark {
      margin-top: 32px;
    }
  }

  &__item {
    border-right: 1px solid $gray-200;
    padding: 8px 20px;
    max-width: 296px;
    text-align: center;

    &:last-child {
      border: 0;
    }

    &--score-benchmark {
      max-width: none;
      padding-left: 0;

      .assessment-finalized-status-bar__item-content {
        padding-bottom: 15px;
      }
    }

    &--overdue-recommendations,
    &--overdue-findings {
      .assessment-finalized-status-bar__item-content {
        height: auto;
      }
    }
  }

  &__item-label {
    padding-top: 6px;
    text-transform: uppercase;
    font-size: $font-11;
    font-weight: 600;
  }

  &__counter-wrapper {
    display: flex;
    height: 54px;
    text-transform: uppercase;
    font-size: $font-11;
    font-weight: 600;
  }

  &__counter-label {
    align-self: center;
    padding-left: 16px;
  }

  &__item-content {
    align-items: center;
    justify-content: center;
    padding-top: 8px;
  }

  &__open-findings,
  &__open-recommendations,
  &__overdue-recommendations {
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    line-height: 18px;
  }
}
