@import '/src/ui/index.scss';

.assessment-finalized-status {
  height: 30px;
  padding: 8px 40px 0 25px;
  margin-bottom: 30px;
  width: 250px;

  &__wrapper {
    position: relative;
  }

  &__line {
    border: 1px dashed $gray-250;
  }

  &__line-completed {
    border: 1px dashed $blue-500;
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__state-completed,
  &__state-finalized,
  &__state-reviewed {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: -8px;
    background: $white;
    width: 18px;
    height: 18px;
    border: 2px solid $gray-250;
    border-radius: 20px;
    text-align: center;

    &:after {
      content: attr(data-text);
      margin-top: 22px;
      color: $gray-300;
      font-size: 10px;
      font-weight: 700;
    }
  }

  &__state-completed {
    left: 0;
  }

  &__state-finalized {
    left: 50%;
  }

  &__state-reviewed {
    left: 100%;
  }

  &__state-completed {
    border-color: $blue-500;

    &:after {
      color: $blue-500;
    }

    &:before {
      font-family: 'icomoon';
      content: '\e923';
      background: $blue-500;
      font-size: 16px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border-radius: 18px;
      margin-top: -2px;
      position: absolute;
    }
  }

  &__state-finalized {
    border-color: $blue-500;

    &:after {
      color: $blue-500;
    }

    &:before {
      content: '';
      background: $blue-500;
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 7px;
      margin-top: 4px;
      position: absolute;
    }
  }

  &__state-completed,
  &__state-finalized,
  &__state-reviewed {
    &--active {
      border-color: $blue-500;

      &:after {
        color: $blue-500;
      }

      &:before {
        content: '';
        background: $blue-500;
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 7px;
        margin-top: 4px;
        position: absolute;
      }
    }

    &--done {
      border-color: $blue-500;

      &:after {
        color: $blue-500;
      }

      &:before {
        font-family: 'icomoon';
        content: '\e923';
        background: $blue-500;
        font-size: 16px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        margin-top: -2px;
        position: absolute;
      }
    }
  }
}
