@import '/src/ui/index.scss';

.so-page {
  &__card .card-with-logo__logo {
    width: 161px;
    height: auto;
  }
  .card-with-logo__content {
    overflow-x: hidden;
    overflow-y: auto;
    height: 600px;
  }

  .card-with-logo-footer {
    position: fixed;
    width: 50%;
    right: 0;
    left: 50%;
  }

  &__card-left-area {
    background: $Turquoise-400;
    padding: 48px;
  }

  & .assessment-controls-control__title {
    margin: 0px !important;
  }
}
