@import '/src/ui/index.scss';

body .editor-other-trigger {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-title {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
  }

  &__close-triggers {
    font-size: 16px;
    padding: 0 3px;
    color: $gray-400;

    &:hover,
    &:focus {
      color: $gray-500;
    }
  }

  &__triggers-body {
    border-left: 1px solid $blue-500;
    padding-left: 10px;
    margin-bottom: 20px;
  }

  &__triggers {
    background: none;

    > .ant-collapse-item > .ant-collapse-header {
      padding: 5px 16px;
    }

    &.ant-collapse-borderless > .ant-collapse-item {
      border: 0;
    }

    .ant-collapse-content-box {
      padding: 0 0 7px 40px;
    }
  }

  &__trigger-header-suffix {
    font-weight: bold;
  }

  &__trigger-header {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__trigger-body {
    border-radius: 7px;
    border: 1px solid $blue-500;
    transition: $regular-transition;
    border-left: 4px solid $blue-500;
    padding: 8px;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
  }

  &__add-question {
    margin-top: 10px;
    margin-left: 0;

    &--first-trigger {
      margin-top: 0;
    }
  }

  &__actions {
    margin-left: auto;
  }

  &__action {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;

    &:hover,
    &:focus,
    &:active {
      color: $blue-500;
    }
  }
}
