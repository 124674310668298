@import '../../ui';

.messenger-notification {
  padding: 16px 12px;
  border-bottom: 1px solid $gray-200;

  &:hover {
    background: $blue-50;
    cursor: pointer;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
  }

  &__date {
    font-size: $font-11;
    color: $gray-400;
  }

  &__vendor-name {
    font-weight: $font-medium;
    margin-left: 8px;
    font-size: $font-11;
    line-height: $font-14;
  }

  &__content {
    padding: 0px 33px 13px 33px;
  }

  &__text {
    font-size: $font-11;
    display: inline-block;
  }
}
