@import '../../ui';

.messenger-notifications-list {
  display: block;
  background: $white;
  width: 391px;
  box-shadow: $box-shadow;

  h3 {
    font-weight: $font-semi-bold;
    font-size: $font-16;
    margin: 0px 5px 0px 0px;
  }
  &__header {
    padding: 12px 16px 9px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  &__header-title {
    display: flex;
    align-items: baseline;
  }
  &__header-read-all {
    text-transform: uppercase;
  }
  &__list {
    max-height: 77vh;
    overflow-y: scroll;
  }
  &__view-all {
    margin: auto;
    padding: 17px;
    text-transform: uppercase;
    font-size: $font-11;
  }
  &__empty-state {
    color: $gray-400;
    font-style: italic;
    font-weight: $font-medium;
    font-size: $font-12;
    padding: 16px 12px;
    text-align: center;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
  }
}
