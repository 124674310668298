@import '/src/ui/index.scss';

.sobo-pages-tab {
  &__title-area {
    display: flex;
    justify-content: space-between;
  }
  &__title-input {
    flex-basis: 450px;
  }
  &__edit-button {
    margin-top: 36px;
    justify-content: end;
    border-radius: 0px;
    text-align: left;
    width: 180px;
    align-items: center;
    text-transform: uppercase;
    font-size: $font-12;
    color: $blue-500;
    .ui-button__before-icon {
      font-size: $font-18 !important;
      margin-right: 5px;
    }
  }
  .card-with-logo-footer {
    flex-flow: row-reverse;
  }
}
