body .editor-answer {
  margin-bottom: 20px;

  .ui-radio-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .ui-textarea {
    width: 100%;
  }

  .ui-checkbox-group__children {
    flex-direction: column;
    gap: 16px;
  }

  .ui-checkbox--mode-checkbox {
    margin: 0;
  }
}
