@import '/src/ui/index.scss';

body .ui-expander {
  &__content {
    transition: $regular-transition;
    max-height: 300px;
    overflow: hidden;

    &--expanded {
      max-height: none;
    }
  }

  &__button {
    color: $blue-500;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    padding: 20px 4px;

    &:hover,
    &:focus {
      color: $blue-700;
    }

    .ui-icon {
      font-size: 26px;
      margin-right: 5px;
    }

    &--expanded {
      .ui-icon {
        transform: rotate(180deg);
      }
    }
  }
}
