@import '../../ui';

.assessment-cloud-monitoring-review-popup {
  .ant-modal-body {
    text-align: center;
    padding: 0 40px;
  }

  &__footer-ok-button {
    margin-bottom: 15px;
  }

  &__picture {
    margin: 45px auto;
    width: 70px;
  }

  &__text {
    font-size: $font-20;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__description {
    font-size: $font-14;
    font-weight: 500;
    color: $gray-500;
  }

  &__description-2 {
    font-size: $font-14;
    font-weight: 600;
    color: $gray-600;
  }
}
