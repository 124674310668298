@import '/src/ui/index.scss';

body .recent-page-vendors-vendor-status {
  &__status-selector {
    width: 100%;
    .ant-select-selection-item {
      vertical-align: middle;
    }

    .ant-select {
      outline: none !important;
      border: 1px solid transparent;
    }

    .ui-icon {
      font-size: 20px;
      margin-right: 5px;
      vertical-align: middle;
    }

    .ant-select-selector {
      padding: 0 !important;
      border: 1px solid transparent !important;
      background: transparent !important;
      box-shadow: none !important;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
      &:before {
        margin-right: 0 !important;
      }
    }

    .ant-select-arrow {
      opacity: 0;
      border: 1px solid $black-100;
      border-radius: 3px;
      padding: 9px 5px 8px;
      margin-top: -10px;
      right: 0 !important;
    }
    &:hover {
      .ant-select-arrow {
        opacity: 1;
      }
    }

    .ant-select-arrow-loading {
      border: 0;
    }

    .ant-select-focused {
      background: white !important;
      border: 1px solid $gray-250;
      border-radius: 4px;

      .ant-select-arrow {
        svg {
          color: $blue-500 !important;
        }
      }

      .ant-select-arrow-loading {
        border: 0;
      }
    }

    .icon-vendor-pending-request {
      color: $blue-500;
    }

    .icon-vendor-assessment-in-progress {
      color: $orange-300;
    }

    .icon-vendor-bo-request-declined {
      color: $gray-400;
    }

    .icon-ok4 {
      color: $green-400;
    }

    .icon-close4 {
      color: $red-500;
    }

    @include desktopSmall{
      .ui-select__option-label {
        display: none;
      }
    }

    .ui-select__option-label{
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 20px;
    }


  }

  &__status-dropdown {
    .ui-icon {
      font-size: 20px;
      margin-right: 5px;
      vertical-align: middle;
    }

    .ant-select-item-option-content {
      vertical-align: middle;
    }

    & .ant-select-item-option-content {
      white-space: nowrap;
    }

    .icon-vendor-pending-request {
      color: $blue-500;
    }

    .icon-vendor-assessment-in-progress {
      color: $orange-300;
    }

    .icon-vendor-bo-request-declined {
      color: $gray-400;
    }

    .icon-ok4 {
      color: $green-400;
    }

    .icon-close4 {
      color: $red-500;
    }
  }
}
