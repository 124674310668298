@import '/src/ui/index.scss';

body .assessment-files {
  &__upload-files {
    .upload-files__items {
      overflow: auto;
      max-height: 280px;
      min-height: 80px;
    }
  }

  @media only screen and (max-height: $smallScreen) {
    &__upload-files {
      .upload-files__items {
        overflow: visible;
        max-height: none;
      }
    }
  }
}
