@import '/src/ui/index.scss';

.assessment-score-benchmark {
  width: 100%;
  height: 42px;
  padding: 0 15px;

  &__inner-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: visible !important;
    display: flex;
  }

  &__progress-bar {
    margin-top: 18px;
    width: 100%;
    height: 4px;
    border-radius: 1px;
    background-image: linear-gradient(to right, $red-500, $orange-300, $green-400);
  }

  &__score,
  &__benchmark {
    position: absolute;
    transform: translate(-50%, 0);
  }

  &__score-scale,
  &__benchmark-scale {
    height: 13px;
    text-align: center;
    margin: auto;
  }

  &__score {
    &-value {
      font-weight: $font-medium;
      line-height: 13px;
    }

    &-scale {
      width: 13px;
      background: white;
      border-radius: 50%;
      border: 2px solid $black-100;

      &--red {
        border: 2px solid $red-500;
      }

      &--orange {
        border: 2px solid $orange-300;
      }

      &--green {
        border: 2px solid $green-400;
      }
    }
  }

  &__benchmark {
    margin-top: 10px;

    &-value {
      font-weight: normal;
      color: $gray-400;
      margin-top: -1px;
    }

    &-scale {
      line-height: 13px;
      width: 1px;
      background: $gray-400;
    }
  }
}
