@import '/src/ui/index.scss';

body .assessment-finalize {
  margin-left: 15px;

  &__finalize-modal {
    .ant-modal-body {
      text-align: center;
    }
  }

  &__image {
    margin-bottom: 20px;
  }

  &__text {
    font-size: $font-20;
    font-weight: 600;
    padding: 0 20px;
  }

  &__finalize-button {
    &:disabled {
      pointer-events: none;
    }
  }

  &__finalize-button-tooltip {
    .ant-tooltip-inner {
      color: $red-500;
      font-size: 15px;

      &:before {
        font-family: 'icomoon';
        font-size: 18px;
        content: '\e92e';
        margin-right: 5px;
      }
    }
  }
}
