@import '/src/ui/index.scss';

.tab-add-button {
  font-size: px2rem(11);
  text-transform: uppercase;
  color: $blue-500;
  font-weight: $font-semi-bold;
  cursor: pointer;
  border: none;
  padding: 0;
  box-shadow: none;
  margin-bottom: 1px;
  border-radius: 0;
  position: relative;

  &:hover {
    background: transparent;
  }
}
