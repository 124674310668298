@import '../../ui';

@mixin size-variant($size, $font) {
  width: $size;
  height: $size;
  position: relative;
  display: flex;

  .organization-logo__default-icon {
    font-size: $font;
    margin: auto;
  }
}

.organization-logo {
  background: $gray-250;
  color: $gray-400;
  margin-right: 10px;
  text-align: center;
  width: 144px;
  max-height: 60px;

  &--img-bg {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $white;
  }

  &--xs {
    @include size-variant($s-24, $s-18);
  }

  &--sm {
    @include size-variant(35px, 26px);
  }

  &--md {
    @include size-variant($s-64, $s-48);
    width: 144px;
    max-height: 60px;
    margin-top: 8px;
  }

  &--circle {
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: 100px;
    min-width: 24px !important;
  }
}
