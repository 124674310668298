@import '/src/ui/index.scss';

body .messenger {
  display: flex;

  &__drop-down {
    position: fixed;
  }

  &__button {
    font-size: 20px;
    position: relative;

    color: $dark-blue-800;

    &:hover,
    &:focus,
    &.ant-dropdown-open {
      color: $blue-700;
    }

    &:active,
    &.messenger__button--active {
      color: $dark-blue-400 !important;
    }

    &--purple {
      color: $purple-400;

      &:hover,
      &:focus,
      &.ant-dropdown-open {
        color: $purple-300;
      }

      &:active,
      &.messenger__button--active {
        color: $purple-200 !important;
      }
    }
  }

  &__button-counter {
    background: $red-600;
    color: $white;
    border-radius: 10px;
    font-size: 9px;
    font-weight: 700;
    padding: 0 5px;
    border: 1px solid $white;
    position: absolute !important;
    top: 4px;
    left: 24px;
  }
}
