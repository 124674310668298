@import '/src/ui/index.scss';

.AppPreloader {
  height: 100vh;
  display: grid;
  align-content: center;
  justify-content: center;

  .loader {
    position: fixed;
    background: $white;
  }

  .preloader {
    position: fixed;
  }
}
