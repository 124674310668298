@import '../../../../ui';

body .customers-filters-filter {
  width: 220px;
  display: flex;
  margin-right: 10px;

  .ant-select-selector {
    display: flex;
    align-items: center;
    background-color: $gray-60 !important;
    border-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;

    &:before {
      margin-right: 5px;
    }
  }

  &--company-filter {
    .ant-select-selection-search {
      margin-left: 72px;
    }
    .ant-select-selector:before {
      content: 'Company:';
    }
  }

  &--vendor-risk-filter {
    width: auto;
    max-width: 300px;

    .ant-select-selection-search {
      margin-left: 42px;
    }
    .ant-select-selector:before {
      content: 'Customer risk:';
    }
  }

  &--assessment-filter {
    .ant-select-selection-search {
      margin-left: 92px;
    }
    .ant-select-selector:before {
      content: 'Assessment:';
    }
  }

  &--parent-organization-filter {
    .ant-select-selection-search {
      margin-left: 90px;
    }
    .ant-select-selector:before {
      content: 'Organization:';
    }
  }

  &__close-button {
    color: $gray-400;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover,
    &:focus {
      background: $gray-60;
      border: 1px solid #d9d9d9;
      color: $black-100;
      opacity: 0.8;
    }
  }
}
