@import '/src/ui/index.scss';

body .recent-page-vendors-assessments {
  display: flex;
  align-items: center;
  width: 100%;

  &__assessments-placeholder {
    color: $gray-400;
  }
}

.assessment-tag {
  cursor: pointer;
  color: $blue-500 !important;
  border-color: $blue-500 !important;

  &:hover {
    background: $blue-50 !important;
    color: $blue-700 !important;
    border-color: $blue-700 !important;
  }

  &:active {
    background: $blue-200 !important;
    color: $blue-700 !important;
    border-color: $blue-700 !important;
  }
}

body .more-assessments-popover-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 350px;

  .ui-tag--blue {
    margin-bottom: 8px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
