@import '/src/ui/index.scss';

body .assessment-finalized {
  padding: 30px;

  .page-subheader__title {
    .subheader-image {
      height: 30px;
    }

    .score-chip {
      margin-left: 16px;
    }
  }

  &__send-to-vendor-button {
    display: flex;
    align-items: center;
    &.ui-button--sm {
      .ui-button__before-icon,
      .ui-button__after-icon {
        font-size: 20px;
      }

      .ui-button__after-icon {
        margin-left: 5px;
      }

      .ui-button__name {
        font-size: 12px;
      }
    }

    &.sent-to-vendor-state {
      background-color: $pink-200;
      color: #ec2163;
      border: transparent;
    }
  }

  &__findings_wrapper {
    background: $white;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
    z-index: 1;
    position: relative;
  }

  &__loader {
    z-index: 9;
  }

  &--is-for-review {
    padding-bottom: 55px;
  }

  &__header {
    display: flex;

    h2 {
      img {
        margin-right: 8px;
      }
    }
  }

  &__header-right {
    margin-left: auto;
    display: flex;
    gap: 40px;
  }

  &__overview {
    padding: 40px;
  }

  &__questionnaire {
    display: flex;
  }

  &__questionnaire-left {
    width: 50%;
    padding: 30px;
  }

  &__questionnaire-right {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    width: 50%;
    padding: 30px;
  }

  &__findings {
    display: flex;
  }

  &__findings-left {
    width: 50%;
    padding: 30px;
  }

  &__findings-right {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    width: 50%;
    padding: 20px;
  }

  &__details {
    display: flex;
    align-items: center;
    margin: 0 0 40px 45px;
  }
}
