@import '../../ui';

body .pending-unassigned-assessments {
  &__modal {
    .ant-modal-footer {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__organization-selector {
    width: 100%;
  }

  &__add-organization-button {
    background: $blue-50;
    width: 100%;
    height: 44px;
    justify-content: flex-start;
    padding-left: 15px;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background: $blue-200;
    }
  }

  &__assessment-list {
    margin: 0;
    padding-left: 10px;
    > li::marker {
      font-size: $font-9;
    }
  }

  &__sub-title {
    font-size: $font-14;
    font-weight: $font-light;
    margin: 10px 10px 10px 34px;
  }

  &__general-error {
    margin-top: 10px;
    color: $error;
    font-weight: 600;
  }
}
