@import '/src/ui/index.scss';

.vendor-new-vendor {
  & .ant-tabs-nav-list {
    margin: 0 auto 0 auto !important;
  }

  margin: px2rem(30.5) px2rem(27.5);
  .ui-tab-pane {
    position: relative;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    & svg {
      margin-right: 1rem;
    }
  }

  &__header-icon {
    font-size: 13px;
    padding: 6px;
    margin-right: 1rem;
    color: $white;
    background: $purple-400;
    border-radius: 24px;
  }

  &__tabs {
    margin-top: px2rem(30);

    .ant-tabs-tab {
      padding-inline: 3rem;
    }
  }

  &__content {
    padding: px2rem(37) px2rem(45) px2rem(80);
    background-color: $white;
    width: 100%;
    height: 70vh;
    overflow-y: scroll;

    & .category-tab {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    & .assign-assessment-tab {
      width: 50vw;
      margin: auto;
    }

    & .tab-header {
      margin-bottom: px2rem(30);
    }

    & .control {
      margin-top: 20px;

      & .ui-input,
      & .ui-select {
        max-width: 333px;
      }
    }
    & .ui-radio-group {
      margin-top: px2rem(4);
    }
  }
  & form {
    display: grid;
    grid-template-columns: (333px 333px);
    column-gap: 157px;
    align-items: baseline;
    justify-content: center;
  }

  &__confirm-modal {
    .ant-modal-footer {
      justify-content: space-between;
      flex-direction: row-reverse !important;
      padding: 10px 24px;
    }
  }
}
