@import '../../../ui/index';

.errors-navigation {
  position: absolute;
  width: 100%;
  bottom: 55px;
  background: $red-450;
  font-size: $font-13;
  color: $white;
  line-height: $font-16;
  padding: 9.5px 26px;
  display: flex;
  justify-content: space-between;

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: baseline;
  }

  &__text,
  &__counter {
    margin-left: 5px;
  }

  &__counter {
    font-weight: $font-medium;
    display: flex;
    align-items: center;

    .icon-left-arrow2,
    .icon-right-arrow2 {
      border: 1px solid white;
      border-radius: 4px;
      font-size: $font-18;
      margin-right: 5px;
      cursor: pointer;
    }
  }

  &__counter-arrows {
    margin-left: 15px;
  }

  &__required {
    text-transform: uppercase;
    font-size: $font-11;
  }

  &__close-button {
    &.ui-button--white-link:hover > .ui-button__name {
      text-decoration: none;
    }
  }
}
