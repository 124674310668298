@import '../../../ui';

.showcase-preview {
  padding: 35px;

  .assessment__left-panel {
    padding-left: 0;
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &-arrow-back {
      margin: 0 20px 0 0;
      & > svg {
        cursor: pointer;
        margin-top: 10px;
      }
    }

    &-description {
      font-size: $font-16;
    }

    &-info {
      margin-top: 24px;
      display: flex;

      &-item {
        display: flex;
        margin-right: 8px;
        font-size: 14px;
        font-weight: $font-semi-bold;
        align-items: center;
      }

      &-label {
        color: $gray-400;
        align-items: baseline;
        display: flex;
        justify-content: center;
        margin-right: 4px;
        font-weight: $font-normal;
        & > svg {
          margin-right: 9px;
        }
      }
    }

    &-read-more {
      cursor: pointer;
      font-size: 11px;
      color: $blue-500;
      margin-top: 8px;
      font-weight: $font-semi-bold;
    }

    &-left {
      display: flex;
      flex-direction: row;
      max-width: 815px;
      width: 100%;
    }
    &-content {
      display: flex;
      flex-direction: column;
    }

    & .ui-text {
      display: flex;
      align-items: center;
    }

    &-right {
      width: 190px;
      & > img {
        width: 100%;
      }
    }
  }

  &__assessments-list {
    margin-top: 24px;
    display: flex;

    & .assessment-item + .assessment-item {
      margin-left: 19px;
    }
  }
}
