@import '/src/ui/index.scss';

body .impact-select {
  width: auto;

  .ant-select-selector:before {
    font-size: 11px !important;
    font-weight: 500 !important;
  }

  .ant-select-selection-item {
    font-weight: 500;
  }

  &--1 {
    .ant-select-selection-item {
      color: $green-400;
    }
  }

  &--2 {
    .ant-select-selection-item {
      color: $orange-300;
    }
  }

  &--3 {
    .ant-select-selection-item {
      color: $orange-300;
    }
  }

  &--4 {
    .ant-select-selection-item {
      color: $red-500;
    }
  }

  &--5 {
    .ant-select-selection-item {
      color: $black-500;
    }
  }

  &__impact-dropdown {
    .dropdown-option-1 {
      color: $green-400;
    }
    .dropdown-option-2 {
      color: $orange-300;
    }
    .dropdown-option-3 {
      color: $orange-300;
    }
    .dropdown-option-4 {
      color: $red-500;
    }
    .dropdown-option-5 {
      color: $black-500;
    }
  }
}
