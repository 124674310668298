@import '/src/ui/index.scss';

body .components-update-answer-confirmation-modal {
  .ant-modal-footer {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 15px 25px;

    .ui-button {
      text-transform: uppercase;
    }
  }
}
