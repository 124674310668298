@import '/src/ui/index.scss';

$button-size: 36px;

.search-expandable {
  display: flex;
  align-items: center;

  &__input {
    width: 0 !important;
  }
  & .ui-input__after-icon {
    cursor: pointer;
  }

  &:not(&.search-expandable--expanded) input {
    padding: 0;
    border: 0;
  }

  &--expanded input {
    padding: 18px 26px 18px 18px !important;
    border: 1px solid #e0dede !important;
  }

  &--expanded {
    width: 100% !important;
    & input {
      height: $button-size;
    }
  }
  &__input-container {
    position: relative;
    margin-right: px2rem(10);
    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      padding: 0;
      width: $button-size;
      min-width: $button-size;
      height: $button-size;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      line-height: 12px;
      border-color: $gray-200;

      &:active {
        transform: translate(0, -50%) !important;
      }

      &.expanded {
        border: none;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  & .ui-input-control {
    transition: none;
  }
}
