@import '/src/ui/index.scss';

body .editor-settings {
  padding: 28px;

  &__title {
    font-size: $font-12;
    display: flex;
    font-weight: $font-semi-bold;
    margin-bottom: 30px;
  }
  &__title-icon {
    font-size: $font-20;
    color: $purple-400;
    margin-right: 10px;
  }

  &__body {
    .ui-select {
      width: 100%;
      margin-bottom: 8px;

      .ant-select-selector {
        height: 48px;
        .ant-select-selection-search-input {
          height: 48px;
        }
      }
    }

    .custom-days-input {
      .ui-input__label {
        font-size: $font-14;
        font-weight: $font-normal;
      }
    }
  }

  &__report-label {
    margin-bottom: 5px;
  }
  &__report-upload-button {
    text-transform: none;
    .ui-button__before-icon {
      margin-right: 30px;
    }
    .ui-icon {
      font-size: $font-24;
    }
    .ui-icon.icon-upload2::before {
      position: absolute;
      top: 4px;
    }
  }

  &__divider {
    margin-bottom: 15px;
  }
  &__file-upload {
    @include file-upload-background;
    padding: 18px;
    width: 306px;
  }
  &__file-upload-title {
    font-size: $font-16;
    font-weight: $font-semi-bold;
  }
  &__file-upload-text {
    font-size: $font-14;
    font-weight: $font-light;
    white-space: break-spaces;
    color: $gray-400;
  }

  .dragged-area {
    border: 1px dashed $dark-blue-400;
    background: $blue-200;
  }

  &__due-date-picker {
    margin-top: 4px;
  }
}
