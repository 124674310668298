@import '/src/ui/index.scss';

body .recent-page-vendors-actions {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0 10px;
  top: 0;
  bottom: 0;
  right: 0;
  background: $blue-100;
  opacity: 0;
  transition: 300ms;

  &:after {
    content: '';
    background: linear-gradient(90deg, rgba(0, 212, 255, 0) 0%, rgba(228, 245, 255, 1) 100%);
    width: 100px;
    height: 100%;
    display: block;
    position: absolute;
    left: -100px;
    top: 0;
  }

  &--always-visible {
    position: static;
    opacity: 1;
    background: transparent;
    padding: 0;

    &:after {
      display: none;
    }
  }

  &__action {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;

    &.ant-tooltip-disabled-compatible-wrapper {
      display: inline-block;
      height: 32px;
      padding: 0;

      .ant-btn {
        font-size: 23px;
        padding: 0 3px;
        color: $gray-250;
      }
    }
  }

  &__activate-icon {
    font-size: 30px;
  }
}

.ant-table-tbody tr {
  cursor: pointer;

  &:hover {
    .recent-page-vendors-actions {
      opacity: 1;
    }
  }
}
