@import '/src/ui/index.scss';

.password-rules-block {
  background: $gray-50;
  display: flex;
  padding: $s-12;

  &__icon {
    color: $purple-200;
  }

  &__info-text {
    font-size: $s-12;
    margin-left: $s-6;
    white-space: pre-line;
  }
}
