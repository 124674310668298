@import '../../../ui';

body .showcase-editor {
  .ant-modal-content {
    margin-top: -6%;
  }
  .ant-modal-content > .ant-modal-header > .ant-modal-title {
    font-size: $font-28 !important;
    font-weight: $font-semi-bold !important;
  }

  &__title-icon {
    font-size: 26px;
    color: $gray-500;
  }

  .ant-modal-header {
    padding: 55px 45px 12px;
    border: 0;
  }
  .ant-modal-body {
    padding: 24px 90px;
    padding-bottom: 100px;
    position: relative;
  }

  &__logo {
    width: 310px;
    margin-bottom: 30px;
  }

  &__multi-select {
    position: relative;
    width: 100%;
  }
  &__multi-select-label-text {
    position: absolute;
    top: 0;
    left: 155px;
    font-size: $font-15;
  }
  &__multi-select-selector {
    width: 99.9%;
    margin: 1px;
  }
}
