@import '/src/ui/index.scss';

body .recent-page-vendors-vendor-risk {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  vertical-align: middle;
  padding: 2px 0;
  color: $white;
  width: 64px;
  height: 18px;
  border-radius: 2px;

  &--High {
    background-color: $red-500;
  }
  &--Medium {
    background-color: $orange-300;
  }
  &--Low {
    background-color: $green-400;
  }
}
