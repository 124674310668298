@import '/src/ui/index.scss';

body .organization-edit-page {
  padding: 30px 60px;

  .ant-table-tbody > tr:nth-child(odd):not(.ant-table-row-selected) > td {
    background: $white;
  }

  &__header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-title-wrap {
    display: flex;
    align-items: center;
    &:hover {
      .organization-edit-page__edit-icon {
        color: $blue-500;
        cursor: pointer;
      }
    }
  }

  &__edit-icon {
    font-size: $s-24;
    margin-left: 10px;
    color: $black-500;
  }

  &__active-edit-icon {
    font-size: 19px;
    color: $gray-600;
    margin-left: 15px;
    cursor: pointer;
  }

  &__input-title {
    .ui-input-control {
      font-size: 28px;
      background: $white;
    }
  }

  &__logo-part {
    display: flex;
    align-items: center;
  }

  &__arrow {
    font-size: $s-24;
    &:hover {
      cursor: pointer;
      color: $purple-200;
    }
  }

  &__header-elements {
    margin-left: 15px;
    display: flex;
  }

  &__subtitle-links {
    color: $blue-500;
    display: flex;
    align-items: baseline;
    text-transform: uppercase;
    font-size: $font-11;
  }

  &__tab-pane {
    padding: 30px;
  }

  &__select {
    .ant-select {
      width: 100%;
    }
  }

  &__remove-org-button {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: $font-11;

    .ui-button__before-icon {
      font-size: 18px;
      margin-right: 5px;
      .ui-icon {
        font-weight: $font-medium;
      }
    }
  }
}
