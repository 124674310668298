@import '/src/ui/index.scss';

body .overview-page {
  position: relative;

  &__loader {
    z-index: 9;
  }

  &__container {
    padding: 30px;
    position: relative;
  }

  &__first-row-content {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    column-gap: 30px;
    margin-bottom: 30px;
    padding: 0 30px;
    position: relative;
  }

  &__first-row-tile {
    margin: 0;
  }

  &__second-row-content {
    grid-template-columns: 1fr 1fr;
    display: grid;
    column-gap: 30px;
    padding: 0 30px 30px;
    position: relative;
  }

  &__second-row-tile {
    margin: 0;
  }

  &__tabs {
    margin-top: 10px;
  }
}
