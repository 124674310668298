@import '/src/ui/index.scss';

body .user-management-tab-downgrade-role-modal {
  .ant-modal-footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 25px;
  }

  &__description-line-2 {
    font-weight: 600;
  }
}
