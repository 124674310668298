@import '/src/ui/index.scss';

body .assessment-categories {
  background: $white;
  padding: 12px;
  border: 1px solid $gray-200;
  border-radius: 4px;
  margin-bottom: 27px;

  &__header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__header-title {
    min-width: 135px;
    font-size: $font-16;
  }

  &__header-title-text {
    font-weight: 650;
  }

  &__categories {
    overflow: auto;
    max-height: calc(62vh - 340px);
  }

  &__category {
    padding: 8px 12px;
    color: $black-100;
    width: 100%;
    height: auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:nth-child(even):not(.assessment-categories__category--selected) {
      background: $gray-50;
    }

    &:focus,
    &:active {
      background: $blue-50;
      color: $black-100;
      font-weight: 600;
    }

    &--selected {
      background: $blue-50;
      font-weight: 600;
    }
  }

  &__category-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80%;
    &--error {
      color: $red-500;
    }
  }

  &__category-completed {
    width: 30px;
    height: 17px;
    background: $green-100;
    border: 1px solid $green-400;
    border-radius: 2px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  &__category-completed-icon {
    color: $green-400;
    font-size: 11px;
  }

  &__category-progress {
    width: 30px;
    background: $blue-200;
    border: 1px solid $blue-500;
    color: $blue-500;
    display: inline-block;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    border-radius: 2px;
    line-height: 1;
    padding: 2px 0;
  }

  @media only screen and (max-height: $smallScreen) {
    &__categories {
      overflow: visible;
      max-height: none;
    }
  }
}
