@import '../../ui';

.upload-list {
  padding: 30px;

  &__assessments {
    padding: 2.3125rem 2.8125rem 5rem;
    background-color: #ffffff;
    width: 50vw;
    margin: auto;
    height: 70vh;
    overflow-y: scroll;
  }

  &__upload {
    background-color: #ffffff;
    width: 100%;
    height: 70vh;
    margin: 45px auto 0 auto;
    text-align: center;
    max-width: 635px;
    overflow: auto;
  }

  &__title {
    font-size: $font-16;
    font-weight: $font-bold;
    margin-bottom: 18px;
  }

  &__description {
    font-size: $font-14;
    font-weight: $font-normal;
    margin-bottom: 24px;
    color: $text-color;
  }

  &__download-template {
    margin-bottom: 68px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-button .ui-button__name {
      display: flex;
      align-items: center;
      font-size: $font-11;
      font-weight: $font-normal;
      text-transform: uppercase;
    }
    & svg {
      margin-right: 5px;
      transform: rotate(180deg);
    }
  }

  &__upload-form {
    margin-top: 37px;
  }

  &__upload-files {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    width: 100%;
    margin: 0 auto;
    border: 1px dashed $gray-250;
    border-radius: $border-radius-xs;
    padding: 0px;

    & .upload-files {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 128px;
      background: $gray-50;
      border: 4px solid #fff;
      border-radius: $border-radius-xs;
      &__items {
        width: 173px;
        height: 83px;
        background: $white;

        & .file-browser {
          height: 100%;
          margin: 0;
          & .file-upload-item {
            border-radius: 2px;
          }
        }
      }
    }
  }

  &__header {
    margin-bottom: 30px;
    & svg {
      margin-right: 10px;
    }
  }
}
