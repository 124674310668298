@import '/src/ui/index.scss';

.ui-divider {
  border-top: 0.1px;
  color: $gray-200;

  &--hidden {
    border-color: transparent;
  }

  &--xxs {
    margin: 0;
  }

  &--xs {
    margin: $s-6 0;
  }

  &--sm {
    margin: $s-12 0;
  }

  &--md {
    margin: $s-24 0;
  }

  &--lg {
    margin: $s-36 0;
  }

  &--vertical {
    border: 0;
    border-right: 1px solid $gray-200;
    height: 15px;

    &.ui-divider--xxs {
      margin: 0;
    }

    &.ui-divider--xs {
      margin: 0 $s-6;
    }

    &.ui-divider--sm {
      margin: 0 $s-12;
    }

    &.ui-divider--md {
      margin: 0 $s-18;
    }

    &.ui-divider--lg {
      margin: 0 $s-36;
    }
  }
}
