@import '../../ui';

body .findings-page {
  padding: 30px;

  .ant-table-tbody > tr:nth-child(odd):not(.ant-table-row-selected, :hover) > td {
    background: $white;
  }
  .ant-table-tbody > tr:nth-child(even):not(.ant-table-row-selected, :hover) > td {
    background: $gray-50;
  }

  &__header {
    margin-bottom: 30px;
  }

  &__list {
    .ant-table-cell {
      font-size: $font-14;
      font-weight: $font-normal;
      color: $text-color;
    }
    .ant-table-column-title {
      text-transform: uppercase;
    }
  }

  &__list-description-column {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__action-bar {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  &__action-bar-top {
    display: flex;
    align-items: center;
  }

  &__input-search {
    width: 300px !important;
  }

  &__active-filters {
    display: flex;
    margin-top: 15px;
    flex-flow: wrap;
    gap: 10px 0;
  }

  .ant-pagination-options {
    display: none;
  }

  .finding-subject {
    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
  }

  .findings-table-assessment-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 350px;

    .ui-tag--blue {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
