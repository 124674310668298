@import '/src/ui/index.scss';

.login-second-authentication {
  &__footer {
    margin-bottom: 20px;
    font-size: $font-13;
  }

  &__alert {
    border: 1px solid $red-200;
    background: $red-50;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 10px 14px;
    margin-bottom: 20px;
  }

  &__alert-icon {
    margin-right: 10px;
  }

  &__header {
    .card-with-logo__title {
      font-size: $font-24;
    }
    .card-with-logo__icon-title {
      margin-right: 5px;
    }
  }
}
