@import '/src/ui/index.scss';

.category-form {
  margin-bottom: px2rem(64);

  &:last-of-type {
    margin-bottom: px2rem(40);
  }
  &__header {
    margin-bottom: px2rem(32) !important;
    display: flex;
    font-weight: $font-semi-bold;
    font-size: 20px;
    align-items: center;

    & > div {
      margin-left: px2rem(24);
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: (333px 333px);
    column-gap: 157px;
    align-items: start;
  }
}
