@import '/src/ui/index.scss';

body .ui-select {
  & .ant-select-selection-placeholder {
    color: $gray-400;
  }

  & .ant-select {
    width: inherit;
    font-size: 13px;
    font-weight: $font-normal;

    &:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 4px;
    }

    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: $gray-200;
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: $purple-200;
      transition: $regular-transition;
      box-shadow: $purple-200 0 0 0 1px;
    }

    & .ant-select-selector {
      width: 100%;
      display: flex;
      align-items: center;
      padding-inline: px2rem(8);
      padding-right: 25px;

      &:before {
        content: attr(data-label);
        display: flex;
        align-items: center;
        color: $gray-400;
        font-weight: 600;
        font-size: 13px;
        margin-right: px2rem(8);
        line-height: 13px;
      }
    }
    &:not(.ant-select-multiple) .ant-select-selector {
      padding: px2rem(8);
      padding-right: 25px;
    }

    &.ant-select-multiple .ant-select-selection-search {
      margin-inline-start: -5px;
    }

    &.ant-select-multiple
      .ant-select-selection-overflow-item
      + .ant-select-selection-overflow-item
      .ant-select-selection-search {
      margin-inline-start: 0;
    }

    & input {
      background: #fff;
      border: 1px solid $gray-200;
    }

    &.ant-select-lg {
      height: 58px;

      .ant-select-selector {
        box-sizing: border-box !important;
        height: 48px !important;
        margin: 5px 0 !important;
        outline: none !important;
        border-radius: $border-radius-xs !important;
      }

      &:not(.ant-select-customize-input, .ant-select-multiple):not(.ant-select-customize-input)
        .ant-select-selection-search-input {
        height: 46px;
      }
    }

    &.ant-select-disabled {
      .ant-select-arrow svg {
        color: $gray-250;
      }
    }
  }

  &--selected {
    .ui-select__wrapper {
      .ant-select-selection-overflow {
        .ant-select-selection-item {
          background-color: $blue-50;
          border-radius: $border-radius-lg;
          border: 1px solid $blue-450;
          max-width: 135px;
        }
      }
      .ant-select-selection-item {
        font-weight: $font-medium !important;

        padding-right: 0;
      }
      .ant-select-selection-item-remove {
        padding: 0px 8px;
        font-size: $s-12;

        &:hover {
          background-color: darken($blue-50, 5%);
        }
      }
    }
  }

  &--color-gray {
    .ant-select:not(.ant-select-disabled) {
      .ant-select-selector {
        background: $gray-50 !important;
      }
    }
  }

  &--color-gray:not(.ui-select--error) {
    .ant-select:not(.ant-select-disabled) {
      .ant-select-selector {
        border: 1px solid $gray-200 !important;
      }
    }
  }

  &--inline {
    .ant-select {
      width: inherit;

      &:not(.ant-select-customize-input):not(.ant-select-customize-input)
        .ant-select-selection-search-input {
        height: 20px;
      }
    }

    .ant-select-selector {
      padding: 0 !important;
      border: 0 !important;
      background: transparent !important;
      box-shadow: none !important;
      height: auto !important;

      &:after {
        line-height: inherit !important;
      }

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: inherit !important;
        padding-right: 24px;
      }

      .ant-select-selection-search {
        left: 7px;
      }
    }
  }

  &__error {
    color: $error;
    font-size: 13px;
    font-weight: 600;
  }

  &__label {
    color: $black-100;
    font-size: 15px;
    font-weight: 500;
  }
  &__wrapper {
    width: inherit;
    display: flex;
  }

  &__dropdown {
    background: transparent;
    margin-top: px2rem(12);
    border: 1px solid $gray-200;
    & .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
      font-weight: $font-medium !important;
    }
    & .ant-select-item-option-content {
      white-space: normal;
    }
    & .ant-select-item {
      background: $white;
      font-weight: $font-normal;
      &:hover,
      &.ant-select-item-option-active {
        background: $blue-100;
      }
    }
  }
  &--error {
    & .ant-select {
      .ant-select-selector {
        border: 1px solid $error !important;
      }
      &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: $error;
        transition: $regular-transition;
        box-shadow: $error 0 0 0 1px;
      }
    }
  }
}

.ant-select-dropdown {
  box-shadow: none !important;
  background: transparent !important;
}
