@import '/src/ui/index.scss';

body .continuous-monitoring-tab-notes-note-modal {
  .ant-modal-body {
    padding-bottom: 0;
  }

  &__title-suffix {
    display: inline-block;
    margin-left: 5px;
    font-weight: 400;
    font-size: $font-16;
  }

  .ant-modal-footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 25px 15px;
  }

  &__note {
    .ui-textarea-control {
      height: 220px;
    }
  }

  &__date-wrapper {
    font-size: $font-12;
    display: flex;
    position: relative;
  }

  &__divider {
    border-left: 1px solid $gray-200;
    margin: 0 10px;
    display: inline-block;
    height: 16px;
  }

  &__internal-label {
    margin-left: auto;
    font-style: italic;
    color: $orange-300;
  }

  &__assessment-wrapper {
    margin-bottom: 12px;
    font-size: $font-12;
  }

  &__assessment-label {
    display: inline-block;
    margin-right: 10px;
  }

  &__control-wrapper {
    display: flex;
    font-size: $font-12;
    margin-bottom: 30px;
  }

  &__control-position {
    display: inline-block;
    margin-right: 10px;
  }

  &__finding-wrapper {
    display: flex;
    font-size: $font-12;
    margin-bottom: 30px;
  }

  &__note-finding-flag {
    margin-right: 20px;
    margin-top: 3px;
    font-size: $font-14;
  }
}
