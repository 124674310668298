@import '/src/ui/index.scss';

.assessment-status-column {
  display: flex;
  align-items: center;
  white-space: nowrap;

  &__label {
    color: $white;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.7;
    min-width: 100px;
    padding: 0 5px;

    &--gray {
      background: $gray-250;
    }

    &--yellow {
      background: $orange-300;
    }

    &--green {
      background: $green-400;
    }

    &--blue {
      background: $dark-blue-400;
    }

    &--red {
      background: $red-500;
    }
    &--turquoise {
      background: $Turquoise-500;
    }
  }

  &__progress-percentage {
    padding-left: 10px;
    font-weight: 500;
    font-size: $font-14;
  }

  &__status-dot {
    display: inline-block;
    width: auto;
    height: 14px;
    min-width: 14px;
    font-weight: 500;
    line-height: 14px;
    border-radius: 7px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    white-space: nowrap;
    vertical-align: middle;
    margin-right: 5px;

    &--color {
      &-gray {
        background: $gray-250;
      }

      &-yellow {
        background: $orange-300;
      }

      &-green {
        background: $green-400;
      }

      &-blue {
        background: $dark-blue-400;
      }

      &-red {
        background: $red-500;
      }
    }
  }

  &--auto-submitted,
  &--force-submitted {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .assessment-status-column__label {
      border: 2px solid $red-600;
    }
  }

  &__auto-submitted {
    line-height: 1em;
    margin-top: 5px;
    font-size: $font-13;
  }

  &__force-submitted {
    line-height: 1em;
    margin-top: 5px;
    font-size: $font-13;
  }

  &__force-submitted-label {
    color: $gray-250;
  }
}
