@import '/src/ui/index.scss';

body .answer-repository {
  &__button {
    &--is-editor {
      font-size: 12px;
      font-weight: 600;
    }
  }

  &__modal {
    top: 40px;

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__footer-ok-button {
    width: 120px;
  }

  &__header {
    margin-bottom: 20px;
    padding: 24px 24px 14px;
    border-bottom: 1px solid $gray-200;
    box-shadow: 1px 4px 3px 0 rgba(0, 0, 0, 0.07);
  }

  &__header-title {
    font-size: $font-20;
    margin: 0;
  }

  &__header-description {
    margin-bottom: 8px;
  }

  &__list-header {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 7px;
  }

  &__search {
    margin-bottom: 10px;
  }

  &__body {
    position: relative;
  }

  &__list-wrapper {
    padding: 0 24px;
  }

  &__categories {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    max-height: 200px;
    overflow: auto;
  }

  &__category-button {
    color: $black-100;
    min-width: 70px;
    display: inline-block;
    padding: 0 12px;

    &.ui-button--blue-outline {
      color: $blue-500;
    }
  }

  &__controls-counter {
    background: $blue-500;
    color: $white;
    border-radius: 20px;
    font-size: 11px;
    margin-left: 5px;
    padding: 3px 6px;
    vertical-align: top;
  }

  &__panel {
    display: flex;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &__empty {
    margin-bottom: 20px;
  }

  &__action-bar {
    border-top: 1px solid $gray-200;
    width: 100%;
    padding: 16px;
  }
}
