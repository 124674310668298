@import '/src/ui/index.scss';

.ui-card {
  background-color: $white;
  border-radius: 0;
  box-shadow: $box-shadow;
  padding: 24px;

  animation-duration: 0.5s;
  animation-name: fadeInLeft;
  animation-fill-mode: both;

  margin-bottom: 24px;

  &--transparent {
    background-color: transparent;
  }

  &--no-padding {
    padding: 0;
  }

  &--no-margin {
    margin: 0;
  }

  &--squared {
    border-radius: $border-radius-xs;
  }
}
