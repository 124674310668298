@import '../../../ui';

body .recent-page-vendors-inherent-risk-popover {
  &__content-title {
    color: $gray-400;
    font-size: 11px;
  }

  &__content{
    padding-bottom: 2px;
  }

  &__inherent-risk-table {
    & th{
      font-weight: $font-semi-bold !important;
      border-bottom: none !important;
      &:first-of-type{
        padding-left: 0 !important;
      }
    }

    .ant-table-tbody{
      box-shadow: $box-shadow-4;


      & tr {
        cursor: initial;
      }
    }


  }

  &__risk {
    text-transform: uppercase;
    font-weight: 600;
    font-size: $font-11;
    text-align: center;
  }

  &__risk--Low {
    color: $green-400;
  }

  &__risk--Medium {
    color: $orange-300;
  }

  &__risk--High {
    color: $red-500;
  }
}
