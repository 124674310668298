@import '/src/ui/index.scss';

.assessment-status {
  height: 30px;
  padding: 8px 40px 0 25px;
  margin-bottom: 30px;

  &__wrapper {
    position: relative;
  }

  &__line {
    border: 1px dashed $gray-250;
  }

  &__line-completed {
    border: 1px solid $gray-250;
    width: 75%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__line-in-progress {
    border: 1px solid $blue-500;
    position: absolute;
    height: 0;
    left: 0;
    top: 0;

    &:before {
      content: '';
      background: $blue-500;
      width: 6px;
      height: 6px;
      display: block;
      border-radius: 6px;
      position: absolute;
      left: -3px;
      top: -3px;
    }
  }

  &__state-finalized,
  &__state-reviewed,
  &__state-not-started {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: -8px;
    background: $white;
    width: 18px;
    height: 18px;
    border: 2px solid $gray-250;
    border-radius: 20px;
    text-align: center;

    &:after {
      content: attr(data-text);
      margin-top: 22px;
      color: $gray-300;
      font-size: 10px;
      font-weight: 700;
    }
    &--done {
      border-color: $blue-500;
      background: $blue-500;
    }
    &--active {
      border-color: $blue-500;

      &:after {
        color: $blue-500;
      }

      &:before {
        content: '';
        background: $blue-500;
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 7px;
        margin-top: 4px;
        position: absolute;
      }
    }
  }

  &__state-finalized {
    left: 75%;
  }

  &__state-reviewed {
    left: 100%;
  }

  &__state-in-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: -8px;
    background: $white;
    width: 18px;
    height: 18px;
    border: 2px solid $blue-500;
    border-radius: 20px;
    z-index: 1;

    &:before {
      content: attr(data-percentage);
      background: $blue-500;
      margin-top: -24px;
      color: $white;
      font-size: 10px;
      font-weight: 700;
      padding: 2px 5px;
      border-radius: 4px;
    }

    &:after {
      content: '';
      background: $blue-500;
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 7px;
      margin-top: 8px;
    }
  }

  &__state-not-started {
    left: -8px;
    border-color: $blue-500;
    align-items: flex-start;

    &:after {
      color: $blue-500;
      white-space: nowrap;
    }
  }

  &__label-in-progress {
    margin-top: 14px;
    color: $blue-500;
    font-size: 10px;
    font-weight: 700;
  }
}
