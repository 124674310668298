@import '/src/ui/index.scss';

.ui-tabs {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  background: $white;

  .ant-tabs-ink-bar {
    background: $purple-400;
    height: 4px !important;
    border-radius: 30px;
  }

  .ant-tabs-nav-wrap {
    padding-right: px2rem(45);
    height: 60px;
    font-weight: $font-semi-bold;
  }

  .ant-tabs-tab {
    padding-inline: px2rem(40);

    @media (max-width: 1440px) {
      padding-inline: px2rem(32);
    }

    &-btn {
      color: $black-100 !important;
      text-shadow: none !important;
    }
    & + .ant-tabs-tab {
      margin: 0;
    }
  }

  &--disabled-tab-on-click {
    & .ant-tabs-tab {
      pointer-events: none;
    }
  }

  &--for-overview-page {
    & .ant-tabs-tab {
      padding-inline: 1.375rem;
      padding: 0 4px;
      margin-right: 28px !important;
    }
    .ant-tabs-nav-wrap {
      height: 40px;
    }
    box-shadow: none !important;
  }

  &--centered {
    & .ant-tabs-nav-wrap {
      justify-content: center;
    }
  }

  &--rounded {
    background: transparent;
    box-shadow: none;

    .ant-tabs {
      overflow: visible;
    }

    .ant-tabs-tab-active {
      background: $white;
      border-top-right-radius: 18px !important;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
      font-weight: 600;

      &:first-child {
        border-top-left-radius: 0 !important;
      }
    }

    .ant-tabs-nav {
      margin: 0 !important;

      &:before {
        display: none;
      }
    }

    .ant-tabs-content-holder {
      background: $white;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
      z-index: 1;
      position: relative;
    }

    .ui-tab-pane {
      width: 100%;
    }

    .ant-tabs-ink-bar {
      border-right: 50px solid $white;
      border-left: 50px solid $white;
    }
  }

  &--inner {
    box-shadow: none;

    .ant-tabs-nav-wrap {
      padding-inline: 0;
      height: 35px;

      .ant-tabs-nav-list {
        column-gap: px2rem(48);
        position: relative;

        .ant-tabs-ink-bar {
          height: 3px !important;
          visibility: visible;
        }

        .ant-tabs-tab {
          padding: 0;
          font-weight: $font-normal;
          line-height: 150%;
          font-size: px2rem(13);
          text-transform: uppercase;
          border: none;
          background: transparent;

          & button {
            display: none;
          }

          &:not(:nth-last-child(2)) {
            &:after {
              content: '';
              position: absolute;
              right: px2rem(-25);
              width: 1px;
              height: 21px;
              background: $gray-200;
            }
          }
        }

        .ant-tabs-nav-add {
          background: transparent;
          border: none;
          padding: 0;
          margin: 0;
        }
      }
    }
    .ant-tabs-extra-content {
      margin-right: px2rem(24);
    }
  }
}
