@import '/src/ui/index.scss';

body .editor-subjects {
  &__title {
    font-weight: 600;
    margin-bottom: 25px;
  }

  &__header {
    display: flex;
    margin-bottom: 3px;
    padding: 0 20px;
  }

  &__header-cell {
    font-size: 11px;
    color: $gray-400;
    font-weight: 500;

    &--name {
      width: 30%;
    }

    &--weight {
      width: 20%;
      padding-left: 8px;
    }

    &--benchmark {
      width: 20%;
      padding-left: 8px;
    }

    &--questions {
      width: 15%;
    }

    &--visible {
      width: 10%;
    }

    &--actions {
      width: 5%;
    }
  }

  &--customer-view {
    .editor-subjects__header-cell--questions {
      margin-left: 55%;
    }
  }
}
