@import '../../../ui';

.upload-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;

  &__header {
    margin-bottom: 16px;

    &-text {
      font-size: $font-20;
      font-weight: $font-semi-bold;
    }
    &-icon {
      display: flex;
      justify-content: center;
    }
  }

  &__statistics {
    background: $gray-75;
    border-radius: 4px;
    display: flex;
    width: 100%;
    padding: 18px 14px;
    flex-direction: column;
    gap: 16px;

    &-row {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 35px minmax(40px, auto) 1fr;

      &-value {
        font-weight: $font-bold;
        margin-right: 8px;
      }
    }
  }

  &__description {
    width: 100%;
    margin-top: 16px;
    font-size: $font-15;
    font-weight: $font-semi-bold;

    & em {
      color: $red-500;
      font-style: normal;
    }
  }

  &__button {
    margin-top: 40px;
  }

  &__info {
    font-size: $font-14;
    margin-top: 40px;
    display: flex;
    align-items: center;
    align-self: baseline;

    & button {
      font-size: $font-11;
    }
  }
}
