@import '/src/ui/index.scss';

@mixin icon($icon) {
  font-family: 'icomoon';
  color: $gray-300;
  font-size: 17px;
  content: $icon !important;
  visibility: visible !important;
}

body .editor-visible-switcher {
  width: auto;

  .ant-select {
    min-width: 110px;
  }

  &--visible,
  &--hidden {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 28px;
    margin: 0 10px;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-item:after {
      margin-left: 3px;
    }
  }

  &--visible {
    .ant-select-selection-item:after {
      @include icon('\e932');
    }
  }

  &--hidden {
    background: $blue-100;

    .ant-select-selection-item:after {
      @include icon('\e931');
    }
  }

  &__dropdown {
    .ant-select-item:nth-child(1) {
      &:after {
        @include icon('\e932');
      }
    }

    .ant-select-item:nth-child(2) {
      &:after {
        @include icon('\e931');
      }
    }
  }
}
