@import '/src/ui/index.scss';

.linked-questions {
  &__button {
    &:hover {
      text-decoration: none;
    }
    .ui-button__name {
      display: flex;
      align-items: center;
      font-size: $font-12;
      font-weight: 600;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__icon {
    font-size: $font-18;
    margin-right: 6px;
  }

  &__popover {
    display: flex;
    align-items: center;
  }

  &__popover-title {
    display: flex;
    width: 500px;
    justify-content: space-between;
    padding: 10px 0;
    position: relative;
  }

  &__popover-title-text {
    display: flex;
  }

  &__icon-close {
    position: absolute;
    right: 0px;
    top: 5px;
    font-weight: lighter;
    color: $gray-250;
  }

  &__popover-content-title {
    color: $gray-400;
    font-size: $font-11;
    margin-bottom: 5px;
  }

  &__popover-content-linked-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    font-size: $font-14;
    &:hover {
      background: $blue-50;
      cursor: pointer;
    }
  }

  &__popover-content-list-title {
    span {
      color: $gray-400;
    }
  }
}
