@import '../../ui';

.third-party-consent {
  margin-top: 26px;

  &__title {
    font-weight: $font-bold;
    font-size: $font-16;
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    span {
      margin-left: 10px;
    }
    .ui-icon.icon-info {
      font-size: 18px;
      margin: 0;
    }
  }

  &__content {
    font-family: 'Montserrat';
    padding-right: 10px;
    font-weight: $font-normal;
    font-size: $font-14;
    line-height: 150%;
    margin-bottom: 20px;
  }

  &__footer {
    display: flex;
    align-items: center;
  }
  &__footer-button-left {
    margin-right: 10px;
    color: $text-color;
    padding: 0px 13px;
  }
  &__footer-button-right {
    padding: 0 18px 0px 16px;
  }
}
