@import '/src/ui/index.scss';

body .page-subheader {
  margin-bottom: 20px;

  &__title {
    font-size: $font-24;
    color: $black-100;
    font-weight: 600;
    margin: 0;
    line-height: 34px;
    display: flex;
    align-items: center;
    word-break: break-all;
    word-break: break-word;
  }

  &--with-back-arrow {
    display: flex;
  }

  &__back-arrow {
    color: $gray-500;
    margin-top: 2px;

    &:hover,
    &:focus,
    &:active {
      color: $black-100;
    }
  }
}
