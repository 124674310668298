@import '/src/ui/index.scss';

.answer-repository-controls {
  &__container {
    width: 50%;
    max-width: 500px;
    margin-right: 20px;
    overflow-y: auto;
    max-height: 400px;
  }

  &__row {
    border-bottom: 1px solid $gray-200;
    padding: 5px;

    &:hover {
      cursor: pointer;
      background-color: $blue-50;

      > label {
        cursor: pointer;
      }
    }

    &.selected {
      background: $blue-200;
    }
  }

  &__row-header {
    width: 100%;
    display: flex;
    align-items: center;
    color: $dark-blue-800;

    &.hebrew {
      direction: rtl;
    }
  }

  &__row-icon {
    font-size: 20px;
    margin-right: 5px;
  }

  &__row-label {
    max-height: 100px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &.hebrew {
      direction: rtl;
    }
  }
}
