@import '/src/ui/index.scss';

@mixin link-theme($color) {
  color: $color !important;

  &:hover {
    color: darken($color, $darken-active-multiplier) !important;
    text-decoration: underline;
  }
}

.ui-link {
  font-weight: 400;
  font-size: $font-14;
  line-height: 17px;

  // Themes
  &--text-link {
    @include link-theme($black-100);
  }

  &--gray-link {
    @include link-theme($gray-400);
  }

  &--pink-link {
    @include link-theme($pink-500);
  }

  &--black-link {
    @include link-theme($black-100);
  }

  // Other
  &--block {
    display: block;
  }
}
