@import '/src/ui/index.scss';

body .editor-active-question {
  &__main {
    display: flex;
    padding: 18px;
  }

  &__key {
    width: 50px;
    padding-top: 49px;
  }

  &__wrapper {
    width: 100%;
  }

  &__top-description,
  &__bottom-description {
    .editable-content__content,
    .editable-content__editable {
      font-size: 13px;
      line-height: 1.5;
      color: $gray-400;
    }
  }

  &__bottom-description {
    margin-bottom: 10px;
  }

  &__title {
    margin: 5px 0;

    .editable-content__content,
    .ui-textarea-control {
      font-size: 15px;
      color: $black-100;
      padding: 11px;
      font-weight: 500;
    }

    .editable-content__content {
      border: 1px solid $gray-200;
      border-radius: $border-radius-xs;
    }

    .editable-content__loader {
      top: 14px;
    }
  }

  &__question-preview {
    display: flex;

    > div:first-child {
      flex: 1;
    }
  }

  &__app-trigger {
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 24px;
  }

  &__action-bar {
    border-top: 1px solid $gray-200;
    display: flex;
    padding: 4px 4px;
    align-items: center;
    width: 100%;
    position: relative;
  }

  &__edit-answers-button,
  &__findings-button,
  &__triggers-button {
    font-size: 12px;
    display: flex;
    padding: 6px 9px;

    .ui-icon {
      font-size: 20px;
    }
  }
  &__right-actions-buttons {
    position: absolute;
    right: px2rem(14);
    .ui-icon {
      font-size: 20px;
    }
  }
  &__remove-answers-button {
    color: $gray-600;
    cursor: pointer;
  }
}
