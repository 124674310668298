@import '/src/ui/index.scss';

body .base-layout {
  background: transparent;
  display: flex;
  flex: auto;
  flex-direction: row;

  &__sider {
    position: sticky;
    top: 0;
    height: 100vh;
    background: $purple-400;
    border-radius: 0 30px 0 0;
    flex: 0 0 $menuHeight !important;
    max-width: $menuHeight !important;
    min-width: $menuHeight !important;
    width: $menuHeight !important;
    padding-top: $headerHeight;
    z-index: 2;

    .ant-layout-sider-trigger {
      background: $purple-200;
      width: $menuHeight !important;
    }

    &.ant-layout-sider-collapsed {
      flex: 0 0 60px !important;
      max-width: 60px !important;
      min-width: 60px !important;
      width: 60px !important;

      .ant-layout-sider-trigger {
        width: 60px !important;
      }
      .base-layout__logo {
        opacity: 1;
      }
      .base-layout__logo-findings {
        opacity: 0;
      }
    }

    &.ant-layout-sider-dark {
      background: $dark-blue-800;

      .ant-layout-sider-trigger {
        background: $dark-blue-400;
      }

      .base-layout__border {
        border-bottom: 1px solid $dark-blue-600;
      }
    }
  }

  &__logo {
    width: 34px;
    height: 34px;
    margin: 15px auto;
    padding: 0;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transition: $regular-transition;
  }
  &__logo-findings {
    opacity: 1;
    width: 120px;
    height: 32px;
    margin: 15px auto;
    padding: 0;
    display: block;
    position: absolute;
    top: 7px;
    right: 0;
    left: 0;
    transition: $regular-transition;
  }

  &__border {
    border: 0;
    border-bottom: 1px solid $purple-200;
    margin: 0 10px 10px;
  }

  &__layout {
    min-height: 100vh;
  }

  &__header {
    background: $white;
    position: sticky;
    top: 0;
    width: 100%;
    height: $headerHeight;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    z-index: 10;
  }

  &__header-aside {
    display: flex;
    align-items: center;
  }

  &__content {
    background: $gray-50;
    min-height: calc(100% - $headerHeight);
  }

  &__dev-mode-button {
    all: unset;
    user-select: none;
    line-height: 12px;
    color: $blue-500;
    height: 30px;
    background-color: transparent;
    display: flex;
    cursor: pointer;
    padding: 0 1px;
    box-shadow: none;
    align-items: center;
    transition: background 0.3s;
    font-size: 9px;
    font-weight: $font-semi-bold;
    position: absolute;
    bottom: 50px;
    &.active {
      color: $red-600;
    }
  }
}
