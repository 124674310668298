@import '/src/ui/index.scss';

body .assessment-finalized-mark-as-review {
  background: $white;
  padding: 10px 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
}
