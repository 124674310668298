@import '/src/ui/index.scss';

@mixin status() {
  color: $white;
  font-size: 11px;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
}

body .trusted-organizations-tab {
  &__organization {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__icon {
    width: 30px;
    height: 30px;
    display: inline-block;
  }

  &__organization-icon {
    width: 30px;
    height: 30px;
  }

  &__learn-more {
    font-size: 11px;
    font-weight: $font-semi-bold;
    color: $blue-500;
    cursor: pointer;
  }

  &__actions {
    padding: 20px 0 50px 0;
    display: flex;
    align-items: center;

    &-left {
      font-size: 14px;
      max-width: 682px;
    }

    .ui-input-control--search {
      width: 240px;
    }
  }

  &__actions-right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__add-button {
    padding: 0 20px 0 20px;
    min-width: auto;
    margin-left: 20px;

    .ui-button__before-icon {
      margin-right: 6px;
    }
  }

  &__status-awaiting-approval {
    @include status();
    background: $blue-450;
  }

  &__status-pending {
    @include status();
    background: $orange-300;
  }

  &__status-trusted {
    @include status();
    background: $green-400;
  }

  &__status-rejected {
    @include status();
    background: $red-500;
    display: flex;
    align-items: flex-start;
    max-width: 100px;
    & svg {
      margin-right: 5px;
    }
  }

  &__organization-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &__approve-button {
    .ui-icon {
      font-size: 18px;
    }
  }

  &__reject-button {
    border: 1px solid $gray-600;
    color: $gray-600;
  }

  &__action {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;
  }
}
