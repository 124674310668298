@import '/src/ui/index.scss';

.onboarding-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .onboarding-card {
    width: 900px;
    height: 680px;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0;
    background-color: $white;

    > div {
      width: 50%;
    }

    &__left-box {
      background-image: url('/assets/images/purple-card-onboarding-background.png');
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 72px;

      &__content {
        img {
          margin-bottom: 5px;
        }

        p {
          color: $white;
          font-weight: $font-medium;
          font-size: $font-16;
          line-height: 27px;
          margin: 0;

          + p {
            font-weight: $font-semi-bold;
            margin-top: 20px;
            margin-bottom: 8px;
          }
        }
      }
    }

    &__left-box__content-p2 {
      font-size: $font-14 !important;
    }

    &__left-box__content-text {
      width: 274px;
    }

    &__inner {
      position: relative;
      padding: 40px 48px;

      h1 {
        &.zero-title {
          font-size: $font-26;
          font-weight: $font-semi-bold;
          margin-top: 20px;
        }

        font-size: $font-20;
        margin-bottom: 20px;
      }

      h2 {
        font-size: $font-18;
        margin-bottom: 15px;
      }

      .google-sso-login-wrapper {
        margin-top: 40px;
      }

      &__continue-to-password-btn {
        margin: 16px auto 40px auto;
        width: 216px;
      }

      &__terms {
        font-size: $font-14;
        font-weight: $font-normal;
        line-height: 21px;

        a {
          text-transform: uppercase;
          font-weight: $font-semi-bold;
        }
      }
    }
  }
}
