@import '/src/ui/index.scss';

body .recent-page-assessments {
  margin-bottom: 55px;

  .ant-table-tbody {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
  }

  .ant-table-tbody > tr:nth-child(odd):not(.ant-table-row-selected, :hover) > td {
    background: $white;
  }

  .ant-table-tbody tr {
    cursor: pointer;
  }

  .showcase-name-indicator {
    margin-bottom: 0;
    color: $Turquoise-400;
    font-size: $font-11;
    font-weight: $font-semi-bold;
  }

  .empty-score {
    color: $gray-400;
    font-size: $font-14;
    font-weight: $font-normal;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__page-subheader {
    display: flex;
    margin: 0;
    .page-subheader__title {
      font-size: $font-20;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
  }

  &__action {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;

    .ant-btn {
      font-size: 23px;
      padding: 0 3px;
      color: $gray-500;
    }

    &.ant-tooltip-disabled-compatible-wrapper {
      padding: 0;
      display: flex !important;
      align-items: center;

      .ant-btn {
        color: $gray-300;
      }
    }
  }

  &__view-all-button {
    margin: 0 25px 0 20px;
    font-weight: $font-semi-bold;
  }

  &__assign-button {
    padding: 0 20px 0 20px;
    min-width: auto;

    .ui-button__before-icon {
      margin-right: 6px;
    }
  }

  &__table-link {
    color: $black-100;

    .ui-icon {
      vertical-align: middle;
      line-height: 1.1;
      margin-right: 3px;
      font-size: 18px;
    }

    &:hover,
    &:focus {
      color: $blue-700;
      text-decoration: underline;
    }
  }

  &__due-date-ico {
    color: $gray-400;
    margin-right: 8px;
  }

  &__due-date {
    color: $black-100;
    display: flex;
    align-items: center;

    &--expired {
      color: $error;

      .recent-page-assessments__due-date-ico {
        color: $error;
      }
    }
  }

  &__actions {
    opacity: 0;
    transition: 300ms;

    & .assessments-page-list-actions__action.ant-tooltip-disabled-compatible-wrapper {
      display: initial !important;
    }
  }
}

.ant-table-tbody tr {
  &:hover {
    .recent-page-assessments__actions {
      opacity: 1;
    }
  }
}
