@import '/src/ui/index.scss';

body .assessments-page-templates-actions {
  opacity: 0;
  transition: $regular-transition;

  &__action {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;

    &.ant-tooltip-disabled-compatible-wrapper {
      display: inline-block;
      height: 32px;
      padding: 0;

      .ant-btn {
        font-size: 23px;
        padding: 0 3px;
        color: $gray-250;
      }
    }
  }
}

.ant-table-tbody tr {
  cursor: pointer;

  &:hover {
    .assessments-page-templates-actions {
      opacity: 1;
    }
  }
}
