@import '/src/ui/index.scss';

.sobo-pages-stage-four {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;

  &__text {
    position: relative;
    width: 350px;
    text-align: center;
  }

  &__illustration {
    position: relative;
    height: 500px;
  }
}
