@import '/src/ui/index.scss';

body .editor-question {
  &.disabled-question {
    .editor-question__key {
      color: $gray-400;
    }

    .editor-question__apps-icons {
      &-icon {
        opacity: 0.6;
      }
    }
    .editor-question__title {
      color: $gray-400;
    }
  }

  &__main {
    display: flex;
    padding: 18px;
  }

  &__key {
    width: 50px;
  }

  &__wrapper {
    width: 100%;
  }

  &__title {
    font-size: 15px;
    color: $black-100;
    font-weight: 500;
    margin-bottom: 20px;
    pre {
      overflow: hidden;
      white-space: pre-wrap;
    }
  }

  &__action-bar {
    border-top: 1px solid $gray-200;
    display: flex;
    padding: 4px 4px;
    align-items: center;
  }

  &__findings-button,
  &__triggers-button {
    font-size: 12px;
    display: flex;
    padding: 6px 9px;

    .ui-icon {
      font-size: 20px;
    }
  }

  &__apps-icons {
    margin-bottom: 8px;

    &-icon {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
  }
}
