@import '/src/ui/index.scss';

body .assessment-category-actions-panel {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 60px;
  padding-top: 20px;
  background-color: transparent;
  z-index: 2;

  &.scrolled {
    position: sticky;
    z-index: 2;
    background-color: $white;
    margin-bottom: 24px;
    border-bottom: 1px solid $gray-200;
    padding-right: 0;
    padding: 5px 0 5px 0;
  }
}

body .assessment-category-header {
  margin-bottom: 30px;
  position: relative;
  padding-top: 30px;

  &__title {
    font-size: $font-20;
    font-weight: 600;
  }

  &__logo-container {
    display: flex;
    margin-bottom: 10px;
  }

  &__logo {
    width: 144px;
    max-height: 60px;
  }

  &__suggestion-button {
    background: $green-400;
    color: $white;
    border-radius: 4px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: $font-12;
    font-weight: 600;

    box-shadow: 0 2px 3px $gray-250;

    .ui-icon {
      font-size: 19px;
      margin-right: 7px;
    }

    &:hover,
    &:focus,
    &:active {
      background: $green-400;
      color: $white;
      opacity: 0.8;
    }
  }

  &__loader {
    margin-right: 8px;
    margin-left: 2px;
    color: $white;
  }

  &__share-controls-button {
    text-transform: uppercase;

    .ui-icon {
      font-size: 22px;
      margin-right: 10px;
    }
  }
}
