@import '/src/ui/index';

body .vendors-page-actions {
  opacity: 0;
  transition: unset;
  display: flex;
  justify-content: end;

  &__action {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;
  }

  &__icon {
    font-size: 23px !important;
  }

  &__remove {
    color: $red-500;

    svg {
      color: $red-500;
    }
  }
}

.ant-table-tbody tr {
  cursor: pointer;

  &:hover {
    .vendors-page-actions {
      opacity: 1;
    }
  }
}
