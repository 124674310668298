@import '../../../../ui';

body .vendors-filters-filter {
  width: 220px;
  display: flex;
  margin-right: 40px;

  .ui-select {
    width: 100%;
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
    background-color: $gray-60 !important;
    border-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;

    &:before {
      margin-right: 5px;
    }
  }

  &--vendor-risk-filter {
    width: auto;
    max-width: 300px;
    margin-right: 10px;
  }

  &--company-filter {
    .ui-select {
      width: 220px;
    }
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 60px !important;
  }

  &__close-button {
    color: $gray-400;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover,
    &:focus {
      background: $gray-60;
      border: 1px solid #d9d9d9;
      color: $black-100;
      opacity: 0.8;
    }
  }
}
