@import '/src/ui/index.scss';

body .assessment-controls-control {
  position: relative;
  display: flex;
  margin-bottom: 30px;

  &:hover .upstream-ai-button--explain {
    display: flex;
  }

  &__control-wrapper {
    &:hover .upstream-ai-button--example,
    &:hover .upstream-ai-button--improve,
    &:hover .upstream-ai-button--audit {
      display: flex;
    }
    max-width: 800px;
    position: relative;
  }

  &:last-child {
    margin-bottom: 0;

    .assessment-controls-control__wrapper {
      border: 0;
    }
  }

  &__key {
    width: 50px;

    &--collaborate {
      width: 80px;
    }

    &--error span {
      color: $red-500;
      font-weight: $font-bold;
      background: $red-100;
      padding: 3px;
    }
  }

  &__wrapper {
    width: 100%;
    border-bottom: 1px solid $gray-100;
    padding-bottom: 30px;
  }

  &__app-icons {
    display: flex;
    gap: 10px;
    margin: -5px 0 10px;
  }

  &__app-icon {
    width: 30px;
    height: 30px;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 20px;
    max-width: 800px;
    position: relative;
    display: flex;
    align-items: center;
  }

  &__title-content {
    display: inline-block;
    position: relative;
  }

  &__title-pre {
    overflow: hidden;
    white-space: pre-wrap;
    margin-bottom: 0;
  }

  &__title--rtl,
  &__description--rtl,
  &__description-bottom--rtl {
    direction: rtl;
    text-align: right;
  }

  &__description,
  &__description-bottom {
    display: flex;
    margin-bottom: 10px;
    max-width: 800px;
  }

  &__description-ico {
    font-size: 18px;
    color: $gray-400;
    margin: 2px 8px 0 0;
  }

  &__description--rtl,
  &__description-bottom--rtl {
    .assessment-controls-control__description-ico {
      margin: 2px 0 0 8px;
    }
  }

  &__control-type-wrapper {
    display: flex;
    width: 100%;
  }

  &__suggestion-button {
    background: $green-400;
    color: $white;
    border-radius: 35px;
    width: 35px;
    padding: 0;
    margin: 14px 0 0 10px;

    .ui-icon {
      font-size: 20px;
      padding-top: 2px;
    }

    &:hover,
    &:focus,
    &:active {
      background: $green-400;
      color: $white;
      opacity: 0.8;
    }

    &--near-title {
      position: absolute;
      margin: 0;
      right: 0;
      top: -5px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 800px;
  }

  &__chat {
    margin-top: 10px;
  }

  &__share-button {
    @include blueButton();
  }

  &__control-type {
    margin-bottom: 20px;
    max-width: 800px;
    width: 100%;

    .ui-radio-group {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .ui-textarea {
      width: 100%;
    }

    .ui-checkbox-group__children {
      flex-direction: column;
      gap: 20px;
    }

    .ui-checkbox--mode-checkbox {
      margin: 0;
    }

    &--with-suggestion:not(.control--with-disabled-suggestion) {
      .ui-select {
        .ant-select:not(.ant-select-disabled) {
          .ant-select-selector {
            background: $purple-50 !important;
          }
        }
      }

      .ui-input-control,
      .ui-textarea-control {
        background: $purple-50;
      }

      .ui-checkbox--mode-checkbox {
        input[type='checkbox']::before {
          background: $green-400;
        }
      }

      .ui-radio input[type='radio']::before {
        background: $green-400;
      }
    }
  }

  &__action-approve {
    margin-left: auto;
  }

  &__error-icon {
    margin-right: 8px;
  }

  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }

  &__error-message {
    color: $red-500;
    font-weight: $font-bold;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    &:focus {
      color: black;
    }
  }

  &__share-response-wrapper {
    margin-left: auto;
  }

  &__share-response-label {
    margin-right: 10px;
  }

  &__share-response-switch {
    &.ant-switch-checked {
      background: $blue-500;
    }
  }
}
