@import '/src/ui/index.scss';

body .assessment-collaborate {
  margin-left: auto;

  &__description {
    margin: -17px 0 20px;
  }

  &__button {
    background: $blue-500;
    color: $white;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-size: $font-14;
    font-weight: 600;
    padding: 4px 0 4px 7px;
    border: none;

    &:hover,
    &:focus {
      background: $blue-500;
      color: $white;
    }

    &--color-pink {
      background: $pink-500;

      &:hover,
      &:focus {
        background: $pink-600;
        color: $white;
      }
    }
  }

  &__icon {
    font-size: 22px;
    margin-right: 10px;
  }

  &__continue {
    font-weight: 500;
    text-decoration: underline;
    font-size: $font-12;
    margin-left: 8px;
  }

  &__cancel-button {
    color: $white;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  &__divider {
    content: '';
    width: 1px;
    height: 32px;
    border-right: 1px solid $white;
    margin-left: 10px;
    display: inline-block;
    opacity: 0.5;
  }

  &__modal {
    .ant-modal-footer {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__comment,
  &__email {
    margin-bottom: 10px;
  }

  &__label {
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
  }

  &__questions {
    background: $gray-50;
    border: 1px solid $gray-200;
    padding: 15px 15px 0;
    margin: 0 0 5px;
    border-radius: 4px;

    &--error {
      border-color: $error;
    }
  }

  &__question {
    display: flex;
    margin-bottom: 10px;
  }

  &__question-key {
    width: 80px;
  }

  &__question-text {
    width: 100%;
  }

  &__error {
    color: $error;
    font-size: 13px;
    font-weight: $font-semi-bold;
  }
}
