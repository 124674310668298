@import '../../ui';

body .assessment-vendor-name {
  font-weight: 600;

  &__icon {
    font-weight: normal;
    margin-left: 5px;
    color: $gray-400;
  }

  &__label {
    font-weight: normal;
    margin-left: 3px;
    color: $gray-400;
  }

  &__link {
    color: $blue-500;

    &:hover,
    &:focus {
      color: $blue-700;
      text-decoration: underline;
    }
  }
}
