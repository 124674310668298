@import '/src/ui/index.scss';

body .recent-page-evidences-status {
  display: flex;
  align-items: center;

  &__label {
    background: $gray-250;
    color: $white;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.7;
    width: 90px;
    padding: 0 5px;
    margin-right: 5px;

    &--0 {
      background: $gray-400;
    }
    &--1 {
      background: $orange-300;
    }
    &--2 {
      background: $green-400;
    }
  }
}
