@import '/src/ui/index.scss';

.ui-tile-with-title {
  background: $white;
  margin: 15px;
  /* finding filed shadow */

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  padding: 22px 30px;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;

    /* Colors / Black / 100% */

    color: $black-100;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* grey text */

    color: $gray-400;
  }
}
