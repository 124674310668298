@import '/src/ui/index.scss';

.import-wizard-menu-modal {
  .ant-modal-content {
    min-height: 474px;
    padding-bottom: 40px;
  }

  .ant-modal-body {
    padding: 27px;
  }

  &__select-type-content {
    display: flex;
    justify-content: space-evenly;
  }

  &__footer {
    border-top: 1px solid $gray-200;
    box-shadow: 0px -10px 24px rgba(0, 0, 0, 0.02), 0px -2px 6px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.04);
  }
}
