@import '/src/ui/index.scss';

body .assessments-page-templates {
  .ant-pagination {
    padding-right: 16px;
  }

  .ant-table-thead {
    background: $white;
    position: sticky;
    top: $headerHeight;
    z-index: 1;
  }

  .ant-table-thead > tr > th {
    padding: 15px 16px 7px;
  }

  &__actions {
    padding: 20px 30px;
    display: flex;
    align-items: center;
  }

  &__actions-right {
    margin-left: auto;
  }

  &__add-template-button {
    display: flex;
    align-items: baseline;
    padding: 0 16px;

    .ui-button__before-icon {
      margin-right: 6px;
      display: flex;
      font-size: $font-12;
    }
  }

  &__menu {
    .ui-icon {
      font-size: 20px;
    }
  }
}

.templates-search {
  max-width: 240px;
}
