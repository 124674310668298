@import '/src/ui/index.scss';

body .overview-filters {
  display: flex;
  margin-bottom: 30px;

  &__filters {
    margin: 0 20px 0 0;
  }

  &__add-filter {
    margin: 0;
  }

  .assessments-filters-filter {
    background: $white;

    .ant-select-selector {
      background: $white !important;
    }
  }
}
