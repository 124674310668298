@import '/src/ui/index.scss';

.upload-file-area {
  &__title {
    display: flex;
    justify-content: left;
    h2 {
      font-weight: $font-semi-bold !important;
      margin: 0;
    }
  }

  &__upper-text {
    font-weight: 400;
    font-size: 14px;
  }

  &__supported-types {
    font-weight: 400;
    font-size: $font-12;
    color: $gray-400;
  }

  &__icon {
    font-size: 40px;
    color: $gray-200;
    margin-bottom: 10px;
  }

  &__drop-area {
    border: 1px dashed $gray-400;
    border-radius: 4px;
    padding: 4px;
  }

  .dragged-area {
    border: 1px dashed $dark-blue-400;
  }

  &__body {
    h3 {
      font-weight: $font-semi-bold;
      margin: 0;
    }
  }

  &__drop-area-content {
    background: $gray-50;
    width: 708px;
    height: 343px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .dragged-content {
    background: $blue-50;
  }

  &__browse-button {
    margin: 15px auto;
    align-items: center;
    padding-top: 2px;
    .ui-button__before-icon {
      font-size: $font-16;
      margin-top: 2px;
    }
  }

  &__file-item {
    margin: 0 auto;
    margin-top: 10px;
  }
}
