@import '/src/ui/index.scss';

.stage-description {
  width: 300px;
  * {
    color: $gray-50;
    margin: 0px;
  }

  h2 {
    font-weight: $font-light;
  }

  h1 {
    margin-top: 50px;
  }
}
