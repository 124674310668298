@import '/src/ui/index.scss';

.my-files-upload-item {
  width: 254px;
  height: 254px;
  border: 1px solid $gray-200;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  transition: box-shadow $regular-transition;

  &:hover {
    box-shadow: $box-shadow-2;
  }

  &__file-preview {
    position: relative;
    flex: 1;
    background: $gray-75;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    width: 100%;
    height: 0;
    border-radius: 5px 5px 0 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--selected {
    outline: 2.5px solid $blue-500;
    user-select: none;
  }

  &__file-label {
    position: absolute;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: $font-bold;
    font-size: 10px;
    bottom: 81px;
    color: white;
    background: #e45647;
    line-height: 12px;
    width: 100%;
    &--expiring-soon {
      background: $orange-300;
    }
    &--expired {
      background: $red-500;
    }
    &--valid {
      background: $green-400;
    }

    & svg {
      margin-right: 4px;
    }
  }

  &__file-info {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: $font-semi-bold;
    font-size: 14px;
    padding-top: 16px;
    height: 81px;
    background: $white;
    border-radius: 0 0 5px 5px;
  }
}
