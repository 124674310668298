@import 'ui';

.phone-input {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: $s-12;

  & .fi {
    font-size: 12px;
  }
}

.phone-input-input {
  width: 100%;
}

.react-tel-input {
  display: flex;
  align-items: center;
}
