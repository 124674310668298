@import '/src/ui/index.scss';

.new-user-activation {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  &__card {
    width: 1280px;
    height: 695px;
    position: relative;
    white-space: pre-line;
    display: flex;
    flex-direction: row;
    padding: 0;
    background-color: $white;

    > div {
      width: 50%;
    }

    &__left-box {
      background-image: url('/assets/images/onboarding-welcome-background.png');
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;

      &__content {
        text-align: center;
        margin: 342px auto 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
          font-family: 'Montserrat';
          font-weight: $font-semi-bold;
          font-size: $font-46;
          margin: 0 0 10px 0;
          color: $white;
        }

        &__divider {
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            $white 54.3%,
            rgba(255, 255, 255, 0) 100%
          );
          width: 207.41px;
          height: 1.2px;
          margin: 18px 0;
        }

        p {
          font-variant: small-caps;
          font-family: 'Montserrat';
          font-weight: $font-semi-bold;
          font-size: $font-18;
          color: $white;
        }
      }
    }

    &__inner {
      padding: 60px 0 60px 40px;
      position: relative;

      &__title {
        margin-bottom: 50px;

        h2 {
          font-size: $font-32;
          font-weight: $font-semi-bold;
          margin-bottom: 6px;
        }

        p {
          font-weight: $font-medium;
          line-height: 28px;
          font-size: $font-18;
        }
      }

      &__content {
        display: flex;
        align-items: flex-start;
        margin-bottom: 44px;

        > div {
          margin-left: 16px;

          h3 {
            margin-bottom: 5px;
            font-size: $font-18;
            font-weight: $font-bold;
          }

          p {
            margin-bottom: 0;
            font-size: $font-16;
            font-weight: $font-normal;
            line-height: 24px;
            padding-right: 70px;
          }
        }
      }

      &__footer {
        background: $pink-500;
        padding: 10px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px 0px 0px 4px;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 60px;
        margin-left: 40px;

        p {
          color: $white;
          font-size: $font-16;
          font-weight: $font-bold;
          line-height: 24px;
          margin: 0;
          margin-right: 20px;
        }

        button {
          box-shadow: none;
          border-radius: 30px;
          border: 1px solid $white;
          height: auto;
          line-height: 100%;
          font-size: $font-12;
          padding: 10px 16px;

          .ui-button__name {
            margin-right: 6px;
          }
        }
      }
    }
  }
}
