@import '/src/ui/index.scss';

body .recent-page-vendors-inherent-risk {
  display: inline-block;

  &:hover {
    .recent-page-vendors-inherent-risk__score {
      font-weight: $font-semi-bold;
    }
  }

  &__wrapper {
    text-align: center;
  }

  .recent-page-vendors-inherent-risk__scor {
    transition: font-weight 0.2s ease;
  }

  &__value {
    font-size: $font-10;
    font-weight: 600;
    text-transform: uppercase;
  }

  &--Low {
    .recent-page-vendors-inherent-risk__value {
      color: $green-400;
    }
  }

  &--Medium {
    .recent-page-vendors-inherent-risk__value {
      color: $orange-300;
    }
  }

  &--High {
    .recent-page-vendors-inherent-risk__value {
      color: $red-500;
    }
  }
}
