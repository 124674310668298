@import '/src/ui/index.scss';

.so-page-stage-one {
  &__content {
    padding: 50px 10px 20px 20px;
  }

  &__title {
    h1 {
      margin: 0px;
    }
  }

  &__illustration {
    position: absolute;
    bottom: -50px;
    left: -100px;
  }
}
