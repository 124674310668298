@import '/src/ui/index.scss';

body .assessment-finalize-loading {
  &__image {
    margin-bottom: 20px;
  }

  &__text {
    font-size: $font-20;
    font-weight: 600;
    padding: 0 20px;
    margin-bottom: 20px;
  }
}
