@import '/src/ui/index.scss';

@mixin main-theme() {
  color: white;
  border-radius: $border-radius-lg;
  height: $sm;
  line-height: 42px;
  text-transform: uppercase;
}

@mixin theme($color, $blue-100, $isWhite) {
  display: flex;
  align-items: baseline;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(90, 22, 44, 0.16);
  background-color: $color;
  border: 1px solid $color;
  @include main-theme();

  @if ($isWhite) {
    color: $black-100;
    border: 1px solid $gray-250;
  }

  &:hover {
    background-color: $blue-100;
    border-color: $blue-100;
    cursor: pointer;
  }

  &:active {
    background-color: darken($color, $darken-active-multiplier);
  }

  &:disabled {
    background-color: lighten($color, $lighten-inactive-multiplier);
    border-color: lighten($color, $lighten-inactive-multiplier);
    cursor: not-allowed;
    transition: all 4s ease;
  }
}

@mixin outline-theme($color, $blue-100) {
  @include main-theme();
  background-color: transparent;
  border: 1px solid $color;
  color: $color;

  &:hover:not(:disabled) {
    background-color: $blue-100;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:disabled {
    color: lighten($color, $lighten-inactive-multiplier);
  }
}

@mixin confirmed-theme($color, $background) {
  @include main-theme();

  color: $color;
  background-color: $background;
  border-color: transparent;

  &:hover {
    color: $color;
    background-color: $background;
  }

  &:active {
    background-color: $background;
  }
}

@mixin link-theme($color) {
  height: auto;
  min-width: 0;
  padding: 1px;
  font-weight: normal;
  line-height: 1;
  background-color: transparent;
  border: none;
  color: $color;
  text-transform: unset;
  font-family: Montserrat, sans-serif;
  box-shadow: none;

  &:hover {
    background-color: transparent;

    .ui-button__name {
      text-decoration: underline;
    }
  }

  &.ui-button--uppercase {
    text-transform: uppercase;
  }
}

@mixin size-variant($size, $font-size) {
  height: $size;
  line-height: $size;
  font-size: $font-size;
  display: flex;
  align-items: baseline;
  padding: 0 20px;
  justify-content: space-evenly;
  .ui-button__before-icon {
    font-size: $font-size;
    margin-right: $size/5;
  }
  .ui-button__after-icon {
    font-size: $font-size;
  }
}

.ui-button {
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  outline: none;
  padding: 0 12px 0 12px;
  text-align: center;
  font-family: Montserrat SemiBold, sans-serif;
  position: relative;

  transition: all $transition-timing ease;
  border: none;

  &__name {
    font-family: Montserrat, sans-serif !important;
    font-weight: $font-medium;
  }

  &:active:not(:disabled) {
    transform: translateY(5%);
  }

  // Button Themes
  &--pink-button {
    @include theme($pink-500, $pink-600, $isWhite: false);
  }

  &--purple-button {
    @include theme($purple-200, $purple-300, $isWhite: false);
  }

  &--green-button {
    @include theme($Turquoise-400, $Turquoise-500, $isWhite: false);
  }

  &--white-button {
    @include theme($white, $gray-75, $isWhite: true);
  }

  &--blue-button {
    @include theme($blue-500, $blue-700, $isWhite: false);
  }

  &--red-button {
    @include theme($red-500, $red-450, $isWhite: false);
  }

  // Outline Button
  &--pink-outline {
    @include outline-theme($pink-500, $pink-100);
  }

  &--purple-outline {
    @include outline-theme($purple-200, $purple-100);
  }

  &--green-outline {
    @include outline-theme($Turquoise-400, $Turquoise-100);
  }

  &--gray-outline {
    @include outline-theme($gray-250, $gray-60);
  }

  &--black-outline {
    @include outline-theme($black-100, $gray-60);
  }

  &--blue-outline {
    @include outline-theme($blue-500, $blue-50);
  }

  // Confirmed Button
  &--pink-confirmed {
    @include confirmed-theme($pink-500, $pink-300);
  }

  &--green-confirmed {
    @include confirmed-theme($Turquoise-400, $Turquoise-300);
  }

  // Button Sizes
  &--xs {
    @include size-variant($xs, $font-11);
    text-transform: none;
  }

  &--sm {
    @include size-variant($sm, $font-12);
  }

  &--md {
    @include size-variant($md, 1em);
  }

  &--lg {
    @include size-variant($lg, 1em);
  }

  // Other styles
  &--lowercase {
    text-transform: initial;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--block {
    width: 100%;
  }

  &--fluid {
    min-width: 0;
  }

  &--squared {
    border-radius: $border-radius-xs;
  }

  &--pink-link {
    @include link-theme($pink-500);
  }

  &--white-link {
    @include link-theme($white);
  }

  &--green-link {
    @include link-theme($Turquoise-400);
  }

  &--purple-link {
    @include link-theme($purple-200);
  }

  &--black-link {
    @include link-theme($black-100);
  }

  &--gray-link {
    @include link-theme($gray-400);
  }

  &--blue-link {
    @include link-theme($blue-500);
  }

  &--dark-blue-link {
    @include link-theme($dark-blue-800);
  }

  &--centered {
    margin: auto;
  }

  &__before-icon,
  &__after-icon {
    font-size: 20px;
    vertical-align: sub;
    margin-left: 0;

    &.simple {
      text-decoration: none;
    }
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--with-hover {
    padding: 5px;
    border-radius: 4px;

    & .ui-button__name {
      text-decoration: none !important;
    }

    &:hover {
      background: $gray-60;
    }
  }
}
