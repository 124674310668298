@import '/src/ui/index.scss';

body .assessment-controls-activate-cloud-monitoring {
  padding: 8px 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  background: $blue-100;
  border-top: 1px solid $blue-500;

  &__button {
    width: 170px;

    .ui-secondary-button__loader {
      color: $white;
      margin-right: 10px;
    }
  }

  &__button-icon {
    margin-right: 10px;
  }

  &__cloud-icon {
    font-size: 24px;
    color: $green-400;
  }

  &__title {
    font-weight: 600;
  }

  &__cancel-request-button {
    width: max-content;
  }
}
