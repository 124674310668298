@import '/src/ui/index.scss';

.ui-text {
  &--light {
    font-weight: $font-light !important;
  }

  &--no-margin {
    margin: 0 !important;
  }

  &--text-text {
    color: $black-100;
  }

  &--gray-text {
    color: $gray-400 !important;
  }

  &--pink-text {
    color: $pink-500 !important;
  }

  &--purple-dark-text {
    color: $purple-500 !important;
  }
}
