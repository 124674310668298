@import '/src/ui/index.scss';

body .editor-edit-answers {
  position: relative;

  &__main {
    display: flex;
    padding: 18px;
  }

  &__key {
    width: 50px;
  }

  &__wrapper {
    width: 100%;
  }

  &__title {
    font-size: 15px;
    color: $black-100;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__header {
    font-size: 15px;
    color: $black-100;
    font-weight: 500;
    padding: 18px 18px 0;
    display: flex;
    align-items: center;

    .ui-icon {
      margin-right: 4px;
      font-size: 18px;
    }
  }

  &__answer-type {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__action-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $gray-200;
    padding: 4px 4px;
  }

  &__footer-ok-button,
  &__footer-ok2-button {
    min-width: 120px;
  }

  &__footer-ok2-button {
    margin-left: auto;
    margin-right: 10px;
  }

  &__action-bar-left-area {
    display: flex;
    align-items: center;
  }
}
