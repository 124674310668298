@import '../../ui';

.pending-trusted-users-modal {
  &__header {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: $font-semi-bold;
    margin: 31px 0 25px 0;
    & > svg {
      margin-right: 11px;
    }
  }
  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &__select {
    width: 100%;
    .ant-select-selector {
      background: white !important;
    }
    .ant-select-selection-item {
      background: $blue-50 !important;
    }
  }
  &__table {
    .ant-table-cell {
      padding: 6px !important;
    }
  }

  &__reject-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
//ui-select pending-trusted-users-modal__select ui-select--color-gray
