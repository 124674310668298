@import '/src/ui/index.scss';

body .editor-add-question {
  border-radius: 7px;
  border: 1px solid $gray-250;
  border-left: 4px solid $gray-250;
  margin: 0 0 15px 50px;
  position: relative;

  &--is-active {
    border: 1px solid $blue-500;
    border-left: 4px solid $blue-500;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.15);
  }

  &__main {
    display: flex;
    padding: 18px;
    color: $gray-400;
  }

  &__key {
    width: 50px;
    padding-top: 49px;
  }

  &__wrapper {
    width: 100%;
  }

  &__top-description,
  &__bottom-description {
    .editable-content__content,
    .editable-content__editable {
      font-size: 13px;
      line-height: 1.5;
      color: $gray-400;
    }
  }

  &__bottom-description {
    margin-bottom: 10px;
  }

  &__title {
    margin: 5px 0;

    .editable-content__content,
    .ui-textarea-control {
      font-size: 15px;
      color: $black-100;
      padding: 11px;
      font-weight: 500;
    }

    .editable-content__content {
      border: 1px solid $gray-200;
      border-radius: $border-radius-xs;
    }
  }

  &__add-button-wrapper {
    margin: 0 0 20px 50px;
    display: flex;
    gap: 20px;
  }

  &__add-button {
    flex-grow: 1;

    .ui-icon {
      font-size: 10px;
    }
  }

  &__add-trigger-button {
    width: 250px;

    .ui-icon {
      font-size: 10px;
    }
  }

  &__action-bar {
    border-top: 1px solid $gray-200;
    display: flex;
    padding: 4px 4px;
    align-items: center;
  }

  &__action-bar-chat-icon {
    margin-left: 10px;
    padding: 8px;
  }

  &__cancel-button {
    margin-left: auto;
    margin-right: 10px;
  }
}
