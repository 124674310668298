@import '/src/ui/index.scss';

.contact-information-tab {
  &__confirm-modal {
    .ant-modal-body {
      text-align: center;
      font-size: $font-20;
      font-weight: 600;
    }

    &-trash {
      margin-bottom: 10px;
    }
  }
}
