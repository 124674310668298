@import '/src/ui/index.scss';

.user-management-tab {
  &__top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__table {
    margin-top: 30px;
    .ant-table-row {
      cursor: default !important;
    }
  }

  &__role-selection {
    .ant-select-selector {
      min-width: 105px;
    }
  }
}
