@import '/src/ui/index.scss';

body .editor {
  background: $gray-100;
  padding: 30px;
  height: 100%;

  &__header-tag-assessment,
  &__header-tag-template {
    color: $white;
    display: inline-block;
    padding: 3px 16px;
    font-size: 12px;
    margin-left: 15px;
    text-transform: uppercase;
  }
  &__header-tag-assessment {
    background: $green-400;
  }
  &__header-tag-template {
    background: $orange-300;
  }

  &__header-buttons {
    position: absolute;
    right: px2rem(30);
    display: flex;
    align-items: center;

    & button:last-child {
      margin-left: px2rem(8);
    }
  }

  &__header-button-edit-template {
    text-transform: uppercase;
    border-radius: 0px;
    align-items: center;
    .ui-icon {
      font-size: $font-14;
    }
  }
  &__main-wrapper {
    min-height: 500px;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  &__subjects-wrapper {
    padding: 45px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__settings-wrapper {
    min-width: 362px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  }

  &__questionnaire {
    display: flex;
    height: 100%;
  }

  &__questionnaire-left {
    width: 30%;
  }

  &__questionnaire-right {
    width: 70%;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  &__template-header-description {
    white-space: break-spaces;
    margin-bottom: 30px;
    position: relative;
  }

  &__template-header-title {
    margin-bottom: 40px;
    position: relative;
  }

  &__template-header-title-input {
    font-size: $font-20;
    font-weight: $font-semi-bold;
    padding: 30px 20px;
  }

  &__template-header-description-input {
    color: $gray-400;
    background: $white;
    margin-left: 19px;
    margin-top: 5px;
  }

  &__continue-button {
    margin-top: 30px;
    align-self: center;
    & svg {
      margin-left: 5px;
    }
    & .ui-button__name {
      display: flex;
      align-items: baseline;
    }
  }

  & .ui-tabs {
    height: calc(100% - 22px);
  }
  & .ant-tabs,
  & .ant-tabs-content {
    height: 100%;
  }
}
