@import '/src/ui/index.scss';

.dashboard {
  position: relative;
  padding: 30px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      h1 {
        font-size: $font-28;
        color: $black-100;
        font-weight: 600;
        margin: 0;
        display: flex;
        align-items: center;
      }

      &__seperator {
        width: 2px;
        height: 20px;
        background-color: $gray-200;
        margin: 0 16px;
      }
    }
  }
}
