@import '/src/ui/index.scss';

body .assessment-finalized-finding {
  background: $gray-60;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;

  &__finding-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__finding-header-text {
    font-size: $font-16;
    font-weight: 600;
    margin: 0;
  }

  &__question-wrapper {
    display: flex;
    align-items: baseline;
    gap: 5px;
    margin-bottom: 10px;
    margin-right: 30px;

    p {
      margin: 0;
    }
  }

  &__question-label,
  &__answer-label {
    width: 85px;
    color: $gray-400;
    font-size: 11px;
    font-weight: 500;
  }

  &__question,
  &__answer {
    width: 100%;
    font-size: 13px;
    margin-top: -3px;
    display: flex;
    align-items: center;
  }

  &__answer {
    .ui-radio-group,
    .ui-checkbox-group__children {
      flex-direction: column;
      gap: 5px;
    }
  }

  &__answer-wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 20px;
  }

  &__description {
    textarea {
      background: $white;
    }

    &--custom {
      background: $white;
      padding: 18px;
      overflow: auto;
      margin-bottom: 10px;
      height: 140px;
      font-size: $font-14;
    }
  }

  &__control-wrapper {
    min-width: 300px;
  }
}
