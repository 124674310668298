@import '/src/ui/index.scss';

body .findings-status-select {
  width: 120px;

  .ant-select-selector:before {
    font-size: 11px !important;
    font-weight: 500 !important;
  }

  .ant-select-selector {
    padding-left: 0 !important;

    &:before {
      display: none !important;
    }
  }

  .ant-select-selection-item {
    font-weight: 500;
    color: $white;
    padding: 0 !important;
    text-align: center;
  }

  .ant-select-open {
    .ant-select-selection-item {
      color: $white;
    }
  }

  &--0 {
    .ant-select-selection-item {
      background-color: $green-400;
    }
  }

  &--1 {
    .ant-select-selection-item {
      background-color: $gray-400;
    }
  }

  &--2 {
    .ant-select-selection-item {
      background-color: $orange-300;
    }
  }

  &__dropdown {
    .ant-select-item:nth-child(1) {
      color: $green-400;
    }
    .ant-select-item:nth-child(2) {
      color: $gray-400;
    }

    .ant-select-item-option-disabled {
      display: none;
    }
  }
}

body .findings-status-select__confirmation-modal {
  .ant-modal-footer {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 15px 25px;

    .ui-button {
      text-transform: uppercase;
    }
  }
}
