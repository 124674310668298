@import '/src/ui/index.scss';

body .chat {
  background-color: $gray-50;
  position: relative;

  &__container-wrapper {
    position: relative;
    overflow-y: auto;
    height: 300px;
  }

  &__loader {
    text-align: center;
    margin-top: 45%;
  }

  &__close-button {
    color: $gray-400;
    position: absolute;
    top: 5px;
    right: 20px;
    padding: 4px 4px;

    &:hover,
    &:focus {
      color: $gray-500;
    }
  }
}

body .chat-container {
  padding-bottom: 60px;

  &__thread {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    margin-top: 20px;
  }

  &__date {
    display: block;
    text-align: center;
    color: $gray-300;
    font-size: 0.9em;
  }

  &__divider {
    border: 0;
    border-bottom: 1px solid $gray-100;
    margin: 5px 0;
  }
}

.chat-input {
  padding: 10px;
  display: flex;
  align-items: center;

  &__input {
    width: 100%;

    .ui-textarea-control {
      background: $white;
      border-radius: 30px;
      margin: 0;
      padding: 8px 33px 8px 8px;
      min-height: 38px;
      max-height: 145px;
      resize: none;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 30px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 3px 3px $gray-200;
        border: solid 12px transparent;
      }

      &::-webkit-scrollbar-corner {
        background: transparent;
      }
    }
  }

  &__send-button {
    padding: 0;
    height: 35px;
    width: 35px;
    margin-left: 8px;

    &:disabled {
      opacity: 0.7;
      transition: $regular-transition;
    }
  }

  &__send-icon {
    height: 35px;
    width: 35px;
    font-size: 1.5em;
  }
}

.chat-bubble {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 8px;
  position: relative;
  margin-top: 20px;

  &__content {
    display: inline-block;
    margin-bottom: 5px;
    padding: 13px 14px;
    vertical-align: top;
    border-radius: 8px;
  }

  &--me {
    align-self: flex-start;

    .chat-bubble__content {
      background-color: $blue-500;
      color: $white;
      border-radius: 0 8px 8px 8px;
    }
  }

  &--you {
    align-self: flex-end;

    .chat-bubble__content {
      background-color: $white;
      border: 1px solid $gray-200;
      border-radius: 8px 0 8px 8px;
    }
  }

  &__time {
    font-size: 0.85em;
    align-self: flex-end;
    color: $gray-400;
  }

  &__msg {
    margin-right: 60px;
    max-width: 450px;
    display: inline-block;
    overflow-wrap: break-word;
  }

  &__author {
    font-size: 0.85em;
    color: $gray-400;
    position: absolute;
    top: -20px;
    left: 4px;
    width: 100%;
  }
}
