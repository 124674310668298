@import '../../../ui';

body .assessment-finalize-cloud-monitoring-activate-popup {
  .ant-modal-body {
    padding: 0 30px;
  }

  &__footer-ok-button {
    margin-bottom: 15px;
  }

  &__icon-title {
    margin-right: 8px;
  }

  &__description {
    padding-top: 30px;
    font-size: $font-14;
    font-weight: 500;
    color: $gray-500;
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
  }
}
