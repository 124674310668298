@import '/src/ui/index.scss';

body .ui-alert {
  border-radius: 5px;
  box-shadow: 1px 1px 4px $gray-250;

  .ui-icon {
    color: $white;
    font-size: 23px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }

  &.ant-alert-success {
    background: $green-100;
    border: 1px solid $green-400;

    .ui-icon {
      background: $green-400;
    }
  }

  &.ant-alert-warning {
    background: $orange-100;
    border: 1px solid $orange-300;

    .ui-icon {
      background: $orange-300;
    }
  }

  &.ant-alert-error {
    background: $red-200;
    border: 1px solid $red-600;

    .ui-icon {
      background: $red-600;
    }
  }
}
