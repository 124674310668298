@import '/src/ui/index.scss';

.score-chip {
  padding: px2rem(7.5) px2rem(11);
  border-radius: px2rem(2);
  color: white;
  text-transform: uppercase;
  font-size: px2rem(11);
  font-weight: $font-bold;
  min-width: 88px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__primary {
    background: $green-400;
  }
  &__warning {
    background: $orange-300;
  }
  &__danger {
    background: $red-500;
  }
}
