@import '/src/ui/index.scss';

body .editor-category-triggers {
  &__title {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
  }

  &__triggers {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    color: $gray-400;
  }

  &__trigger {
    cursor: pointer;

    &:hover {
      color: $blue-500;
    }
  }

  &__trigger-popover-suffix {
    color: $gray-400;
    display: inline-block;
    margin-right: 5px;
  }

  &__trigger-popover-link {
    font-size: 13px;
    padding: 0;
    color: $gray-500;
  }
}
