@import '/src/ui/index.scss';

body .assessments-page {
  padding: 30px;

  &__wrapper {
    background: $white;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
  }

  &-actions {
    padding: 20px 30px;
    display: flex;
    align-items: center;

    .ui-input-control--search {
      width: 240px;
    }
  }

  &__actions-right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__action {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;

    .ant-btn {
      font-size: 23px;
      padding: 0 3px;
      color: $gray-500;
    }

    &.ant-tooltip-disabled-compatible-wrapper {
      padding: 0;
      display: flex !important;
      align-items: center;

      .ant-btn {
        color: $gray-300;
      }
    }
  }

  &__assign-button {
    padding: 0 20px 0 20px;
    min-width: auto;
    margin-left: 20px;

    .ui-button__before-icon {
      margin-right: 6px;
    }
  }

  &__created-assessment-from-wizard {
    & h2 {
      font-size: 20px;
      font-weight: $font-semi-bold;
    }
    & p {
      font-size: 14px;
      color: $gray-400;
    }

    & .ant-modal-body {
      display: flex;
      padding-top: 40px;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 340px;
      margin: 0 auto;
    }
    & .ant-modal-footer {
      padding-bottom: 47px;
    }
  }
}
