@import '/src/ui/index.scss';

body .assessment-due-date {
  font-weight: 600;

  &--without-back-action {
    margin-left: 0;
  }

  &__icon {
    font-weight: normal;
    margin-left: 5px;
    color: $gray-400;
  }

  &__label {
    font-weight: normal;
    margin-left: 5px;
    color: $gray-400;
  }
}
