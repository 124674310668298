@import '/src/ui/index.scss';

body .base-layout-main-menu {
  background: transparent !important;
  border: 0;
  padding: 5px;
}

body .base-layout-main-menu {
  &__menu-item {
    padding-left: 16px !important;
    color: $white !important;
    width: 100% !important;
    border-radius: 3px;
    margin-bottom: 16px !important;
    display: flex;
    align-items: center;

    &:after {
      display: none;
    }

    &.ant-menu-item-selected {
      background-color: $purple-200 !important;
    }

    .ant-menu-item-icon {
      font-size: 20px !important;
      width: 20px;
      text-align: center;
    }
    & svg{
      min-width: 16px !important;
    }
  }

  &.ant-menu-dark {
    .base-layout-main-menu__menu-item.ant-menu-item-selected {
      background-color: $dark-blue-400 !important;
    }
  }
}
