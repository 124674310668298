@import '/src/ui/index.scss';

body .editable-content {
  position: relative;

  &__content {
    padding: 4px;
    border: 1px solid transparent;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background: $gray-50;
    }
  }

  &__editable {
    .ui-textarea-control {
      width: 100%;
      height: 32px;
      padding: 4px;
      margin: 0;
      display: block;
    }
  }

  &__loader {
    position: absolute;
    right: 10px;
    top: 5px;

    .anticon {
      color: $gray-250;
    }
  }

  &__pre {
    margin-bottom: 0;
    font-family: Montserrat, sans-serif !important;
    overflow: hidden;
    white-space: pre-wrap;
  }
}
