@import '/src/ui/index.scss';

body .continuous-monitoring-tab-notes {
  width: 1055px;
  margin: auto;
  padding-top:40px;

  &__notes-placeholder-wrapper {
    border: 1px solid $gray-200;
    border-radius: 5px;
    padding: 20px;
  }

  &__notes-placeholder {
    margin: auto;
    color: $gray-400;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    position: relative;
  }

  &__header-title-wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  &__header-title {
    font-weight: 600;
    font-size: $font-16;
  }

  &__header-title-icon {
    margin: 2px 8px 0 0;
    font-size: 20px;
  }

  &__add-note-button {
    position: absolute;
    right: 0;
    top: 5px;

    .ui-secondary-button__icon {
      font-size: 18px;
      margin-right: 8px;
    }
  }

  &__note-read-more-button {
    display: inline-block;
    opacity: 0;
    transition: $regular-transition;
  }

  &__note-corner {
    background: $white;
    position: absolute;
    right: -1px;
    bottom: -1px;
    opacity: 1;
    transition: $regular-transition;
  }

  &__note {
    border: 1px solid $gray-200;
    border-left: 3px solid $orange-200;
    border-radius: 5px;
    padding: 10px 20px 20px;
    margin-bottom: 12px;
    position: relative;
    cursor: pointer;
    transition: $regular-transition;

    &:hover {
      background: $example-feedback;
      box-shadow: $box-shadow-3;

      .continuous-monitoring-tab-notes__note-read-more-button {
        opacity: 1;
      }

      .continuous-monitoring-tab-notes__note-corner {
        opacity: 0;
      }
    }
  }

  &__note-wrapper {
    margin-right: 170px;
  }

  &__note-control-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-12;
  }

  &__note-control-title-control-position {
    margin-right: 7px;
    display: inline-block;
  }

  &__note-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    font-size: $font-12;
  }

  &__note-divider {
    border-left: 1px solid $gray-200;
    margin: 0 10px 0 2px;
    display: inline-block;
    height: 16px;
  }

  &__note-tag-wrapper {
    display: flex;
  }

  &__note-date {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: $font-12;
  }

  &__note-finding-flag {
    margin-right: 5px;
  }
}
