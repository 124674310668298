@import '/src/ui/index.scss';

body .assessment-vendor-due-date {
  font-weight: 600;
  display: flex;
  align-items: center;
  border: 1px solid $gray-200;
  border-radius: 4px;
  padding: 8px 10px 8px 10px;
  transition: 0.2s ease;

  &--today {
    border-color: $red-200;
    background: $red-200;
  }

  &__icon {
    font-weight: normal;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 8px;
    font-size: 24px;
  }

  &__label {
    font-weight: normal;
    color: $gray-400;
    font-size: $font-12;
    line-height: 1.2em;
  }

  &__time {
    font-size: $font-20;
    font-weight: 600;
    line-height: 1.2em;
  }

  &.due-date--scrolled {
    padding: 2px 12px;
    border: none;

    .assessment-vendor-due-date__icon {
      font-weight: normal;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 8px;
      font-size: 24px;
    }

    .assessment-vendor-due-date__label {
      font-size: $font-10;
    }

    .assessment-vendor-due-date__time {
      font-size: $font-16;
    }
  }
}
