@import '/src/ui/index.scss';

body .editor-trigger-menu {
  &__button {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;

    .ui-icon {
      position: relative;
    }
  }

  &__icon--active {
    &:after {
      content: '\e91f';
      display: block;
      font-size: 7px;
      background: $red-600;
      color: $white;
      border: 1px solid $white;
      border-radius: 12px;
      width: 13px;
      height: 13px;
      padding: 2px 0;
      position: absolute;
      left: 10px;
      top: 1px;
    }
  }
}
