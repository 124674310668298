@import '/src/ui/index.scss';

body .strengthen-auth {
  &__description {
    margin-bottom: 20px;
  }

  &__modal {
    .ant-modal-footer {
      align-items: start;
      padding: 0 24px 24px;
    }
  }
}
