@import '/src/ui/index.scss';

body .assessments-page-list {
  .showcase-name-indicator {
    margin-bottom: 0;
    color: $Turquoise-400;
    font-size: $font-11;
    font-weight: $font-semi-bold;
  }

  .empty-score {
    color: $gray-400;
    font-size: $font-14;
    font-weight: $font-normal;
  }

  &__table {
    .ant-table-thead > tr > th {
      padding: 15px 16px 7px;
    }

    .ant-table-tbody > tr:nth-child(odd):not(.ant-table-row-selected, :hover) > td {
      background: $white;
    }
    .ant-table-tbody > tr:nth-child(even):not(.ant-table-row-selected, :hover) > td {
      background: $gray-50;
    }
  }

  &__table-link {
    color: $black-100;

    .ui-icon {
      vertical-align: middle;
      line-height: 1.1;
      margin-right: 3px;
      font-size: 18px;
    }

    &:hover,
    &:focus {
      color: $blue-700;
      text-decoration: underline;
    }
  }

  .ant-pagination {
    padding-right: 16px;
  }

  &__due-date-ico {
    color: #828282;
    margin-right: 8px;
  }

  &__due-date {
    display: flex;
    align-items: center;

    &--expired {
      color: $error;

      .assessments-page-list__due-date-ico {
        color: $error;
      }
    }
  }

  &__actions {
    opacity: 0;
    transition: 300ms;
    & .assessments-page-list-actions__action.ant-tooltip-disabled-compatible-wrapper {
      display: initial !important;
    }
  }
}

.ant-table-tbody tr {
  cursor: pointer;

  &:hover {
    .assessments-page-list__actions {
      opacity: 1;
    }
  }
}
