@import "../../ui";

.force-submit-modal{
  padding: 6px;

  &__header{
    display: flex;
    align-items: center;
    font-size: $font-20;
    font-weight: $font-semi-bold;
    margin-bottom: 26px;
    line-height: 26px;

    & svg{
      margin-right: 10px;
    }
  }

  &__description{
    font-size: $font-15;
    margin-bottom: 24px;
  }

  &__footer{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    & button{
      text-transform: uppercase;
    }
  }
}