@import '/src/ui/index.scss';

.blinking-dot {
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;

  &--green {
    background-color: $green-400;
  }

  &--gray {
    background-color: $gray-400;
  }

  &--blue {
    background-color: $blue-500;
  }

  &--orange {
    background-color: $orange-300;
  }

  &--red {
    background-color: $red-500;
  }

  &--lightblue {
    background: $blue-450;
  }

  &--white {
    background-color: $white;
  }

  &--sm {
    height: 10px;
    width: 10px;
  }

  &--md {
    height: 12px;
    width: 12px;
  }

  &--static {
    position: relative;
  }

  &--animation {
    animation: 2.5s blink ease infinite;
  }
}
