@import '../../ui';

@mixin button-design($icon-color, $hover-color) {
  background: none;
  & .ui-button__before-icon {
    color: $icon-color;
  }
  &:hover,
  &--active {
    background: $hover-color;
  }
}

.upstream-ai {
  &__popover {
    width: 600px;
    & .ant-popover-title {
      background: $gray-75;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 9px;
    }
    & .ant-popover-inner {
      border-radius: 10px;
    }
    & .ant-popover-inner-content {
      min-height: 352px;
      width: 100%;
    }
  }

  &__popover-content-text {
    height: 270px;
    overflow-y: auto;
    font-size: $font-14;
    font-weight: $font-normal;
    line-height: 200%;
    padding: 0 10px 0 0;
    position: relative;
    white-space: break-spaces;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 8px;
    align-items: center;
  }

  &__title-text {
    color: $gray-400;
    font-size: $font-11;
    text-transform: uppercase;
  }

  &__title-buttons-list {
    display: flex;
    align-items: center;
  }

  &__title-button {
    padding: 0 7px;
    color: $text-color;
    border: none;
    box-shadow: none;
    margin-right: 10px;

    &--explain {
      @include button-design($pink-500, $explain-in-popup-hover);
    }
    &--example {
      @include button-design($example-icon, $example-in-popup-hover);
    }
    &--improve,
    &--audit {
      @include button-design($improve-icon, $improve-in-popup-hover);
    }
  }

  &__popover-content-actions {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
  }
}
