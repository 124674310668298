@import '/src/ui/index.scss';

.ui-excel-sheet {
  .Spreadsheet {
    font-size: $font-12;
    &__cell--readonly {
      color: $black-100;
    }
  }
}
