@import '/src/ui/index.scss';

body .editor-answer-creator-editor {
  &__header {
    display: flex;
    padding: 0 0 7px;
  }

  &__header-empty-space {
    width: 75%;
    margin-left: 28px;
  }

  &__header-weight {
    text-align: center;
    width: 85px;
    font-size: 11px;
    font-weight: 500;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__option {
    display: flex;
  }

  &__option-key {
    margin-right: 5px;
    padding: 4px 0;

    .ui-radio input[type='radio'] {
      margin-right: 5px;
    }
  }

  &__option-value {
    width: 75%;
    margin-right: 10px;
  }

  &__option-weight {
    width: 85px;

    .ant-select {
      min-width: 85px;
    }
  }

  &__add-option {
    cursor: pointer;
    display: flex;
    color: $gray-400;
    align-items: center;
    margin-top: 6px;
  }

  &__add-option-text {
    display: inline-block;
    padding-left: 4px;
  }

  &__add-option-key {
    margin-right: 10px;
  }

  &__remove-button {
    font-size: 18px;
    padding: 3px;
    margin-left: 10px;
    color: $gray-500;

    &:hover,
    &:focus,
    &:active {
      color: $black-100;
    }
  }
}
