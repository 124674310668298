@import '/src/ui/index.scss';

body .assessment-finalized-findings {
  position: sticky;
  top: 95px;
  overflow-y: scroll;
  height: 95vh;

  .assessment-finalized-add-finding__button {
    color: $blue-500;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 4px;

    &:hover,
    &:focus {
      color: $blue-700;
    }

    .ui-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }

  &__header-title-text {
    font-weight: $font-semi-bold;
  }

  &__recommendations {
    color: $black-100;
    border-radius: 20px;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
  }

  .ant-table-row.selected {
    .ant-table-cell {
      background: $blue-50 !important;
    }
  }

  &__findings-table {
    margin-bottom: 15px;
  }

  .assessment-finalized-add-finding__button__wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .ui-collapse {
    .ant-collapse-item {
      border: none;
      margin-bottom: 20px;

      &-disabled {
        .ant-collapse-header {
          color: $black-100;
        }
      }

      .ant-collapse-header {
        align-items: center;

        &[aria-expanded='false'] {
          border-bottom: 1px solid $gray-200;
        }

        .ant-collapse-header-text {
          display: flex;
          align-items: center;
          color: $black-100;

          .assessment-finalized-findings__header-title {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0;
            color: $black-100;

            img {
              margin-right: 8px;
            }
          }
        }
      }

      .ant-collapse-content-box {
        .ui-select {
          margin-bottom: 20px;

          .ant-select {
            width: 100%;

            .ant-select-selector:before {
              font-size: $font-13;
              color: $black-100;
              font-weight: $font-medium;
            }
          }
        }
      }
    }
  }
}
