@import '/src/ui/index.scss';

body .organizations-table {
  &__actions {
    transition: $regular-transition;
  }

  &__action-icons {
    font-size: 23px;
    padding: 0 4px;
    color: $gray-500;
    opacity: 0;
  }

  .ant-table-row {
    cursor: pointer;
    &:hover {
      .ant-btn.ant-btn-link.organizations-table__action-icons {
        opacity: 1;
      }
    }
  }
}
