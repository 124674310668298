@import '/src/ui/index.scss';

$input-small-size: 25px;

.category-tab-label {
  display: flex;
  justify-content: center;

  &__edit-icon {
    display: none;
    margin-left: px2rem(18.5);
  }

  &:hover {
    .category-tab-label__edit-icon {
      display: block;
    }
  }

  &__editing {
    display: flex;
    height: $input-small-size;
    align-items: center;

    & svg {
      margin-left: px2rem(7);
      box-sizing: content-box;
      padding: 5px;
      border: 1px solid transparent;

      &:hover {
        background: $gray-60;
        border-radius: 50%;
      }
    }

    & .ui-input {
      height: $input-small-size;
      margin-bottom: px2rem(12);

      & .ui-input__wrapper {
        height: $input-small-size;

        & input {
          height: $input-small-size;
          padding: px2rem(2) px2rem(16);
          font-size: px2rem(13);
        }
      }
    }
  }
}
