@import '/src/ui/index.scss';

$height: 59px;
$fontSizeValue: calc(1rem - 4px);

footer {
  width: 100%;
  height: $height;
  padding: 10px 16px;
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px -10px 24px rgba(0, 0, 0, 0.02), 0px -2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);

  & .footer__button {
    font-size: $fontSizeValue;
    min-width: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 1rem;
    line-height: $fontSizeValue;

    & *:first-child:not(span) {
      margin-right: 0.4rem;
    }
    & *:last-child:not(span) {
      margin-left: 0.4rem;
    }
  }
}
