@import '/src/ui/index.scss';

body .editor-app-questions {
  position: relative;
  display: flex;
  align-items: center;
  background: $white;
  border-radius: 7px;
  border: 1px solid $purple-200;
  border-left: 4px solid $purple-200;
  margin: 0 0 15px 0;
  transition: $regular-transition;
  padding: 8px;

  &__apps-icons {
    margin-right: 10px;
    display: flex;
    gap: 5px;
  }

  &__apps-icon {
    border: 1px solid $purple-300;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    padding: 5px;
  }

  &__apps {
    text-transform: uppercase;
    font-size: $font-11;
    font-weight: 600;
  }

  &__controls-wrapper {
    display: flex;
    gap: 5px;
    font-size: $font-11;
    font-weight: 600;
    color: $gray-400;
  }

  &__controls-label {
    text-transform: uppercase;
  }

  &__control-popover-suffix {
    color: $gray-400;
    display: inline-block;
    margin-right: 5px;
  }

  &__control {
    cursor: pointer;

    &:hover {
      color: $blue-500;
    }
  }

  &__remove-button {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;

    position: absolute;
    right: 5px;
    top: 11px;

    &:hover,
    &:focus,
    &:active {
      color: $blue-500;
    }
  }
}

body .editor-questions__question-box__collapse {
  margin-bottom: 15px;

  &.ant-collapse {
    .ant-collapse-item {
      border: none;

      .ant-collapse-header {
        padding: 0;
        display: flex;
        align-items: center;

        .ant-collapse-expand-icon {
          > span {
            margin: 0 8px 0 20px;
          }
        }

        &-text {
          p {
            color: $black-100;
            font-weight: $font-semi-bold;
            font-size: $font-11;
            margin-bottom: 0;
          }
        }
      }
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 15px 0 0 0;
      }
    }
  }
}
