@import '/src/ui/index.scss';

.ui-completed-checkbox {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    all: unset;

    &::before {
      font-family: 'icomoon';
      content: '\e923';
      color: $blue-500;
      font-size: 22px;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }
  }

  &__label {
    transition: background 0.2s, color 0.2s, width 0.2s;
    font-size: 11px;
    font-weight: 500;
    color: $blue-500;
    border: 1px solid $blue-500;
    padding: 5px 8px;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &--checked {
    .ui-completed-checkbox__label {
      background: $blue-500;
      color: $white;
    }

    input[type='checkbox']::before {
      color: $white;
    }
  }

  &--disabled {
    opacity: 0.5;
  }
}
