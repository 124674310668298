@import '/src/ui/index.scss';

.ui-checkbox-group {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__children {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    & .ui-checkbox {
      margin-right: px2rem(8);
      margin-top: px2rem(8);
    }
  }

  &__error {
    color: $error;
    font-size: 13px;
    font-weight: $font-semi-bold;
    margin-top: px2rem(7);
  }
}
