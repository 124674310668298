@import '../../ui';

@mixin container() {
  font-weight: $font-normal;
  line-height: 175%;
  padding: 15px;
  position: relative;
  white-space: break-spaces;
  border: 1px solid $gray-200;
  border-radius: 4px;
  display: flex;
}

@mixin icon() {
  font-size: 20px;
  margin-right: 8px;
}

.audit-control-content {
  max-height: 300px;
  overflow: scroll;

  h5 {
    font-size: $font-11;
    font-weight: $font-semi-bold;
    color: $gray-400;
    text-transform: uppercase;
    font-family: 'Montserrat';
  }

  &__overview {
    @include container();

    p {
      margin: 0;
    }

    .ui-icon.icon-findings-page {
      @include icon();
    }
  }

  &__findings {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    @include container();

    .ui-icon.icon-flag {
      @include icon();
    }
  }

  &__finding {
    display: flex;
    flex-direction: column;
    background: $gray-50;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;

    &-impact,
    &-probability {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      font-size: $font-13;
    }
  }

  &__finding-footer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 7px;
    border-top: 1px solid $gray-200;
    padding-top: 10px;
    height: 35px;
    position: relative;
  }

  &__finding-status-bar {
    display: flex;
    justify-content: space-around;
    div {
      margin-right: 10px;
    }
  }

  &__findings-title {
    display: flex;
  }

  &__add-finding {
    text-transform: uppercase;
    color: $blue-500;
  }

  &__finding-clickable {
    cursor: pointer;
  }

  &__added-icon-finding {
    &.audit-control-content__added-icon-finding.ui-icon.icon-check {
      color: $blue-500;
      font-size: $font-30 !important;
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }

  &__finding-impact {
    @include impactStatus();
  }
  &__finding-probability {
    @include probabilityStatus();
  }
}
