@import '/src/ui/index.scss';

.hr-with-children {
  display: block;
  text-align: center;
  margin: 30px 0;
  font-size: $font-11;
  font-weight: $font-semi-bold;
  color: $gray-400;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 45%;
    background-color: $gray-200;
    height: 1px;
    top: 8px;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}
