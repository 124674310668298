@import '/src/ui/index.scss';

body .ui-collapse {
  border: none;
  background: none;

  .ant-collapse-item {
    background: none;
    border: 1px solid $gray-200;
  }

  .ant-collapse-content {
    background: none;
    border-top: none;
  }

  &--separated-panels {
    .ant-collapse-item {
      margin-bottom: 10px;
    }
  }

  &--rounded {
    .ant-collapse-item {
      border-radius: 5px !important;
    }
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0px;
  }
}

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
  cursor: default !important;
}
