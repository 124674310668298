@import '/src/ui/index.scss';

body .ui-icon {
  &--pink {
    color: $pink-500;
  }
  &--green {
    color: $Turquoise-400;
  }
  &--purple {
    color: $purple-400;
  }
  &--black {
    color: $black-100;
  }
  &--red {
    color: $red-500;
  }
}
