@import '../../../ui';

body .recent-page-vendors-average-score-popover {
  &__content {
    & .ant-table-thead {
      display: none;
    }
    padding: 2px 0;
  }

  &__content-title {
    color: $gray-400;
    font-size: 11px;
    font-weight: $font-semi-bold;
    margin-bottom: 12px;
  }

  &__apps-content-title {
    color: $gray-400;
    font-size: 11px;
    margin-top: 20px;
    font-weight: $font-semi-bold;
    margin-bottom: 12px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 10px;
  }

  &__header-sub-title {
    font-weight: $font-normal;
  }

  &__header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__header-title {
    font-weight: 600;
    font-size: 16px;
  }

  &__header-score {
    width: 115px;
    border: 1px solid $gray-250;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px 0;

    &-title {
      text-align: center;
    }
  }

  &__header-score-percentage {
    text-align: center;
    font-size: $font-24;
    font-weight: 600;
    line-height: 1.1em;
  }

  &__header-score-value {
    text-transform: uppercase;
    text-align: center;
    font-size: $font-11;
    font-weight: 600;
  }

  &__header-score--Low {
    background: $pink-100;
    border-color: $red-600;
  }

  &__header-score--Medium {
    background: $orange-100;
    border-color: $orange-300;
  }

  &__header-score--High {
    background: $green-50;
    border-color: $green-400;
  }

  &__assessments-score-table {
    box-shadow: $box-shadow-4;

    .ant-table-tbody tr {
      cursor: initial;
    }
  }

  &__app-findings {
    display: flex;
    align-items: center;
  }

  &__app-findings-icon {
    color: $red-500;
    font-size: 20px;
    margin-left: 4px;
  }

  &__empty-state {
    display: grid;
    background: $gray-50;
    padding: 0 16px;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    box-shadow: 0px 2px 4px 0px #0000001f;
    grid-template-columns: 1fr 1fr;

    &-label {
      font-size: $font-14;
      font-weight: $font-normal;
      color: $text-color;
    }

    &-value {
      padding-left: 40px;
      font-size: $font-14;
      font-weight: $font-normal;
      color: $gray-400;
    }
  }
}

.recent-page-vendors-average-score-popover {
  &:hover {
    font-weight: $font-semi-bold;
  }
}
