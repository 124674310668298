@import '/src/ui/index.scss';

.ant-notification-notice {
  &.file-upload-indicator {
    padding: 16px 20px;
    width: 480px;
    height: 100px;
    display: flex;
    align-items: center;

    .ant-notification-notice-message {
      padding: 0;
      margin: 0;
    }

    .file-upload-indicator__inner {
      display: flex;
      flex-direction: row;
      position: relative;

      .file-upload-indicator__icon {
        position: relative;
        width: 64px;
        display: flex;
        align-items: flex-start;
        margin-right: 25px;

        .loader {
          .inner-loader {
            height: 64px;
            width: 64px;
          }
        }
      }
      .file-upload-indicator__message {
        display: flex;
        flex-direction: column;

        h2 {
          font-size: $font-14;
          font-weight: $font-semi-bold;
          color: $text-color;
          margin-bottom: 5px;
          line-height: 21px;
        }
        p {
          font-size: $font-12;
          font-weight: $font-normal;
          color: $text-color;
          line-height: 21px;
          margin-bottom: 0;

          a {
            text-decoration: underline;
            text-transform: uppercase;
            color: $text-color;

            &:hover {
              color: $blue-500;
            }
          }
        }
      }
    }
  }
}
