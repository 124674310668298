@import '/src/ui/index.scss';

.remove-confirmation-modal {
  width: 100% !important;
  max-width: 640px !important;

  .ant-modal-content {
    padding: 30px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 16px 24px 0 rgba(0, 0, 0, 0.06);
    border-radius: 5px;
  }
  .ant-modal-body{
    padding: 0!important;
  }
  .ant-modal-footer{
    margin-top: 24px;
    padding: 0 !important;
  }


  &__header {
    display: flex;
    font-size: 20px;
    font-weight: $font-semi-bold;
    color: $text-color;
    gap: 8px;
    align-items: center;
    & .ui-icon{
      font-size: 24px;
      color: $gray-600
    }
    margin-bottom: 24px;
  }

  &__text{
    font-size: $font-15;
    color: $text-color;
  }

  &__footer{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
