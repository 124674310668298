@import '/src/ui/index';

.onboarding-2fa-method {
  &__footer {
    margin-bottom: 20px;
  }

  &__alert {
    border: 1px solid $red-200;
    background: $red-50;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 10px 14px;
    margin-bottom: 20px;
  }

  &__alert-icon {
    margin-right: 10px;
  }

  .card-with-logo-footer__right-button {
    width: auto !important;
  }
}
