@import '../../ui';

body .ui-tag {
  border-radius: 15px !important;
  border-color: $blue-450;
  position: relative;

  &--with-icon {
    display: inline-flex;
    align-items: center;
    max-width: 100%;

    span {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--blue {
    color: $blue-500 !important;
    background-color: $blue-50;
  }

  &--text-ellipsis {
    text-overflow: ellipsis;
    max-width: 240px;
    overflow: hidden;
  }

  &--small {
    border-color: $blue-300 !important;
    font-size: $font-9 !important;
    line-height: $font-11;
    padding: 2px 8px !important;
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
