@import './theme';
@import 'colors';

body,
html {
  padding: 0;
  margin: 0;

  font-family: Montserrat, sans-serif !important;
  font-style: normal;
  font-weight: normal;
}

h1.ant-typography {
  font-size: $font-28 !important;
}

h2.ant-typography {
  font-size: $font-24 !important;
}

h3.ant-typography {
  font-size: $font-20 !important;
  font-weight: 400;
}

h4.ant-typography {
  font-size: $font-16 !important;
  letter-spacing: 0.3px;
  font-weight: 400 !important;
}

.ant-select-dropdown {
  & > div {
    background: $white !important;
  }
}

pre {
  margin-bottom: 0;
  font-family: Montserrat, sans-serif !important;
}
