@import '/src/ui/index.scss';

.category-tab {
  position: relative;

  &__header {
    margin-bottom: px2rem(33) !important;
  }
  &__delete-tab {
    position: absolute;
    right: 0;
    top: 150px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: px2rem(11);
    font-weight: $font-semi-bold;
    z-index: 1;

    & svg {
      margin-right: px2rem(7.9);
    }
  }

  &__confirm-modal {
    .ant-modal-body {
      text-align: center;
      font-size: $font-20;
      font-weight: 600;
    }
  }
}
