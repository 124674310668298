@import '/src/ui/index.scss';

.notifications-tab {
  &__input {
    width: 700px !important;
  }
  &__select {
    width: 700px;
    .ant-select {
      width: 450px;
    }
  }
  &__checkbox {
    float: left;
    padding: 6px;
  }
  &__action-buttons {
    display: flex;
    float: right;
  }
  &__button {
    margin-right: 10px;
  }
  &__reset-default-popup {
    text-align: center;
  }

  &__disable-notification {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
