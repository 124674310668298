@import '/src/ui/index.scss';

body .editor-findings-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 4px 0;

  &--active {
    background: $gray-50;
  }

  &--over {
    background: $blue-200;
  }
}
