@import '/src/ui/index.scss';

body .assessments-page-list-actions {
  &__action {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;

    .ant-btn {
      font-size: 23px;
      padding: 0 3px;
      color: $gray-500;
    }

    &.ant-tooltip-disabled-compatible-wrapper {
      display: inline-block;
      height: 32px;
      padding: 0;

      .ant-btn {
        color: $gray-300;
      }
    }
  }
}
