@import '/src/ui/index.scss';

body .assessment-finalized-finding-statuses {
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  flex-wrap: wrap;

  &--preview {
    gap: 15px;
  }

  &__impact,
  &__probability {
    width: auto;
  }

  &__impact--1,
  &__probability--1 {
    color: $green-400;
  }

  &__impact--2,
  &__probability--2 {
    color: $orange-300;
  }

  &__impact--3,
  &__probability--3 {
    color: $orange-300;
  }

  &__impact--4,
  &__probability--4 {
    color: $red-500;
  }

  &__impact--5,
  &__probability--5 {
    color: $black-500;
  }

  &__impact-wrapper,
  &__probability-wrapper {
    display: flex;
    align-items: center;
  }

  &__impact-label,
  &__probability-label {
    font-size: 11px;
    color: $black-100;
    font-weight: 600;
    margin-right: 5px;
  }
}
