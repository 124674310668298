@import '/src/ui/index.scss';

body .ui-modal {
  .ant-modal-close-x {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .ant-modal-header {
    border: 0;
    padding: 24px 24px 0;
  }

  .ant-modal-title {
    font-size: $font-20;
    font-weight: 600;
    display: flex;
    align-items: center;

    > span {
      display: flex;
      align-items: center;
    }

    .ui-icon,
    svg {
      margin-right: 10px;
      font-size: 24px;
    }
  }

  .ant-modal-footer {
    border: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__footer-ok-button,
  &__footer-cancel-button {
    margin-bottom: 15px;
  }
}
