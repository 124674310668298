@import '/src/ui/index.scss';

body .editor-answer-creator {
  border-radius: 7px;
  border: 1px solid $blue-500;
  border-left: 4px solid $blue-500;
  margin: 0 0 15px 50px;
  position: relative;

  &__main {
    display: flex;
    padding: 18px;
  }

  &__key {
    width: 50px;
  }

  &__wrapper {
    width: 100%;
  }

  &__title {
    font-size: 15px;
    color: $black-100;
    font-weight: 500;
    margin-bottom: 20px;

    pre {
      overflow: hidden;
      white-space: pre-wrap;
    }
  }

  &__header {
    font-size: 15px;
    color: $black-100;
    font-weight: 500;
    padding: 18px 18px 0;
    display: flex;
    align-items: center;

    .ui-icon {
      margin-right: 4px;
      font-size: 18px;
    }
  }

  &__action-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $gray-200;
    padding: 4px 4px;
  }

  &__footer-cancel-button,
  &__footer-ok-button {
    min-width: 120px;
  }
}
