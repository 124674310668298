@import '/src/ui/index.scss';

.my-files-uploaded-item-list__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 613px;
  width: 100%;
  margin: 0 auto;

  &-info {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: $text-color;
    line-height: 21px;
    text-align: center;

    &-max-size {
      margin-top: 25px;
      color: $gray-400;
    }
  }

  &-button {
    & button {
      font-size: 11px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;
      }
    }
  }
}
