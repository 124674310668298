@import '/src/ui/index.scss';

body .assessment {
  display: flex;
  height: 100%;

  &__loader {
    z-index: 9;
  }

  &__left-panel {
    width: 30%;
    padding: 24px 27px 15px;
    height: calc(100vh - $headerHeight);
    overflow: hidden;
    overflow-y: auto;
  }

  &__right-panel {
    background: $white;
    width: 70%;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    height: 100%;
    position: relative;
  }

  &__right-panel-inner {
    height: calc(100vh - $headerHeight - 55px);
    overflow: auto;
    padding: 0 60px 15px 60px;
  }

  &__page_header {
    margin-bottom: 5px;
  }

  &__details {
    display: flex;
    align-items: center;
    margin: 0 0 40px 45px;
  }

  &__response-automation-modal {
    .ant-modal-body {
      text-align: center;
    }
  }

  &__response-automation-modal-image {
    margin-bottom: 15px;
  }

  &__response-automation-modal-title {
    font-size: $font-20;
    margin: 0;
  }
}
