@import '/src/ui/index.scss';

$phone-width: 209px;
$phone-height: 407px;
$phone-radius: 37px;

.not-found-supported-on-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &__container {
    position: relative;
  }

  &__details {
    border: 4px solid $purple-400;
    display: flex;
    width: $phone-width;
    height: $phone-height;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $phone-radius;
    z-index: 1;
    position: relative;
  }

  &__not-supported-icon {
    position: absolute;
    right: -67px;
    bottom: -30px;
    z-index: 3;
  }

  &__background {
    top: -13px;
    right: -13px;
    position: absolute;
    width: $phone-width;
    height: $phone-height;
    background: $blue-50;
    border-radius: $phone-radius;
    z-index: 0;
  }

  &__camera-dot {
    background: $purple-400;
    width: 7.56px;
    height: 7.56px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
  }

  &__title {
    margin-top: 11px;
    max-width: 133px;
    text-align: center;
    font-size: $font-18;
    line-height: px2rem(22);
    margin-bottom: 13px;
  }

  &__yet {
    font-size: $font-28;
    font-weight: $font-semi-bold;
    text-transform: uppercase;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 86px;
  }
}
