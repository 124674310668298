@import '/src/ui/index.scss';

.ui-radio-group {
  display: flex;
  & .ui-radio:not(:last-of-type) {
    margin-right: px2rem(24);
  }
  &__error {
    & input[type='radio'] {
      border-color: $red-500;
    }
  }
  &--horizontal {
    flex-direction: column;
    & .ui-radio:not(:last-of-type) {
      margin-bottom: px2rem(24);
    }
  }
}
