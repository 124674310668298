@import '/src/ui/index.scss';

@mixin file-upload-background() {
  border: 1px dashed $gray-400;
  border-radius: 4px;
  padding: 4px;
  background: $gray-50;
}

.upload-logo {
  &__area {
    @include file-upload-background;
    display: flex;
    justify-content: center;
    padding: 15px;
    height: 91px;

    h4 {
      margin: 0px;
    }
  }

  &__logo-icon {
    font-size: $font-20;
    margin-right: 10px;
  }
  &__upload-logo-text {
    display: flex;
    margin-top: 8.5px;
    align-items: center;
    .ui-divider--vertical {
      border-right: 1px solid #46a6d7;
    }
  }
  &__upload-logo-browse-button,
  &__logo-remove-button {
    line-height: 1.4;
    margin: 0px 2px;
    .ui-button__name {
      font-size: $font-11;
      text-transform: uppercase;
      font-weight: $font-normal;
    }
  }

  &__logo-empty-state {
    display: flex;
    align-items: center;
  }
}
