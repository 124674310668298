@import '/src/ui/index.scss';

body .editor-questions {
  padding: 0 50px 0 0;
  overflow: scroll;
  position: relative;

  &__loader {
    background: #ffffff59;
  }

  &__question-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__question-box {
    width: 100%;
    margin-left: 50px;
  }

  &__drag-icon-wrapper {
    position: absolute;
    left: 20px;
    padding-bottom: 20px;
    &:hover {
      cursor: grab;
    }
  }

  &__drag-icon {
    color: $gray-300;
  }

  &__question {
    .editor-question {
      background: $white;
      border-radius: 7px;
      outline: 1px solid $gray-250;
      border-left: 4px solid transparent;
      margin: 1px 0 15px;
      transition: $regular-transition;

      &:hover {
        border-color: $gray-250;
        border-left: 4px solid $gray-250;
      }
    }

    &--active {
      .editor-active-question__container,
      .editor-edit-answers,
      .editor-findings {
        background: $white;
        border-radius: 7px;
        border: 1px solid $blue-500;
        margin: 0 0 15px;
        transition: $regular-transition;
        border-left: 4px solid $blue-500;
        padding-left: 0;
        box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.15);

        &:hover {
          border-color: $blue-500;
        }
      }
    }

    &--is-dragging {
      .editor-question {
        background: $blue-200;
      }
    }
  }
}
