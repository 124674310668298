@import '../../../ui';

body .recent-page-vendors-vendor-risk-popover {
  &__content-title {
    color: $gray-400;
    font-size: 11px;
  }

  &__inherent-risk-table {
    margin-bottom: 20px;

    .ant-table-tbody tr {
      cursor: initial;
    }
  }

  &__assessments-score-table {
    .ant-table-tbody tr {
      cursor: initial;
    }
  }

  &__empty-state {
    display: grid;
    background: $gray-50;
    padding: 0 16px;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    box-shadow: 0px 2px 4px 0px #0000001f;
    margin-top: 10px;
    grid-template-columns: 1fr 1fr;

    &-label {
      font-size: $font-14;
      font-weight: $font-normal;
      color: $text-color;
    }

    &-value {
      padding-left: 40px;
      font-size: $font-14;
      font-weight: $font-normal;
      color: $gray-400;
    }
  }
}
