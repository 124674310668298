@import '../../ui';

.image-editor {
  text-align: center;
  margin-top: 33px;

  & canvas {
    background-color: $gray-100;
    opacity: 1;
    background-image: repeating-linear-gradient(
        45deg,
        $white,
        25%,
        transparent 25%,
        transparent 75%,
        $white 75%,
        $white
      ),
      repeating-linear-gradient(45deg, $white 25%, #e5e5f7 25%, #e5e5f7 75%, $white 75%, $white);
    background-position: 0 0, 10px 10px;
    background-size: 20px 20px;
  }

  & .ant-slider-horizontal {
    width: 200px;
    height: 9px;
    &,
    &:hover {
      .ant-slider-rail {
        background: $blue-100;
      }
      .ant-slider-track {
        background: $blue-500;
      }
      .ant-slider-handle {
        border-color: $blue-500;
      }
    }
  }

  &__slider {
    display: flex;
    justify-content: space-between;
    width: 70%;
    align-items: center;
    margin: 32px auto auto;
  }

  &__scale-button {
    &:hover {
      text-decoration: none !important;
    }
    & .ui-button--black-link {
      &:hover > .ui-button__name {
        text-decoration: none !important;
      }
    }
    & .ui-button__name {
      font-size: $font-30;
      font-weight: $font-extra-light !important;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
