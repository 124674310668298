@import '/src/ui/index.scss';
$collapsed-width: 60px;
$not-collapsed-width: 165px;

.new-vendor-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 901;
  transition: width $regular-transition, left $regular-transition;

}
