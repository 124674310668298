@import '/src/ui/index.scss';

.category-tab {
  position: relative;

  &__header {
    margin-bottom: px2rem(33) !important;
  }
  &__delete-tab {
    position: absolute;
    right: 0;
    top: 95px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: px2rem(11);
    font-weight: $font-semi-bold;

    & svg {
      margin-right: px2rem(7.9);
    }
  }

  &__tab-pane {
    display: flex;
  }

  &__confirm-modal {
    .ant-modal-body {
      text-align: center;
      font-size: $font-20;
      font-weight: 600;
    }
  }

  &__inherent-risk {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: $font-bold;
    margin: 44px 0;
    & .score-pill {
      margin-left: 8px;
    }
  }

  &__inherent-risk-label {
    display: inline-block;
    margin-right: 5px;
  }
}
