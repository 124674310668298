@import '/src/ui/index.scss';

body .base-layout-profile-menu {
  display: flex;
  margin-left: 15px;
  align-items: center;

  &__menu {
    .ant-dropdown-menu-item-group-list {
      margin: 0;
    }
  }

  &:before {
    display: block;
    content: '';
    width: 1px;
    border-right: 1px solid $gray-200;
    margin-right: 15px;
    height: 25px;
  }

  &__button {
    position: relative;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus,
    &.ant-dropdown-open {
      opacity: 0.8;
    }
  }

  &__menu-item-ico {
    font-size: 18px !important;
  }

  &__user {
    display: flex;
    align-items: center;
    padding: 15px 0;
  }

  &__user-badge {
    font-size: $font-16;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 30px;
    border: 1px solid $gray-250;
    text-align: center;
    color: $gray-400;
  }

  &__user-info {
    padding-left: 10px;
  }

  &__user-name {
    font-size: $font-13;
    line-height: 1.2;
    color: $text-color;
  }

  &__user-email {
    color: $gray-400;
    font-size: $font-13;
    line-height: 1.2;
  }
}

body .base-layout-organizations-submenu {
  &__menu {
    .ant-dropdown-menu-item-group-title {
      font-size: 11px;
      font-weight: 600;
    }

    &-item {
      align-items: center;
      display: flex;
      margin: 0px !important;
      font-size: $font-14;
      font-weight: $font-normal;
      padding: 5px 12px;
      line-height: 22px;
      height: auto;

      &:hover {
        background: $gray-75;
      }

      &--selected {
        font-weight: 700 !important;
      }

      &.ant-dropdown-menu-item {
        &.ant-dropdown-menu-item-active {
          .base-layout-organizations-submenu__menu-edit-button {
            opacity: 1 !important;

            &:hover {
              color: $blue-700;
            }
          }
        }
      }
    }

    &-label-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-edit-button {
      font-size: 20px;
      padding: 0 3px;
      color: $gray-500;
      opacity: 0;
    }
  }
}
