@import '../../ui';

.upstream-ai-button {
  position: absolute;
  display: none;
  padding: 0px 7px !important;

  &--explain {
    right: -85px;
    top: 0px;
    background: $explain-default-bg;
    border: 1px solid $explain-default-border;
    color: $pink-500;
    &:hover {
      background: $pink-300 !important;
      border: 1px solid $pink-500;
    }
  }
  &--example {
    right: 10px;
    left: auto;
    bottom: 15px;
    background: $example-default-bg;
    border: 1px solid $example-default-border;
    color: $example-icon;
    z-index: 1;
    &:hover {
      background: $example-hover-bg !important;
      border: 1px solid $example-icon;
    }
  }

  &--improve,
  &--audit {
    right: 10px;
    left: auto;
    bottom: 15px;
    background: $improve-default-bg;
    border: 1px solid $improve-default-border;
    color: $improve-icon;
    z-index: 1;
    &:hover {
      background: $improve-hover-bg !important;
      border: 1px solid $improve-icon;
    }
  }

  & .ui-button__name {
    color: $black-100 !important;
  }

  &--open {
    display: flex !important;
  }

  &--hide-button {
    display: none;
  }
}
