@import '/src/ui/index.scss';

body .continuous-monitoring-tab {
  border-bottom: 1px solid $gray-200;
  padding-bottom: 40px;
  width: 1055px;
  margin: 0 auto 0 auto !important;

  &__add-domain-wrapper {
    text-align: center;
  }

  &-risk-recon {
    &__full-scan-modal {
      &__credits-left {
        padding: 4px 12px;
        align-items: center;
        background-color: $gray-50;
        border-radius: 4px;
        font-size: $font-14;
        font-weight: $font-normal;
        width: 100%;
        margin-top: 24px;
      }
    }
  }

  &__app-icons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 155px;
    margin: 0 auto 30px;
  }

  &__app-icons-icon {
    width: 45px;
    height: 45px;
    border: 1px solid $gray-100;
    padding: 1px;
    border-radius: 4px;
    opacity: 0.6;
  }

  &__add-domain-title {
    margin-bottom: 10px;
  }

  &__add-domain-form.ui-form {
    display: flex;
    width: 325px;
    column-gap: unset;
    margin: 0 auto;
  }

  &__send-button {
    padding: 0 30px;
    margin-left: 5px;
  }

  &__apps-title {
    font-weight: 600;
    font-size: $font-16;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  &__apps-title-icon {
    font-size: 18px;
    margin-right: 10px;
    margin-top: -3px;
  }

  &__apps-list {
    margin: 0;
    padding: 0;
    list-style: none outside none;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__app {
    width: 210px;
    height: 196px;
    border: 1px solid $gray-200;
    border-radius: 10px;
    box-shadow: $box-shadow-3;
  }

  &__app--azure,
  &__app--ibm,
  &__app--qualys {
    .continuous-monitoring-tab__app-property {
      width: 80px;
    }
  }

  &__app-header {
    background: $gray-50;
    border-bottom: 1px solid $gray-100;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 12px;
    border-radius: 10px 10px 0 0;
  }

  &__app-title {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: $font-13;
    font-weight: 600;
    color: $black-100;
    line-height: 1.2em;
    width: 66%;
  }

  &__app-icon {
    width: 30px;
    padding: 2px;
    margin-right: 5px;
  }

  &__app-status {
    background: $gray-400;
    margin-left: auto;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: $white;
    padding: 4px 8px;
    border-radius: 2px 0 0 2px;
    box-shadow: 0px 1.63px 3.259px 0px rgba(0, 0, 0, 0.08);
    line-height: 0.8rem;
  }

  &__app-status--active {
    background: $green-400;
  }

  &__app-body {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 12px;
  }

  &__app-property {
    background: $gray-50;
    border: 1px solid $gray-200;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;

    &__status {
      color: $white;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      min-height: 40px;
      font-family: Montserrat, serif;
      font-size: 16px;
      font-style: normal;
      font-weight: $font-semi-bold;
      height: 40px;
      line-height: 40px;
      width: 70px;
      justify-content: center;
      overflow: hidden;

      &--border {
        border: 1px solid #e0dede;
        border-radius: 4px;
      }

      &--A {
        background: $green-500;
      }

      &--B {
        background: $dark-blue-300;
      }

      &--C {
        background: $orange-200;
        color: $black-100;
      }

      &--D {
        background: $orange-400;
      }

      &--F {
        background: $red-500;
      }

      span {
        display: inline-block;
        border-right: 1px solid $gray-200;
        padding-right: 7px;
        margin-right: 7px;
      }
    }
  }


  &__app-property-label {
    font-size: $font-12;
    color: $gray-400;
    display: block;
    margin-bottom: 3px;
    text-transform: capitalize;
  }

  &__app-findings-icon {
    color: $red-500;
    font-size: 20px;
    margin-left: 4px;
  }

  &__app-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      cursor: pointer;
    }
  }

  &__app-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px 30px;
    font-size: $font-13;
  }

  &__app-placeholder-icon {
    display: inline-block;
    margin-bottom: 5px;
  }

  &__app-no-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px 30px;
    font-size: $font-13;
  }

  &__app-no-result-icon {
    margin: 1px 0 9px;
    font-size: 27px;
    color: $gray-250;
  }

  &__app-button {
    width: 150px;
  }

  &__app-button--link {
    margin-top: 3px;
  }
}
