@import '/src/ui/index.scss';

body .assessment-finalized-categories {
  &--is-questionnaire-tab {
    position: sticky;
    top: 95px;
  }

  &__header-title {
    margin-bottom: 10px;
  }

  &__header-title-text {
    font-weight: 600;
  }

  .ant-table-row.selected {
    .ant-table-cell {
      background: $blue-50 !important;
    }
  }
}
