@import '/src/ui/index.scss';

.forgot-password-form {
  display: grid;
  grid-template-rows: repeat(2, max-content) 1fr;
  height: 100%;

  &__footer {
    align-self: end;
    display: flex;
    align-items: baseline;
    position: absolute;
    bottom: 50px;
  }
}
