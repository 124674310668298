@import './colors';
@import './mediaQueries';

@mixin blueButton() {
  color: $blue-500;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  padding: 4px 4px;

  &:hover,
  &:focus {
    color: $blue-700;
  }

  .ui-icon {
    font-size: 20px;
    margin-right: 5px;
  }
}

@mixin file-upload-background() {
  border: 1px dashed $gray-400;
  border-radius: 4px;
  padding: 4px;
  background: $gray-50;
}

@mixin impactStatus() {
  &--trivial {
    color: $green-400;
  }
  &--minor {
    color: $orange-300;
  }
  &--moderate {
    color: $orange-300;
  }
  &--major {
    color: $red-500;
  }
  &--extreme {
    color: $black-500;
  }
}

@mixin probabilityStatus() {
  &--rare {
    color: $green-400;
  }
  &--unlikely {
    color: $orange-300;
  }
  &--moderate {
    color: $orange-300;
  }
  &--likely {
    color: $red-500;
  }
  &--veryLikely {
    color: $black-500;
  }
}


@mixin mobile {
  @media (max-width: $mobileScreen) {
    @content;
  }
}

@mixin desktopSmall {
  @media (max-width: $desktopScreen) {
    @content;
  }
}

@mixin desktopMedium {
  @media (max-width: $desktopMediumScreen ) {
    @content;
  }
}

@mixin desktopLarge {
  @media (min-width: $desktopMediumScreen) {
    @content;
  }
}
