@import '/src/ui/index.scss';

.language-select {
  .ant-select {
    width: 100%;
  }

  .ant-select-selection-item {
    margin-top: 8px;
    margin-left: 6px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 9px !important;
    color: #868686b5;
  }

  .ant-select-focused .ant-select-selector {
    border: $purple-200 2px solid !important;
  }

  .ant-select-selector {
    background: $gray-50 !important;
    border: 1px solid $gray-200 !important;
    border-radius: $border-radius-xs !important;
    box-sizing: border-box !important;
    height: 48px !important;
    margin: 5px 0 !important;
    outline: none !important;
    transition: $regular-transition !important;
    position: relative !important;
  }

  .language-select {
    &__afterIcon {
      align-content: center;
      align-items: center;
      color: #828282;
      display: flex;
      height: 35px;
      justify-content: center;
      position: relative;
      transition: 200ms ease-out;
      width: 35px;
      float: right;
      z-index: 99;
      margin-top: -45px;
    }
  }
}
