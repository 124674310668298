@import '/src/ui/index';

body .recent-page-vendors-business-owner {
  &__user-selector {
    inset: 0;
    margin: auto;
    height: 32px;
    z-index: 2;
  }

  .ant-select {
    min-width: 140px;
    width: inherit !important;
    position: absolute;
    z-index: 2;
  }

  &__ui-select-dropdown {
    & .ant-select-item-option-content {
      white-space: normal;
      word-break: break-word;
      display: inline-flex;
    }
  }

  &__add-user {
    opacity: 0;
    font-size: 23px;
    padding: 0;
    color: $gray-500;
    margin: 0;
    transition: unset;

    &:hover,
    &:active {
      color: $blue-500;
    }

    &:active {
      color: $blue-700;
    }
  }

  &__empty-state {
    font-size: 24px;
    padding: 0;
    color: $gray-500;
    margin: 0;
    transition: unset;

    &:hover,
    &:active {
      color: $blue-500;
    }

    &:active {
      color: $blue-700;
    }
  }

  &__bo-list {
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
      .recent-page-vendors-business-owner__add-user {
        opacity: 1;
      }
    }
  }

  &__bo-list-user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 1px solid $gray-400;
    color: $gray-400;
    text-transform: uppercase;

    &:hover {
      color: $blue-500;
      border-color: $blue-500;
      cursor: pointer;
    }
  }

  &__bo-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 1px solid $gray-400;
    color: $gray-400;
    text-transform: uppercase;
  }

  &__invite-user-button {
    background: $blue-50;
    width: 100%;
    height: 44px;
    justify-content: flex-start;
    padding-left: 15px;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background: $blue-200;
    }
  }
  &__tag {
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: 23px;
    gap: 4px;
    margin-right: 4px;
    padding: 4px 12px;
    color: $text-color;
    border: 1px solid $blue-450;
    background: $blue-50;
    & svg {
      cursor: pointer;
    }
  }
}

.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      ul {
        padding-left: 20px;
        margin-bottom: 0;
      }
    }
  }
}
