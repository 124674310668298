@import '/src/ui/index.scss';

.my-files-list {
  height: calc(100% - 110px);
  padding: 50px 30px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;

  .my-files-list__loading-more {
    position: absolute;
    bottom: 0;
    width: 90%;
    margin: auto;
    background-color: $white;
    text-align: center;
    padding: 5px;
  }
}
