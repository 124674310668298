@import '/src/ui/index.scss';

body .organizations-settings-page {
  padding: 30px;

  .disabled-row {
    color: $gray-250;
    pointer-events: none;
    cursor: not-allowed !important;
  }

  .ant-table-tbody {
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 8%);

    & > tr:nth-child(odd):not(.ant-table-row-selected) > td {
      background: $white;
    }
  }

  &__top-row {
    margin-bottom: px2rem(80);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ui-input {
      flex-direction: initial;
    }
  }

  &__input {
    background: $white;
    &:hover {
      border: 1px solid $gray-200;
    }
  }

  &__button-icon {
    float: left;
    margin-top: 9px;
    margin-right: 6px;
  }

  &__new-org-popup {
    .ant-modal-body {
      padding: 0 20px 5px 20px;
    }
  }

  &__new-org-popup-select {
    width: 130px;
  }

  &__organization-table-label {
    display: flex;
  }
}
