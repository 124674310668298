@import '../../ui';

.upstream-ai-feedback {
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: $font-13;
  width: 310px;
  height: 40px;

  &__left-icon,
  &__right-icon {
    font-size: $font-18;
    cursor: pointer;

    &:hover {
      color: $blue-500;
    }

    &--filled {
      color: $red-500;
      &:hover {
        color: $red-500;
      }
    }
  }

  &__left-icon {
    margin: 0 10px;
    padding-right: 10px;
    border-right: $gray-200 solid 1px;
    &--filled {
      color: $green-400;
      &:hover {
        color: $green-400;
      }
    }
  }

  &--explain {
    background: $explain-feedback;
  }
  &--example {
    background: $example-feedback;
  }
  &--improve,
  &--audit {
    background: $improve-feedback;
  }
}
