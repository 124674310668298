@import '/src/ui/index.scss';

body .editor-findings {
  position: relative;

  &__main {
    display: flex;
    padding: 18px;
    color: $gray-400;
  }

  &__key {
    width: 50px;
  }

  &__wrapper {
    width: 100%;
  }

  &__title {
    font-size: 15px;
    color: $black-100;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__header {
    font-size: 15px;
    color: $black-100;
    font-weight: 500;
    padding: 18px 18px 0;
    display: flex;
    align-items: center;

    .ui-icon {
      margin-right: 4px;
      font-size: 18px;
    }
  }

  &__answer-type {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__answer-type-option {
    display: flex;
  }

  &__answer-type-option-key {
    margin-right: 5px;
  }

  &__answer-type-option-content {
    width: 100%;
  }

  &__add-finding-button {
    padding: 6px 0;
  }

  &__findings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 4px 0;

    &--over {
      background: red;
      border: #4c5fbd;
    }
  }

  &__finding {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__finding-wrapper {
    display: flex;
    align-items: center;
    background: $gray-50;
    border: 1px solid $gray-200;
    border-left: 2px solid $blue-500;
    padding: 15px;
    width: 85%;
    gap: 50px;

    .recent-page-findings-risk {
      width: 170px;
    }

    &--draggable {
      border: 1px dashed $blue-500;
      cursor: move;
    }
  }

  &__findings-description {
    width: 45%;
    color: $black-100;
  }

  &__recommendations {
    background: $green-400;
    color: $white;
    border-radius: 20px;
    width: 19px;
    height: 19px;
    line-height: 19px;
    display: inline-block;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }

  &__action {
    font-size: 23px;
    padding: 0 3px;
    color: $gray-500;
  }

  &__action-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid $gray-200;
    padding: 4px 4px;
  }

  &__action-bar-ok-button {
    min-width: 120px;
  }
}
