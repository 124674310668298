@import '/src/ui/index.scss';

body .ui-approve-answer {
  &__button {
    color: $blue-500;
    border: 1px solid $blue-500;
    border-radius: 4px;
    font-size: 26px;
    display: flex;
    align-items: center;
    padding: 17px 0 17px 7px;
    position: relative;

    &:before {
      content: '';
      border-right: 1px solid $blue-500;
      display: block;
      height: 21px;
      width: 1px;
      position: absolute;
      left: 40px;
      top: 7px;
      opacity: 1;
    }

    &:after {
      background: $white;
      color: $blue-500;
      content: '\e917';
      font-family: 'icomoon';
      padding: 0 2px 0 0;
      margin-left: 8px;
      border-radius: 0 4px 4px 0;
      height: 34px;
      width: 28px;
      border-left: 1px solid transparent;
    }

    &:hover,
    &:focus,
    &:active {
      border: 1px solid $blue-500;
      color: $blue-500;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      border: 1px solid $blue-500;
      color: $blue-500;
      opacity: 0.5;
    }

    &--approved {
      background: $blue-500;
      color: $white;

      &:hover,
      &:focus,
      &:active {
        background: $blue-500;
        color: $white;
      }

      &:before {
        display: none;
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        background: $blue-500;
        color: $white;
        opacity: 0.5;
      }
    }

    &--disapproved {
      background: $red-400;
      color: $white;

      &:after {
        border-left: 1px solid $blue-500;
      }

      &:hover,
      &:focus,
      &:active {
        background: $red-400;
        color: $white;
      }

      &:before {
        display: none;
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        background: $red-400;
        color: $white;
      }
    }
  }

  .ui-icon {
    width: 26px;
  }

  &__ico-approved {
    font-size: 24px;
  }
  &__ico-disapproved {
    font-size: 20px;
  }
}
