@import '/src/ui/index.scss';

body .assessment-finalized-secured-evidence {
  padding: 40px;

  &__general-files-header,
  &__question-files-header {
    font-weight: 600;
    font-size: $font-16;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__upload-button {
    margin-left: 15px;
  }

  &__general-files {
    border-bottom: 1px solid $gray-100;
    margin-bottom: 25px;
    padding-bottom: 10px;
  }

  &__category {
    margin-bottom: 25px;
  }

  &__category-title {
    margin-bottom: 20px;
  }

  &__category-controls {
    padding-left: 20px;
    border-left: 1px solid $gray-100;
  }

  &__control-key {
    width: 50px;
  }

  &__control-title {
    margin-bottom: 10px;
    font-weight: 600;
  }

  &__control-wrapper {
    display: flex;
  }
}
