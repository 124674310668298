@import '/src/ui/index.scss';

.okta-tab {
  padding: 20px;

  &__title {
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  &__description {
    line-height: $font-28;
    font-weight: $font-light;
  }

  &__forms-wrapper {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    h4 {
      text-transform: uppercase;
    }
  }

  &__left-form {
    flex-basis: 350px;
    border-right: 1px solid $gray-250;
    padding-right: 30px;
  }

  &__right-form {
    flex-basis: 350px;
    margin-left: 30px;
  }
}
