@import '/src/ui/index.scss';

// Override antd table styles
body .ui-table {
  .ant-table {
    background: transparent;
  }

  .ant-table-tbody {
    background: $white;
  }

  .ant-table-thead > tr > th {
    background: transparent;
    color: $gray-400;
    font-size: 11px;
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-title {
    flex: initial;
  }

  .ant-table-column-sorter {
    margin-left: 8px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }

  .ant-table-thead > tr > th {
    border-bottom: 2px solid $gray-100;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 10px;
  }

  td.ant-table-column-sort {
    background: $white;
  }

  .ant-table-thead > tr > th {
    padding: 9px 16px 7px;
  }

  .ant-pagination-options {
    .ant-select-selector {
      height: 32px !important;
      margin: 0 !important;

      .ant-select-selection-item {
        margin-top: 0;
      }
    }
  }

  .ant-table-tbody > tr:hover > td {
    background: $blue-100;
  }

  &--cursor-default {
    .ant-table-tbody > tr:hover > td {
      cursor: default;
    }
  }

  .ant-table-tbody > tr:nth-child(odd):not(.ant-table-row-selected) > td {
    background: $gray-50;
  }

  .ant-table-tbody > tr:nth-child(odd):not(.ant-table-row-selected):hover > td {
    background: $blue-100;
  }

  .ant-pagination {
    @mixin pagination-item-theme() {
      border-color: $purple-200;
      color: $purple-200;

      a {
        color: $purple-200;
      }
    }

    li,
    .ant-pagination-item-link {
      background-color: $gray-50;
      border-radius: $border-radius-xs;

      &:hover {
        @include pagination-item-theme();
      }
    }

    .ant-pagination-item-active {
      @include pagination-item-theme();
    }

    .anticon-double-right {
      color: $purple-200;
    }
  }

  &__loader {
    width: 96px;
    height: 96px;
    margin: -48px !important;
    background: transparent;
  }
}
