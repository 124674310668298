@import '/src/ui/index.scss';

.file-type-button {
  height: 107px !important;
  width: 192px;
  border-radius: 5px !important;
  border-color: $gray-200 !important;
  position: relative;

  img {
    width: 48px;
    height: 48px;
    margin: auto;
    position: absolute;
    right: 70px;
    top: 18px;
  }

  &__content {
    color: $black-100;
    font-weight: $font-semi-bold;
    font-size: $font-14;
    font-family: Montserrat, sans-serif !important;
    text-transform: none;
    margin-top: 58px;
  }

  &--selected {
    border: 2px solid $blue-500 !important;
  }
}
