@import '../../ui';

.collab-emails-list {
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  &__dates {
    min-width: 77px;
  }

  &__col-right {
    margin-left: 15px;
  }

  &__title {
    text-transform: uppercase;
    font-size: 10px;
    text-align: left;
    font-weight: $font-medium;
    opacity: 0.7;
  }
  & .ant-tooltip-content {
    background: #00000033 !important;
  }
}
