@import '/src/ui/index.scss';

body .assessment-chat-button {
  .ui-secondary-button__loader {
    margin-right: 8px;
  }

  &.assessment-chat-button--with-unread-messages .ui-icon {
    margin-right: 7px;

    &:after {
      left: 7px;
      top: -2px;
    }
  }

  &.assessment-chat-button--many-unread-messages .ui-icon:after {
    font-size: 9px;
    border-radius: 16px;
    width: 16px;
    height: 16px;
    left: 6px;
    top: -2px;
  }

  &.assessment-chat-button--very-many-unread-messages .ui-icon:after {
    font-size: 8px;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    left: 5px;
    top: -3px;
  }

  &.assessment-chat-button--with-unread-messages.ui-secondary-button--tooltip {
    .ui-icon:before {
      width: 21px;
      text-align: left;
      display: inline-block;
    }
  }

  &.assessment-chat-button--many-unread-messages.ui-secondary-button--tooltip {
    .ui-icon:before {
      width: 22px;
      text-align: left;
      display: inline-block;
    }
  }

  &.assessment-chat-button--very-many-unread-messages.ui-secondary-button--tooltip {
    .ui-icon:before {
      width: 23px;
      text-align: left;
      display: inline-block;
    }
  }
}
