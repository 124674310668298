@import '/src/ui/index.scss';

$search-icon-size: 36px;

body .editor-subject-list {
  background: $white;
  padding: 20px 35px 20px 12px;
  position: sticky;
  top: $headerHeight;

  &__header {
    margin-bottom: 8px;
  }

  &__header-title {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 55px;
  }

  &__header-title-text {
    font-weight: 600;
  }

  &__subjects-header {
    display: flex;
    justify-content: space-between;
  }

  &__subjects-header-cell {
    font-size: 11px;
    color: $gray-400;
    font-weight: 500;

    &--name {
      width: 70%;
      padding-left: 28px;
    }

    &--weight {
      width: 30%;
      padding-left: 10px;
      margin-right: 20px;
    }
  }

  &__subjects:not(.editor-subject-list__subjects--is-subject-dragging) {
    .editor-subject-list-subject:hover:not(.editor-subject-list-subject--active) {
      background: $blue-50 !important;
      color: $black-100;
      cursor: pointer;

      .editor-subject-list-subject__drag-icon-wrapper {
        opacity: 1;

        .editor-subject-list-subject__drag-icon {
          &:hover {
            color: $blue-500;
          }
        }
      }
    }
  }

  &__subject {
    padding: 8px 12px;
    color: $black-100;
    width: 100%;
    height: auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:nth-child(odd):not(.editor-subject-list__subject--selected, :hover, :focus, :active) {
      background: $gray-50;
    }

    &:hover {
      background: $blue-50 !important;
      color: $black-100;
      cursor: pointer;
    }

    &:focus,
    &:active {
      background: $white;
      color: $black-100;

      &:nth-child(odd) {
        background: $gray-50;
      }
    }

    &--selected {
      background: $blue-50 !important;
      font-weight: $font-semi-bold;
    }

    &--active {
      border: 1px solid $blue-500 !important;
      border-left: 4px solid $blue-500 !important;
      padding-left: 10px;
      background: $white !important;

      &:hover {
        border: 1px solid $blue-500 !important;
        border-left: 4px solid $blue-500 !important;
        background: $white !important;
      }
    }
  }

  &__subject-name {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &--active-edit {
      text-overflow: unset;
    }
  }

  &__subject-weight {
    width: 20%;
    padding-left: 10px;
  }

  &__add-subject-button {
    font-size: $font-12 !important;
    margin-top: 15px;
  }
}
