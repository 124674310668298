@import '/src/ui/index.scss';

.ui-checkbox {
  display: flex;

  & input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    height: 0;
    cursor: pointer;
    display: inline-block;
  }

  &__label {
    transition: color 0.2s, border-color 0.2s, box-shadow 0.2s;
    font-size: 13px;
    background-color: #fff;
    border: 1px solid $gray-200;
    padding: px2rem(13);
    position: relative;
    cursor: pointer;
  }

  &--checked {
    label {
      font-weight: $font-semi-bold;
      border-color: $blue-500;
      border: 2px solid $blue-500;
    }
  }

  &--mode-label {
    &:hover label {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    }
  }

  &--mode-checkbox {
    display: flex;

    input[type='checkbox'] {
      all: unset;
      opacity: 1;
      border: 1px solid $gray-200;
      background: #fff;
      width: 20px;
      height: 20px;
      position: relative;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block;

      &::before {
        font-family: 'icomoon';
        content: '\e91f';
        color: $white;
        font-size: 10px;
        background: $blue-500;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 4px;
        left: 50%;
        top: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: opacity 0.2s;
      }

      &:checked::before {
        opacity: 1;
      }

      &:disabled:before {
        background: $gray-250;
        cursor: not-allowed;
      }
    }

    .ui-checkbox__label {
      background: transparent;
      border: 0;
      padding: 0 10px;
      font-size: 14px;
    }

    &.ui-checkbox--checked {
      label {
        font-weight: $font-bold;
        color: $black-100;
        border: 0;
      }
    }

    &.ui-checkbox--disabled {
      label {
        color: $gray-250;
        cursor: not-allowed;
      }
    }
  }

  &--color-pink {
    input[type='checkbox']:not(:disabled) {
      &::before {
        background: $pink-500;
      }
    }
  }

  &--color-green {
    input[type='checkbox']:not(:disabled) {
      &::before {
        background: $Turquoise-400;
      }
    }
  }
}
