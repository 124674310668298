@import '/src/ui/index.scss';

body .assessment-finalize-complete {
  &__image {
    margin-bottom: 20px;
  }

  &__text {
    font-size: $font-20;
    font-weight: 600;
    padding: 0 20px;
    margin-bottom: 20px;
  }

  &__footer {
    border: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__footer-ok-button,
  &__footer-cancel-button {
    margin-bottom: 15px;
  }

  &__sentence-container {
    margin-bottom: 20px;
  }
}
