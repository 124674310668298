@import '/src/ui/index.scss';

body .assessments-filters-filter-archive {
  .ant-radio-button-wrapper {
    color: $gray-400;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $blue-500;
    border-color: $blue-500;
    background: $blue-50;
    font-weight: 600;
  }

  .ant-radio-button-wrapper:hover {
    color: $blue-500;
  }

  .ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
  }
}
