@import '/src/ui/index.scss';

$column-size: 450px;

.card-with-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  &__logo {
    height: 100%;
    width: $column-size;
    object-fit: cover;
  }

  &__card {
    display: grid;
    grid-template-columns: repeat(2, $column-size);
  }

  &__content {
    padding: 64px 48px;
    position: relative;
  }

  &__left-area {
    position: relative;
    min-height: 600px;
  }

  &__back-button {
    position: absolute !important;
    right: 365px;
    top: 16px;
    font-size: $font-16 !important;
    display: flex !important;
    align-items: baseline !important;
  }

  &__back-icon {
    font-size: $font-12 !important;
    padding: 5px !important;
    margin-bottom: 5px !important;
  }

  &__title {
    font-size: $font-28;
    font-weight: 600;
    margin-bottom: 0.5em;
  }
}
