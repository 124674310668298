@import '/src/ui/index.scss';

body .editor-app-triggers-modal {
  &__modal {
    top: 40px;

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__footer-ok-button {
    width: 120px;
  }

  &__header {
    margin-bottom: 20px;
    padding: 24px 30px 14px;
  }

  &__header-title {
    font-size: $font-20;
    margin: 0;
  }

  &__header-description {
    margin-bottom: 8px;
  }

  &__services {
    display: flex;
    gap: 20px;
    padding: 0 30px;
    margin-bottom: 100px;
    flex-wrap: wrap;
  }

  &__service-icon-wrap {
    height: 40px;
    margin-bottom: 10px;
    margin-top: 1px;
  }

  &__service {
    border-radius: 5px;
    color: $black-100;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 125px;
    width: 125px;
    border: 1px solid $gray-100;
    padding: 20px 5px 0;
    white-space: normal;
    transition: none;

    &:hover,
    &:focus {
      border: 1px solid $gray-250;
      color: $black-100;
      box-shadow: $box-shadow-2;
    }

    &--selected {
      border: 2px solid $blue-500;

      &:hover,
      &:focus {
        border: 2px solid $blue-500;
        box-shadow: none;
      }

      .editor-app-triggers-modal__service-icon-wrap {
        margin-top: 0;
      }
    }

    &:disabled {
      border: 1px solid $gray-100;
      &:hover,
      &:focus {
        border: 1px solid $gray-100;
      }

      .editor-app-triggers-modal__service-icon-wrap {
        opacity: 0.7;
      }
    }
  }

  &__service-icon {
    width: 30px;
    display: block;
    margin-right: 5px;
  }

  &__service-findings-cloud-monitoring-icon,
  &__service-risk-recon-icon {
    width: 40px;
  }

  &__service-cloudhawk-icon {
    width: 45px;
  }

  &__service-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__apps-loader {
    margin: 0 auto 40px;
  }
}
