@import '/src/ui/index.scss';

body .assessment-finalized-finding {
  background: $gray-60;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;

  &__finding-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__finding-header-text {
    font-size: $font-16;
    font-weight: 600;
    margin: 0;
  }

  &__question-wrapper {
    display: flex;
    align-items: baseline;
    gap: 5px;
    margin-bottom: 10px;
    margin-right: 30px;

    p {
      margin: 0;
    }
  }

  &__question-label,
  &__answer-label {
    width: 85px;
    color: $gray-400;
    font-size: 11px;
    font-weight: 500;
  }

  &__question,
  &__answer {
    width: 100%;
    font-size: 13px;
    margin-top: -3px;
    display: flex;
    align-items: center;
  }

  &__answer {
    .ui-radio-group,
    .ui-checkbox-group__children {
      flex-direction: column;
      gap: 5px;
    }
  }

  &__answer-wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 20px;
  }

  &__description-wrapper {
    margin-bottom: 10px;

    &--app-description {
      background-color: $white;
      max-height: 140px;
      padding: 18px;
      overflow-y: auto;
      border: 1px solid $gray-200;
      border-radius: 4px;

      ul {
        list-style: none;
        padding: 0;

        li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }

          span {
            &.app-severity-key {
              margin-right: 40px;
              color: $black-100;
              font-size: 14px;
              font-weight: $font-normal;
            }
            &.app-severity-value {
              font-size: 13px;
              font-weight: $font-semi-bold;

              &.severity-level-1 {
                color: $green-400;
              }
              &.severity-level-2 {
                color: $orange-300;
              }
              &.severity-level-3 {
                color: $red-500;
              }
              &.severity-level-4 {
                color: $black-100;
              }
            }
          }
        }
      }
    }
  }

  &__control-wrapper {
    min-width: 300px;
  }
}
