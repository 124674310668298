@import '/src/ui/index.scss';

.customer-manage {
  margin: px2rem(30.5) px2rem(27.5);
  .ui-tab-pane {
    position: relative;
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    & svg {
      margin-right: 1rem;
    }
  }

  &__header-icon {
    font-size: 13px;
    padding: 6px;
    margin-right: 1rem;
    color: $white;
    background: $Turquoise-400;
    border-radius: 24px;
  }

  &__tabs {
    margin-top: px2rem(30);

    & > .ui-tabs > .ant-tabs > .ant-tabs-nav {
      width: 100%;
      & > .ant-tabs-nav-wrap {
        justify-content: center;
      }
    }
  }

  &__content {
    padding: px2rem(37) px2rem(45) px2rem(80);
    background-color: $white;
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    & .tab-header {
      margin-bottom: px2rem(30);
    }

    & .control {
      margin-top: 20px;

      & .ui-input,
      & .ui-select {
        max-width: 333px;
      }
    }
    & .ui-radio-group {
      margin-top: px2rem(4);
    }
  }
  & form {
    display: grid;
    grid-template-columns: (333px 333px);
    column-gap: 157px;
    align-items: baseline;
  }

  &__confirm-modal {
    .ant-modal-footer {
      justify-content: space-around;
      flex-direction: row-reverse !important;
    }
  }
}
