@import '/src/ui/index.scss';

body .assessment-show-report {
  &__viewer-not-found {
    text-align: center;
    margin-top: 50px;
    font-size: 1.5em;
  }

  &__pdf-container {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
  }
}
