@import '/src/ui/index.scss';

body .assessment-category-navigation {
  background: $white;
  padding: 10px 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  &__back {
    margin-right: auto;
    color: $gray-500;
    display: flex;

    .icon-left-arrow {
      margin-right: 7px;
      font-size: 16px;
      color: $gray-500;
    }
  }

  &__next {
    display: flex;

    .icon-right-arrow {
      margin-left: 7px;
      font-size: 16px;
    }
  }
}
