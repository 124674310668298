@import '/src/ui/index.scss';

.ui-popup {
  .ant-modal-header {
    border: none;
    padding: 20px 20px 12px;
  }

  .ant-modal-title {
    margin-left: 25px;
    font-size: $font-20;
    line-height: 25px;
    display: flex;
    align-items: center;

    .ui-icon,
    svg {
      margin-right: 10px;
      font-size: 24px;
    }
  }

  &__title-icon {
    position: absolute;
    top: 18px;
  }

  &__confirmation {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__remove-popup-content {
    text-align: center;
    white-space: pre-line;
    width: 250px;
    margin: auto;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    border: none;
  }

  &--no-title-margin {
    .ant-modal-title {
      margin-left: 0px !important;
    }
    .ant-modal-header {
      border-bottom: 1px solid $gray-200;
    }
  }

  &--fullscreen {
    height: 100vh !important;
    width: 100vw !important;
    margin: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    max-width: 100vw !important;

    .ant-modal-body {
      height: calc(100vh - 110px);
    }
    .ant-modal-content {
      height: 100vh !important;
      display: block;
      position: fixed;
      width: 100vw !important;
    }
  }
}
