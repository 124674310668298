@import '../../ui';

.uploaded-files-preview {
  background: $white;
  padding: 18px;
  border: 1px solid $gray-200;
  border-radius: 4px;

  span {
    font-weight: $font-bold;
    font-size: 14px;
  }
}
