@import '/src/ui/index.scss';

.my-files-upload-item-dropdown {
  &__button {
    width: 40px;
    height: 40px;
    padding: 4px 8px;
    border: 1px solid $gray-250;
    background: white;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black-100;
    transition: all 0.2s;
    position: absolute;
    right: 13px;
    top: 13px;

    &--selected {
      background: $blue-250;
      color: $blue-700;
    }

    &:hover {
      background-color: $blue-100;

      svg {
        color: $blue-700;
      }
    }

    &:focus,
    &:active {
      background-color: $blue-250;

      svg {
        color: $blue-700;
      }
    }
  }
}

.my-files-upload-item-dropdown__menu {
  width: 205px;
  background: $white;
  padding: 8px 0;
  border: 1px solid $gray-250;
  border-radius: 4px;

  .ant-dropdown-menu-item {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    & svg {
      margin-right: 8px;
    }

    &:hover {
      background: $blue-100;
    }
  }
}
