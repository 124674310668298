@import '/src/ui/index.scss';

body .editor-duplicate-finding {
  position: fixed;
  bottom: 20px;
  left: $menuHeight;
  right: 0;
  z-index: 1;

  &__wrapper {
    margin-left: 30%;
    display: flex;
  }

  &__action {
    background: $black-100;
    margin: 0 auto;
    border-radius: 7px;
    transition: $regular-transition;
    display: flex;
    align-items: center;
    padding: 0 8px 0 12px;

    &:hover {
      background: $gray-600;
    }
  }

  &__text {
    color: $white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    display: inline-block;

    &:after {
      content: '';
      border-left: 1px solid $white;
      margin-left: 6px;
    }
  }

  &__close-button {
    color: $white;
    display: flex;
    align-items: center;
    padding: 4px 4px;

    &:before {
    }

    &:hover,
    &:focus,
    &:active {
      color: $gray-50;
    }
  }
}

.menu-collapsed {
  .editor-duplicate-finding {
    left: 61px;
  }
}
