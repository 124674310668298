@import '/src/ui/index.scss';

body .overview-findings {
  flex-grow: 2;

  &__items {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__counter-wrapper {
    margin-left: 15px;
  }

  &__counter-label {
    text-transform: uppercase;
    font-size: $font-11;
    font-weight: 500;
    margin-bottom: 4px;
  }

  &__counter {
    font-size: $font-20;
    font-weight: 600;
    line-height: 18px;
  }
}
