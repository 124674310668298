@import '../../ui';

body .pending-unassigned-assessments-add-organization-modal {
  &__body {
    margin-bottom: 24px;
  }

  &__footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}
