@import '../../../ui';

.vendors-list-status {
  &__badge {
    font-size: 28px;
  }
  &__badge--red {
    color: $red-500;
  }
  &__badge--orange {
    color: $orange-300;
  }
  &__badge--green {
    color: $green-400;
  }

  &__badge-wrapper {
    display: flex;
    gap: 5px;
    line-height: 1.2;
  }

  &__badge-score {
    font-weight: 600;
    font-size: $font-12;
  }

  &__badge-text {
    font-size: $font-12;
  }
  &__empty {
    color: $gray-400;
  }
}
