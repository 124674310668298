@import '/src/ui/animations.scss';

$loader-size: 100px;
$loader-border: 2px solid transparent;
$transition: width 0.5s ease-in-out, height 0.5s ease-in-out, opacity 0.3s 0.5s ease-in-out,
  visibility 0.3s 0.5s ease-in-out;

@mixin inner-loader-mixin($size, $animation-time, $top-color) {
  animation: spin $animation-time linear infinite;
  border: $loader-border;
  border-radius: 50%;
  border-top-color: $top-color;
  bottom: $size;
  content: '';
  left: $size;
  position: absolute;
  right: $size;
  top: $size;
}

.loader {
  background: rgba(255, 255, 255, 0.8);
  margin: auto;
  transition: $transition;
  display: inline-block;

  &--with-backdrop {
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    left: 0;
    position: absolute;
    z-index: 900;
  }
}

.preloader {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.inner-loader {
  animation: spin 2s linear infinite;
  border: $loader-border;
  border-radius: 50%;
  height: $loader-size;
  position: relative;
  width: $loader-size;
  display: block;
  border-top-color: #43c5b2;

  &:before {
    @include inner-loader-mixin(5px, 3s, #402f62);
  }

  &:after {
    @include inner-loader-mixin(15px, 1.5s, #fd4579);
  }
}
