@import '/src/ui/index.scss';

.ui-doughnut-chart-container {
  position: relative;
  margin: auto;
  margin-top: 10%;
  min-height: 210px;
  &__content {
    position: absolute;
    left: 50%;
    top: 53%; // doughnut chart in chartJS has initial padding on top which cannot be deleted
    transform: translateX(-50%) translateY(-50%);
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    color: #333333;
  }
}
