@import '/src/ui/index.scss';

@mixin beforeIcon() {
  font-family: 'icomoon';
  content: '\e950';
  position: absolute;
  right: 15px;
  top: 20px;
  color: $improve-icon;
  cursor: pointer;
  z-index: 1;
}

@mixin textAreaAudit() {
  &:before {
    @include beforeIcon();
  }
  & .ui-textarea-control {
    background: $audit-finding-bg !important;
  }
}

body .assessment-add-finding {
  &__description--audit {
    position: relative;
    @include textAreaAudit();
  }

  &__modal {
    & .assessment-recommendations__recommendation-description {
      &--audit {
        position: relative;
        @include textAreaAudit();
      }
    }

    & .assessment-recommendations__recommendation-due-date {
      &--audit {
        & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background: $audit-finding-bg !important;
        }
      }
    }

    & .assessment-recommendations__recommendation-force-evidence {
      &--audit {
        & .ui-input-control {
          background: $audit-finding-bg !important;
        }
        & .ui-input-control::before {
          background: $improve-icon !important;
        }
      }
    }

    .ant-modal-footer {
      flex-direction: row;
      justify-content: space-between;
    }

    & .ui-textarea-control {
      padding-right: 38px;
    }

    & .assessment-add-finding__statuses {
      &--impact-audit {
        & .impact-select .ant-select-selector {
          background: $audit-finding-bg !important;
        }
      }
      &--probability-audit {
        & .probability-select .ant-select-selector {
          background: $audit-finding-bg !important;
        }
      }
    }
  }

  &__top {
    background: $gray-60;
    padding: 20px;
    margin-bottom: 20px;
  }

  &__finding-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__finding-header-text {
    font-size: $font-16;
    font-weight: 600;
    margin: 0;
  }

  &__question-wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;

    p {
      margin: 0;
    }
  }

  &__question-label,
  &__answer-label {
    width: 85px;
    color: $gray-400;
    font-size: 11px;
    font-weight: 500;
  }

  &__question,
  &__answer {
    width: 100%;
    font-size: 13px;
    margin-top: -2px;
  }

  &__answer-wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 20px;
  }

  &__question-select {
    margin-bottom: 15px;

    .ui-select__label {
      margin-bottom: 8px;
    }

    .ant-select {
      min-width: 250px;
    }
  }

  &__statuses {
    display: flex;
    justify-content: space-between;
  }

  &__description-wrapper {
    textarea {
      background-color: $white;
    }
  }
}
