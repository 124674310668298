@import '/src/ui/index.scss';

body .editor-back-to-follow-up-question {
  position: fixed;
  bottom: 20px;
  left: $menuHeight;
  right: 0;
  z-index: 1;

  &__wrapper {
    margin-left: 30%;
    display: flex;
  }

  &__action {
    background: $black-100;
    margin: 0 auto;
    border-radius: 7px;
    transition: $regular-transition;
    display: flex;

    &:hover {
      background: $gray-600;
    }
  }

  &__back-button {
    color: $white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
      color: $gray-50;
    }

    .ui-icon {
      margin-right: 5px;
    }
  }

  &__close-button {
    color: $white;
    display: flex;
    align-items: center;

    &:before {
    }

    &:hover,
    &:focus,
    &:active {
      color: $gray-50;
    }
  }
}

.menu-collapsed {
  .editor-back-to-follow-up-question {
    left: 61px;
  }
}
