@import '/src/ui/index.scss';

.metadata-item {
  margin-bottom: 2px;
  display: flex;

  &__title {
    width: 250px;
  }

  &__datepicker {
    display: inline-block;
    background-color: $gray-50;
    border-radius: 4px;
    padding: 4px 8px;
  }

  &__value {
    position: relative;
    display: flex;

    &:hover {
      .metadata-item__value-input {
        border-radius: 4px;
        border: 1px dashed $gray-200;
        cursor: pointer;
      }

      .metadata-item__value-remove {
        display: inline-block;
      }
    }
  }

  &__value-input {
    border: 1px solid transparent;
    padding: 4px 8px;
    min-width: 230px;
    display: inline-block;
  }

  &__value-remove {
    padding: 8px 4px;
    align-self: center;
    display: none;
    color: $red-600;
    cursor: pointer;

    &:hover {
      color: $red-400;
    }

    &--show {
      display: block;
    }
  }
}
