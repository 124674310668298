@import '/src/ui/index.scss';

body .ui-filter {
  min-width: 250px;
  display: flex;
  margin-right: 10px;

  .ui-select {
    flex-grow: 1;
  }

  .ant-select-selector {
    display: flex;
    position: relative;
    align-items: center;
    background-color: $gray-60 !important;
    border-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
      left: auto;
      right: 25px;
    }

    &:before {
      margin-right: 5px;
    }
  }

  .ant-select-selector:before {
    content: attr(data-label);
  }

  &--vendorStatus {
    width: auto;
    max-width: 400px;
  }

  &--user {
    .ant-select-selection-search {
      margin-left: 40px;
    }
  }

  &--questionFinding {
    .ant-select-selection-search {
      margin-left: 131px;
    }
    .ant-select-selector {
      min-width: 220px;
    }
  }

  &--message {
    .ant-select-selection-search {
      margin-left: 70px;
    }
  }

  &--archive {
    .ant-select-selector {
      border-radius: 4px !important;
    }
  }

  &--risk {
    .ui-select {
      width: 190px;
    }
  }

  &__close-button {
    color: $gray-400;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover,
    &:focus {
      background: $gray-60;
      border: 1px solid #d9d9d9;
      color: $black-100;
      opacity: 0.8;
    }
  }

  &--archive {
    min-width: unset;

    .ant-select-selector {
      background-color: $white !important;
    }
  }
}
