@import '/src/ui/index.scss';

body .recent-page-findings-risk {
  color: $gray-400;
  font-weight: 500;
  display: flex;
  align-items: center;

  .icon-flag {
    font-size: 20px;
    margin-right: 5px;
  }

  &--low {
    color: $green-400;
  }
  &--medium {
    color: $orange-300;
  }
  &--high {
    color: $red-500;
  }
  &--critical {
    color: $black-500;
  }
  &--showStopper {
    color: $black-500;
  }

  &__label {
    font-size: 11px;
    color: $black-100;
    font-weight: 600;
    margin-right: 5px;
  }
}
