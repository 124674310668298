@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?272o7w');
  src:  url('fonts/icomoon.eot?272o7w#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?272o7w') format('truetype'),
    url('fonts/icomoon.woff?272o7w') format('woff'),
    url('fonts/icomoon.svg?272o7w#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-notes:before {
  content: "\e96a";
}
.icon-not-shared:before {
  content: "\e968";
}
.icon-cloud:before {
  content: "\e969";
}
.icon-send:before {
  content: "\e967";
}
.icon-invite-member:before {
  content: "\e964";
}
.icon-file:before {
  content: "\e965";
}
.icon-clock2:before {
  content: "\e966";
}
.icon-arrow-down-2:before {
  content: "\e962";
}
.icon-arrow-up:before {
  content: "\e963";
}
.icon-bo-empty-state:before {
  content: "\e961";
}
.icon-more2:before {
  content: "\e960";
}
.icon-add4:before {
  content: "\e95f";
}
.icon-not-specified:before {
  content: "\e959";
}
.icon-close4:before {
  content: "\e95a";
}
.icon-ok4:before {
  content: "\e95b";
}
.icon-vendor-bo-request-declined:before {
  content: "\e95c";
}
.icon-vendor-assessment-in-progress:before {
  content: "\e95d";
}
.icon-vendor-pending-request:before {
  content: "\e95e";
}
.icon-apps:before {
  content: "\e957";
}
.icon-no-scan:before {
  content: "\e958";
}
.icon-answer-repository:before {
  content: "\e956";
}
.icon-clock:before {
  content: "\e955";
}
.icon-copy:before {
  content: "\e954";
}
.icon-sparkle:before {
  content: "\e950";
}
.icon-vote-down-filled:before {
  content: "\e951";
}
.icon-vote-down:before {
  content: "\e952";
}
.icon-vote-up-filled:before {
  content: "\e953";
}
.icon-revert:before {
  content: "\e94f";
}
.icon-date-outline:before {
  content: "\e94e";
}
.icon-archive:before {
  content: "\e94c";
}
.icon-restore:before {
  content: "\e94d";
}
.icon-left-arrow2:before {
  content: "\e94a";
}
.icon-right-arrow2:before {
  content: "\e94b";
}
.icon-xlsx:before {
  content: "\e949";
}
.icon-trusted-badge:before {
  content: "\e948";
}
.icon-suggestion:before {
  content: "\e947";
}
.icon-showcase:before {
  content: "\e946";
}
.icon-create-showcase:before {
  content: "\e945";
}
.icon-findings-page:before {
  content: "\e944";
}
.icon-finding:before {
  content: "\e943";
}
.icon-external-link:before {
  content: "\e942";
}
.icon-download:before {
  content: "\e941";
}
.icon-exclamation:before {
  content: "\e940";
}
.icon-ok2:before {
  content: "\e93f";
}
.icon-overdue-assessments:before {
  content: "\e93e";
}
.icon-drag:before {
  content: "\e93d";
}
.icon-trigger:before {
  content: "\e93c";
}
.icon-go-to-file:before {
  content: "\e939";
}
.icon-linked-question-mark:before {
  content: "\e93a";
}
.icon-settings:before {
  content: "\e93b";
}
.icon-add3:before {
  content: "\e936";
}
.icon-multi-line-text:before {
  content: "\e937";
}
.icon-single-line-text:before {
  content: "\e938";
}
.icon-multi-select:before {
  content: "\e933";
}
.icon-radio-button:before {
  content: "\e934";
}
.icon-drop-down:before {
  content: "\e935";
}
.icon-eye-disabled:before {
  content: "\e931";
}
.icon-eye2:before {
  content: "\e932";
}
.icon-upload2:before {
  content: "\e92f";
}
.icon-edit2:before {
  content: "\e930";
}
.icon-image:before {
  content: "\e911";
}
.icon-info2:before {
  content: "\e92e";
}
.icon-add-user:before {
  content: "\e92c";
}
.icon-share:before {
  content: "\e92d";
}
.icon-findings:before {
  content: "\e92b";
}
.icon-back:before {
  content: "\e929";
}
.icon-more:before {
  content: "\e92a";
}
.icon-add2:before {
  content: "\e928";
}
.icon-close3:before {
  content: "\e927";
}
.icon-note:before {
  content: "\e924";
}
.icon-message:before {
  content: "\e925";
}
.icon-upload:before {
  content: "\e926";
}
.icon-check2:before {
  content: "\e922";
}
.icon-check:before {
  content: "\e923";
}
.icon-symlink:before {
  content: "\e921";
}
.icon-close2:before {
  content: "\e920";
}
.icon-ok:before {
  content: "\e91f";
}
.icon-disabled:before {
  content: "\e91d";
}
.icon-add:before {
  content: "\e91e";
}
.icon-flag:before {
  content: "\e91c";
}
.icon-remove:before {
  content: "\e918";
}
.icon-plus2:before {
  content: "\e919";
}
.icon-edit:before {
  content: "\e91a";
}
.icon-assign:before {
  content: "\e91b";
}
.icon-close:before {
  content: "\e916";
}
.icon-arrow-down2:before {
  content: "\e917";
}
.icon-filter:before {
  content: "\e915";
}
.icon-date:before {
  content: "\e914";
}
.icon-logout:before {
  content: "\e912";
}
.icon-organization2:before {
  content: "\e913";
}
.icon-organization:before {
  content: "\e910";
}
.icon-plus:before {
  content: "\e90f";
}
.icon-arrow-down:before {
  content: "\e906";
}
.icon-messages:before {
  content: "\e907";
}
.icon-bell:before {
  content: "\e908";
}
.icon-campaigns:before {
  content: "\e909";
}
.icon-document-with-list:before {
  content: "\e90a";
}
.icon-checklist:before {
  content: "\e90b";
}
.icon-vendors:before {
  content: "\e90c";
}
.icon-overview:before {
  content: "\e90d";
}
.icon-chart:before {
  content: "\e90e";
}
.icon-lock:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e904";
}
.icon-left-arrow:before {
  content: "\e900";
}
.icon-right-arrow:before {
  content: "\e901";
}
.icon-eye:before {
  content: "\e902";
}
.icon-info:before {
  content: "\e903";
}
