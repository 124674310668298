@import '/src/ui/index.scss';

.password-tab {
  display: flex;

  &__left {
    padding-right: 50px;
    border-right: 1px solid $gray-60;
  }

  &__right {
    padding-left: 50px;
  }

  &__radio-group {
    display: flex;
    flex-direction: column;
  }

  &__alert {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__alert-icon {
    margin-right: 10px;
  }
}
