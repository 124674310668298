@import '/src/ui/index.scss';

body .assessment-finalized-findings {
  position: sticky;
  top: 95px;
  overflow-y: scroll;
  height: 95vh;

  &__header-title {
    margin-bottom: 10px;
  }

  &-add-finding__button {
    color: $blue-500;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 4px;
    background-color: $white;

    &:hover,
    &:focus {
      color: $blue-700;
    }

    .ui-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .ui-select {
    margin-bottom: 20px;

    .ant-select {
      width: 100%;

      .ant-select-selector:before {
        font-size: $font-13;
        color: $black-100;
        font-weight: $font-medium;
      }
    }
  }

  &__header-title-text {
    font-weight: 600;
  }
  &__header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__recommendations {
    color: $black-100;
    border-radius: 20px;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
  }

  .ant-table-row.selected {
    .ant-table-cell {
      background: $blue-50 !important;
    }
  }

  &__findings-table {
    margin-bottom: 15px;
  }
}
