// Theme variables will be written here

$s-128: 128px;
$s-64: 64px;
$s-48: 48px;
$s-36: 36px;
$s-24: 24px;
$s-18: 18px;
$s-12: 12px;
$s-8: 8px;
$s-6: 6px;
$s-4: 4px;

$font-46: 46px;
$font-35: 35px;
$font-32: 32px;
$font-30: 30px;
$font-28: 28px;
$font-26: 26px;
$font-24: 24px;
$font-20: 20px;
$font-18: 18px;
$font-16: 16px;
$font-15: 15px;
$font-14: 14px;
$font-13: 13px;
$font-12: 12px;
$font-11: 11px;
$font-10: 10px;
$font-9: 9px;

$font-thin: 100;
$font-extra-light: 200;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;
$font-black: 900;

$transition-timing: 0.2s;

$darken-hover-multiplier: 10%;
$darken-active-multiplier: 5%;
$lighten-inactive-multiplier: 15%;

$xs: 25px;
$sm: 35px;
$md: 40px;
$lg: 48px;

$border-radius-xs: 4px;
$border-radius-lg: 30px;

$box-shadow: 0px 12px 30px rgb(0 0 0 / 6%), 0px 2.43384px 7.30152px rgb(0 0 0 / 4%),
  0px 0px 1.21692px rgb(0 0 0 / 4%);

$box-shadow-2: 0 5px 14px 0 rgba(0, 0, 0, 0.08);
$box-shadow-3: 0 0 4px 0 rgba(0, 0, 0, 0.16);
$box-shadow-4: 0 2px 4px rgba(0, 0, 0, 0.12);

$headerHeight: 64px;
$menuHeight: 165px;
