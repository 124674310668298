@import '/src/ui/index.scss';

$max-container-height: calc((48px * 5) + (0.5rem * 5) + 40px);

.share-showcase-tab {
  grid-template-columns: 1fr !important;

  *::-webkit-scrollbar {
    background-color: $white;
    width: 3px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $purple-200;
    border-radius: 20px;
    border: 3px solid $purple-200;
  }

  & .control {
    max-height: $max-container-height;
    height: 100%;
    overflow: scroll;
  }

  & .ui-checkbox-group__children {
    overflow: hidden;
  }

  &__info {
    &-title {
      font-size: $font-15;
      font-weight: $font-semi-bold;
    }

    &-desc {
      font-size: $font-14;
      font-weight: $font-normal;
      line-height: 21px;
    }
  }

  &__empty {
    display: flex;
    width: 100%;
    padding: 24px;
    justify-content: center;
    align-items: center;
    background: $gray-50;
    margin-top: 16px;

    &-icon {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      font-size: $font-14;
      font-weight: $font-normal;
    }
  }

  &__date-picker {
    margin-top: 30px;
    &-input {
      display: block;
      width: 255px;
    }
    &-label {
      font-weight: $font-semi-bold;
      font-size: $font-15;
    }
  }
}
