@import '/src/ui/index.scss';

body .editor-answer-bank-answer-type-button {
  color: $black-100;
  min-width: 70px;
  display: inline-block;
  padding: 0 12px;

  &.ui-button--blue-outline {
    color: $blue-500;
  }

  .ui-button__before-icon {
    margin-right: 4px;
    font-size: 20px;
  }

  &__counter {
    background: $blue-500;
    color: $white;
    border-radius: 20px;
    font-size: 11px;
    margin-left: 5px;
    padding: 3px 6px;
  }
}
