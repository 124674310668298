@import '/src/ui/index.scss';

body .base-layout-profile-menu-organization-ico {
  width: 33px;
  height: 33px;
  background: $gray-200;
  color: $gray-300;
  font-size: 25px;
  border-radius: 36px;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
  }

  &--size-medium {
    width: 22px;
    height: 22px;
    font-size: 16px;

    &:before {
      position: absolute;
      top: 7px;
      left: 0;
      right: 0;
    }
  }
}
