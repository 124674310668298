@import '/src/ui/index.scss';

$max-container-height: calc((48px * 5) + (0.5rem * 5) + 40px);

.assign-assessment-tab {
  &__header-title {
    font-size: $font-15;
    font-weight: $font-semi-bold;
    color: $text-color;
    margin: 0;
  }

  &__form {
    grid-template-columns: 1fr !important;
  }

  *::-webkit-scrollbar {
    background-color: $white;
    width: 3px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $purple-200;
    border-radius: 20px;
    border: 3px solid $purple-200;
  }

  & .control {
    max-height: $max-container-height;
    height: 100%;
    overflow: scroll;
  }

  & .ui-checkbox-group__children {
    overflow: hidden;
  }

  &__vendor-auto-assessment-period {
    padding-left: 1px;
  }
}
