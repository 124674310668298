@import '/src/ui/index.scss';

.organization-security-tab {

  &__title {
    font-size: $font-16;
    font-weight: 600;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
  }

  &__title-icon {
    margin-right: 10px;
    font-size: 20px;
  }

  &__settings {
    width: 400px;
    padding-bottom: 30px;
  }

  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }

  &__label-inner {
    display: flex;
    align-items: center;
    width: 365px;
  }

  &__label-tooltip-text {
    margin-left: 7px;
  }

  &__label-tooltip-wrapper {
    display: flex;
    align-items: center;
  }
}
