@import '/src/ui/index.scss';

body .editor-subject-header {
  border-bottom: 1px solid $gray-200;
  margin-bottom: 20px;
  padding: 14px 30px;
  display: flex;
  align-items: center;

  &__left {
    width: 80%;
    padding-right: 10px;
  }

  &__title-wrapper {
    display: flex;
  }

  &__key {
    font-size: $font-20;
    font-weight: 600;
    padding: 11px 0;
  }

  &__title {
    width: 100%;

    .editable-content__content,
    .ui-textarea-control {
      font-size: $font-20;
      line-height: 1.5;
      color: $black-100;
      padding: 11px;
      font-weight: 600;
    }

    .editable-content__loader {
      top: 14px;
    }
  }

  &__description {
    .editable-content__content,
    .editable-content__editable {
      font-size: 13px;
      line-height: 1.5;
      color: $gray-400;
    }
  }

  &__weight-selector {
    width: 180px;

    .ant-select .ant-select-selector:before {
      font-weight: normal;
    }
  }

  &__benchmark-selector {
    width: 280px;
    margin-left: 10px;

    .ant-select .ant-select-selector:before {
      font-weight: normal;
    }
  }
}
