@import '/src/ui/index.scss';

body .assessment-sent {
  &__modal {
    .ant-modal-body {
      text-align: center;
      padding: 0 40px;
    }

    &-text {
      font-size: $font-20;
      font-weight: 500;
      margin-bottom: 0;
    }

    &-description {
      font-size: $font-14;
      font-weight: 500;
      color: $gray-500;
    }

    &-vendor-name {
      font-size: $font-16;
      font-weight: 500;
    }

    &-picture {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  &__footer-ok-button {
    margin-bottom: 15px;
  }
}
