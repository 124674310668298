@import '../../ui';
.showcases {
  padding: 1.875rem;
  position: relative;
  & .ant-table-content {
    min-height: 45vh;
  }
  &__wrapper {
    background: $white;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
  }

  &__header {
    margin: 0 !important;
  }
  &__subtitle {
    font-size: $font-14;
    color: $black-100;
    margin-bottom: 20px;
  }
  &-list {
    & .ui-button--sm {
      line-height: 25px;
      align-items: center;
    }
    &__button {
      .ui-button__before-icon {
        height: 20px;
        font-size: $font-20;
      }
    }
    &__empty-state {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;
      cursor: initial;
    }
    &__empty-state-button {
      margin-top: 30px;
      font-size: $font-12;
      color: $blue-500;
      margin-bottom: 60px;
    }
    &__empty-state-text {
      color: $black-100;
      width: 680px;
      white-space: pre-line;
    }
    &-actions {
      padding: 20px 30px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .ui-input-control--search {
        max-width: 247px;
        display: flex;
      }
    }
  }
}
