@import '../../../ui/index';

.no-internet-connection {
  background: $red-500;
  padding: 13px 16px;
  border-radius: 5px;
  color: $white;
  font-size: $font-14;
  font-weight: $font-semi-bold;
  margin: 18px 0;
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
}
