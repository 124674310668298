//White:
$white: #ffffff;

//Black:
$black-100: #262626;
$black-500: #000000;
$text-color: $black-100;

//Pink:
$pink-100: #ffedf3;
$pink-200: #fce7ef;
$pink-300: #fdd4e2;
$pink-400: #f76f9f;
$pink-500: #ef3c76;
$pink-600: #df2357;
$pink-700: #c2143e;

//Turquoise:
$Turquoise-100: #f0fcfb;
$Turquoise-200: #9ef1e3;
$Turquoise-300: #66e2d1;
$Turquoise-400: #32beb0;
$Turquoise-500: #26b0a3;
$Turquoise-600: #158c85;
$Turquoise-700: #15706b;

//Blue:
$blue-50: #f0f8fd;
$blue-100: #e4f5ff;
$blue-200: #e1f0fd;
$blue-250: #d4e9fd;
$blue-300: #cbeafd;
$blue-400: #85c5e5;
$blue-450: #6bccec;
$blue-500: #349ed4;
$blue-700: #106c9b;
$blue-800: #185274;

//Dark Blue:
$dark-blue-300: #4d7de7;
$dark-blue-400: #4c5fbd;
$dark-blue-600: #3f4874;
$dark-blue-800: #272d4b;

//Purple:
$purple-50: #f8f6fc;
$purple-100: #efe8fd;
$purple-150: #e6d3ff;
$purple-175: #956ee7;
$purple-200: #744fc0;
$purple-300: #6346a2;
$purple-400: #4d3280;
$purple-500: #38245c;

//Gray:
$gray-50: #fafafa;
$gray-60: #f7f7f7;
$gray-75: #f5f5f5;
$gray-100: #ededed;
$gray-200: #e0dede;
$gray-250: #bdbdbd;
$gray-300: #9c9c9c;
$gray-400: #828282;
$gray-500: #5f6061;
$gray-600: #464648;

//Red:
$red-50: #fdf4f3;
$red-100: #ffe9e7;
$red-200: #f8d0cc;
$red-300: #f7b1aa;
$red-400: #ed8275;
$red-450: #fa6050;
$red-500: #e45647;
$red-600: #d14031;
$red-700: #922d22;

$error: $red-500;

// Green:
$green-50: #f8ffef;
$green-100: #e7f7d0;
$green-200: #d0efa7;
$green-300: #b1e274;
$green-400: #88ce37;
$green-500: #74b82a;
$green-600: #58921e;
$green-700: #44701b;

//Orange:
$orange-50: #fff8f0;
$orange-100: #ffe7c6;
$orange-200: #ffcb65;
$orange-300: #ffa742;
$orange-400: #ff8d20;
$orange-500: #f96707;
$orange-600: #dd4402;

// upstream ai
$explain-in-popup-hover: #fad7e3;
$explain-default-border: #ff99ba;
$explain-default-bg: #ffe4ed;
$explain-feedback: #fff7fa;

$example-in-popup-hover: #ffead1;
$example-default-border: #fac055;
$example-default-bg: #ffead1;
$example-hover-bg: #ffe2c0;
$example-icon: #ffa500;
$example-feedback: #fef8f0;

$improve-in-popup-hover: #f1e7ff;
$improve-default-border: #b176ff;
$improve-hover-border: #744fc0;
$improve-default-bg: #f1e7ff;
$improve-hover-bg: #e6d3ff;
$improve-icon: #9747ff;
$improve-feedback: #f8f2ff;

$audit-finding-bg: #f9f5ff;

// Exports:

:export {
  gray400: $gray-400;
  white: $white;
  black100: $black-100;
  black500: $black-500;
  textColor: $text-color;
  pink100: $pink-100;
  pink200: $pink-200;
  pink300: $pink-300;
  pink400: $pink-400;
  pink500: $pink-500;
  pink600: $pink-600;
  pink700: $pink-700;
  turquoise100: $Turquoise-100;
  turquoise200: $Turquoise-200;
  turquoise300: $Turquoise-300;
  turquoise400: $Turquoise-400;
  turquoise500: $Turquoise-500;
  turquoise600: $Turquoise-600;
  turquoise700: $Turquoise-700;
  blue50: $blue-50;
  blue100: $blue-100;
  blue200: $blue-200;
  blue250: $blue-250;
  blue300: $blue-300;
  blue400: $blue-400;
  blue450: $blue-450;
  blue500: $blue-500;
  blue700: $blue-700;
  blue800: $blue-800;
  darkBlue300: $dark-blue-300;
  darkBlue400: $dark-blue-400;
  darkBlue600: $dark-blue-600;
  darkBlue800: $dark-blue-800;
  purple50: $purple-50;
  purple100: $purple-100;
  purple150: $purple-150;
  purple175: $purple-175;
  purple200: $purple-200;
  purple300: $purple-300;
  purple400: $purple-400;
  purple500: $purple-500;
  gray50: $gray-50;
  gray60: $gray-60;
  gray75: $gray-75;
  gray100: $gray-100;
  gray200: $gray-200;
  gray250: $gray-250;
  gray300: $gray-300;
  gray400: $gray-400;
  gray500: $gray-500;
  gray600: $gray-600;
  red50: $red-50;
  red100: $red-100;
  red200: $red-200;
  red300: $red-300;
  red400: $red-400;
  red450: $red-450;
  red500: $red-500;
  red600: $red-600;
  red700: $red-700;
  error: $error;
  green50: $green-50;
  green100: $green-100;
  green200: $green-200;
  green300: $green-300;
  green400: $green-400;
  green500: $green-500;
  green600: $green-600;
  green700: $green-700;
  orange50: $orange-50;
  orange100: $orange-100;
  orange200: $orange-200;
  orange300: $orange-300;
  orange400: $orange-400;
  orange500: $orange-500;
  orange600: $orange-600;
  explainInPopupHover: $explain-in-popup-hover;
  explainDefaultBorder: $explain-default-border;
  explainDefaultBg: $explain-default-bg;
  explainFeedback: $explain-feedback;
  exampleInPopupHover: $example-in-popup-hover;
  exampleDefaultBorder: $example-default-border;
  exampleDefaultBg: $example-default-bg;
  exampleHoverBg: $example-hover-bg;
  exampleIcon: $example-icon;
  exampleFeedback: $example-feedback;
  improveInPopupHover: $improve-in-popup-hover;
  improveDefaultBorder: $improve-default-border;
  improveHoverBorder: $improve-hover-border;
  improveDefaultBg: $improve-default-bg;
  improveHoverBg: $improve-hover-bg;
  improveIcon: $improve-icon;
  improveFeedback: $improve-feedback;
  auditFindingBg: $audit-finding-bg;
}
