@import '/src/ui/index.scss';

body .assessment-finalized-categories-view-findings {
  color: $white;
  background: $red-500;
  border-radius: 20px;
  width: 18px;
  height: 18px;
  line-height: 16px;
  font-weight: 600;
  font-size: $font-12;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    background: $blue-500;
    color: $white;
  }
}
