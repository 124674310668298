@import '/src/ui/index.scss';

body .recent-page-evidences {
  margin-bottom: 55px;

  .ant-table-tbody {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
  }

  .ant-table-tbody > tr:nth-child(odd):not(.ant-table-row-selected, :hover) > td {
    background: $white;
  }

  .ant-table-tbody tr {
    cursor: pointer;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__page-subheader {
    display: flex;
    margin: 0;
    .page-subheader__title {
      font-size: $font-20;
    }
  }

  &__table-link {
    color: $black-100;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $gray-400;
    }
  }

  &__expiration-date-ico,
  &__issued-date-ico {
    color: $gray-400;
    margin-right: 8px;
  }

  &__expiration-date,
  &__issued-date {
    color: $black-100;
    display: flex;
    align-items: center;

    &--expired {
      color: $error;

      .recent-page-evidences__expiration-date-ico {
        color: $error;
      }
    }
  }
}
