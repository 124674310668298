@import '/src/ui/index.scss';

.personal-data-tab {
  display: flex;
  padding-bottom: 100px;

  &__form-area-left {
    margin-right: 50px;
    flex-basis: 300px;
  }
  &__form-area-right {
    margin-right: 50px;
    flex-basis: 300px;
  }
}
