@import '/src/ui/index.scss';

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &__container {
    position: relative;
  }

  &__content {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    & .ui-button {
      margin-top: px2rem(41);
    }

    & .ui-text {
      font-weight: $font-medium;
    }
  }

  &__404 {
    font-size: px2rem(206);
    line-height: px2rem(200);
    font-weight: $font-extra-bold;
    color: $purple-400;
  }
}
