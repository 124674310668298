@import '/src/ui/index.scss';

$max-width: 173px;

.drop-wrapper {
  width: 100%;
  padding: 3px;
  border: 1px dashed $gray-300;
  border-radius: 4px;
  background: $gray-50;
  position: relative;

  .upload-files {
    background: #fff;
    padding: 18px;

    &__support {
      font-weight: $font-light;
    }

    &__button {
      margin-top: px2rem(11);
    }

    &__title {
      font-size: $font-16;
      font-weight: $font-semi-bold;
    }

    &__items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-block: px2rem(12);
      gap: px2rem(13);
    }

    &__confirm-modal {
      .ant-modal-body {
        text-align: center;
        font-size: $font-20;
        font-weight: 600;
      }
    }
  }
}

.upload-files__confirm-modal {
  .ant-modal-body {
    text-align: center;
    font-size: $font-20;
    font-weight: 600;
  }

  &-trash {
    margin-bottom: 10px;
  }
}

.file-upload-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-200;
  padding-block: px2rem(11);
  max-width: $max-width;
  height: 76px;
  position: relative;
  justify-content: center;
  align-items: center;

  &__body {
    display: flex;
    flex-flow: column;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
  }

  &__edit-icon {
    position: absolute;
    right: 30px;
    top: 0px;
    color: $gray-400;
    cursor: pointer;
  }

  &__remove-icon {
    position: absolute;
    right: 10px;
    top: 0px;
    color: $gray-400;
    cursor: pointer;
    z-index: 1;
  }

  &__file {
    position: relative;
    height: 37px;
  }

  &__name {
    font-size: 11px;
    color: $gray-400;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: $max-width;
    padding: 0 px2rem(5);
  }
}
