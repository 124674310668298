@import '/src/ui/index.scss';

$area-size: 254px;

.my-files-upload-file {
  width: $area-size;
  height: $area-size;
  padding: 0 24px 0 24px;
  border-radius: 4px;
  border: 1px dashed $blue-500;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 254px;
  max-width: 254px;

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
    text-transform: uppercase;

    & svg {
      margin-bottom: 12px;
    }
  }

  .max-size-info {
    margin-top: 8px;
    color: $gray-400;
    line-height: 21px;
  }
}
