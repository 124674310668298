@import '../../ui';

.copy-button {
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border: 1px solid #ededed;
  color: $blue-500;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-24;

  &:hover {
    background: $blue-100;
  }
}
