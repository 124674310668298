@import '/src/ui/index.scss';

body .my-files-popup {
  &__modal {
    top: 60px;
    position: relative;

    .ant-modal-footer {
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 15px 25px;
      box-shadow: 1px -3px 13px -5px rgba(0, 0, 0, 0.05);
      border-top: 1px $gray-200 solid;

      .ui-button {
        text-transform: uppercase;
        margin: 0;
      }
    }

    .ant-modal-body {
      padding: 0;
    }
  }

  &__header {
    padding: 24px;
    border-bottom: 1px solid $gray-200;
  }

  &__title {
    font-size: $font-28;
    margin-bottom: 2px;
  }

  &__description {
    margin-bottom: 25px;
  }

  &__body {
    position: relative;
    height: calc(100vh - 400px);

    .my-files-list {
      height: 100%;
    }
  }

  &__file-image {
    margin: auto;

    svg {
      width: 55px;
      height: auto;
    }
  }
}
