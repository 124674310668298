@import '/src/ui/index.scss';

body .recent-page-vendors {
  margin-bottom: 55px;

  &__orgname {
    font-size: 11px;
    color: $gray-400;
    font-weight: $font-semi-bold;
    margin-bottom: 4px;
  }

  &__table {
    .ant-table-tbody tr {
      cursor: initial;
    }
  }

  .ant-table-tbody {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
  }

  .ant-table-tbody > tr:nth-child(odd):not(.ant-table-row-selected, :hover) > td {
    background: $white;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__page-subheader {
    display: flex;
    margin: 0;

    .page-subheader__title {
      font-size: $font-20;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
  }

  &__view-all-button {
    margin-right: 25px;
    font-weight: $font-semi-bold;
  }

  &__assign-button {
    padding: 0px 20px 0px 16px;
    min-width: auto;

    .ui-button__before-icon {
      margin-right: 6px;
    }
  }

  &__table-link {
    color: $black-100;

    .ui-icon {
      vertical-align: middle;
      line-height: 1.1;
      margin-right: 3px;
      font-size: 18px;
    }

    &:hover,
    &:focus {
      color: $blue-700;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  &__onboarding-not-completed {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: $orange-300;
    display: inline-block;
    margin-right: 4px;
  }

  &__company-name {
    font-weight: $font-medium;
  }

  &__findings {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__findings-empty-link {
    color: $black-100;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  &__findings-link {
    color: $black-100;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    &:hover,
    &:focus {
      color: $blue-700 !important;
      font-weight: 500;
    }

    .recent-page-vendors__findings-link-findings {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
