@import '/src/ui/index.scss';

body .apps-tab {
  &__table {
    margin-top: 30px;

    .ant-table-thead .ant-table-cell {
      text-transform: uppercase;
    }
  }

  &__service-icon {
    width: 24px;
    height: 27px;
    padding: 2px;
    margin-right: 10px;
  }

  &__table-configuration {
    color: $gray-400;
  }

  &__table-header {
    font-size: $font-11;
  }

  &__default-apps-table-container {
    margin-bottom: 50px;
  }

  &__status-icon {
    color: $green-400;
    font-size: 20px;
  }

  &__status-enabled {
    background-color: $green-400;
  }

  &__form {
    display: flex;
    align-items: center;
    padding-left: 380px;
  }

  &__send-button {
    padding: 0 30px;
    margin-left: 5px;
  }

  &__ssc-api-key-input,
  &__ibm-username-input,
  &__ibm-password-input {
    width: 200px;
  }

  &__ibm-password-input {
    margin-left: 5px;
  }
}
