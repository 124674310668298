@import '/src/ui/index.scss';

.base-layout-placeholder {
  font-size: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $dark-blue-400;
}
