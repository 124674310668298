@import '/src/ui/index.scss';

.answer-repository-answers {
  &__container,
  &__empty {
    width: 50%;
  }
  &__answer {
    user-select: none !important;
    border-bottom: 1px solid $gray-200;
    padding: 5px;

    &:hover {
      cursor: pointer;
      background-color: $blue-50;
    }

    &.selected {
      background: $blue-200;
    }

    &.disabled {
      cursor: not-allowed;
      color: $gray-400;
      background: $white;
    }
  }
}
