@import '/src/ui/index.scss';

body .base-layout-add-button {
  display: flex;
  margin-left: 20px;

  &__button {
    box-shadow: 0px 4px 16px rgba(90, 22, 44, 0.16);
    background: $pink-500;
    color: $white;
    font-size: 20px;
    position: relative;
    border-radius: 36px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;

    &:hover,
    &:focus,
    &.ant-dropdown-open {
      background: $pink-600;
      color: $white;
    }
  }

  &__menu-item {
  }

  &__menu-item-ico {
    color: $purple-400 !important;
    width: 20px;
    font-size: 18px !important;
    text-align: center;
  }
}
