@import '/src/ui/index.scss';

.ui-file-upload {
  .ui-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    border: none;
    border-radius: 4px;
    color: $blue-500;

    & svg {
      margin-right: px2rem(5);
    }

    &:hover {
      background-color: $blue-50;
      color: $blue-700;
    }

    &:active {
      background-color: $blue-250;
    }
  }
}
