@import '/src/ui/index.scss';

body .assessment-finalized-recommendation {
  margin-bottom: 20px;
  border-bottom: 1px solid $gray-100;
  padding-bottom: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__description {
    margin-bottom: 10px;

    .ui-textarea-control {
      background: $white;
    }

    &--app-description {
      background-color: $white;
      max-height: 140px;
      padding: 18px;
      overflow-y: auto;
      border: 1px solid $gray-200;
      border-radius: 4px;
      margin-bottom: 20px;

      h5 {
        font-size: $font-14;
        font-weight: $font-semi-bold;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }

          span {
            color: $black-100;
            font-size: $font-14;
            font-weight: $font-normal;

            &.app-recommendation-key {
              margin-right: 10px;
            }
            &.app-recommendation-value {
              font-weight: $font-semi-bold;
            }
          }
        }
      }
    }
  }

  &__note {
    .ui-textarea-control {
      background: $white;
    }
  }

  &__note-wrapper {
    background: $gray-60;
    padding: 15px 15px 10px;
    position: relative;
    margin-bottom: 20px;
  }

  &__note-title {
    font-weight: 600;
    font-size: 15px;
  }

  &__note-close-button {
    color: $gray-400;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 4px 4px;

    &:hover,
    &:focus {
      color: $gray-500;
    }
  }

  &__due-date {
    margin-bottom: 15px;
  }

  &__due-date-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__due-date-label {
    font-size: 11px;
    color: $gray-400;

    .ui-icon {
      margin-right: 5px;
      font-size: 14px;
    }
  }

  &__due-date-text {
    font-weight: 700;
    margin: 0 25px 0 12px;
  }

  &__evidence {
    color: $gray-400;
  }

  &__force-evidence {
    margin-bottom: 25px;
    margin-top: 10px;
  }

  &__action-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }

  &__mark-complete-button {
    margin-left: auto;
  }

  &__approve-button-wrapper {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__pending-status {
    color: $orange-300;
    font-weight: 700;
    font-size: $font-12;
  }

  .ui-select {
    width: 100%;
    margin-bottom: 8px;

    .ant-select-selector {
      height: 48px;
      .ant-select-selection-search-input {
        height: 48px;
      }
    }
  }

  .custom-days-input {
    margin-bottom: 10px;

    .ui-input__label {
      font-size: $font-14;
      font-weight: $font-normal;
    }
  }
}
