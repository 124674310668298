@import '/src/ui/index.scss';

.ui-loader-indicator {
  display: flex;
  justify-content: center;
  align-items: center;

  &__dot {
    display: inline-block;
    width: 14px;
    margin: 0 1px;
    height: 14px;
    border-radius: 50%;
    background-color: $white;
    animation: loadingBounce 1.4s infinite;
  }

  &__first {
    animation-delay: -0.32s;
  }

  &__second {
    animation-delay: -0.16s;
  }
}
