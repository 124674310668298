@import '/src/ui/index.scss';

.card-with-enforce-2fa {
  width: 900px;
  height: 680px;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0;
  background-color: $white;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  &__left-box {
    background-image: url('/assets/images/purple-card-onboarding-background.png');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 72px;
    width: 50%;

    &-content {
      img {
        margin-bottom: 5px;
      }

      p {
        color: $white;
        font-weight: $font-medium;
        font-size: $font-16;
        line-height: 27px;
        margin: 0;

        + p {
          font-weight: $font-semi-bold;
          margin-top: 20px;
          margin-bottom: 8px;
        }
      }
    }
  }

  &__left-box-content-p2 {
    font-size: $font-14 !important;
  }

  &__left-box-content-text {
    width: 274px;
  }

  &__inner {
    position: relative;
    padding: 40px 48px;
    width: 50%;

    h1 {
      &.zero-title {
        font-size: $font-26;
        font-weight: $font-semi-bold;
        margin-top: 20px;
      }

      font-size: $font-20;
      margin-bottom: 20px;
    }

    h2 {
      font-size: $font-18;
      margin-bottom: 15px;
    }
  }
}
