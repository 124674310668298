@import '/src/ui/index.scss';

body .assessment-actions {
  &__button {
    padding: 0 14px;
    display: flex;


    .ui-icon {
      margin-left: 12px;
      font-size: 13px;
    }
  }

  &__menu {
    .ui-icon {
      font-size: 20px;
    }
  }
}
