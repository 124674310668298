@import '../../../ui';

body .assign-showcase {
  padding: 30px 30px 55px;
  position: relative;
  overflow-y: hidden;

  &__empty-state {
    background: $gray-50;
    width: 85vw;
    padding: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--error {
      border: 1px solid $error;
    }
  }

  &__empty-state-error {
    margin-top: 8px;
    color: $error;
    font-size: 13px;
    font-weight: $font-semi-bold;
  }

  &__empty-state-text {
    display: flex;

    .ui-button__name {
      font-size: $font-12 !important;
    }
  }

  &__form {
    margin-top: 60px;
  }

  &__header {
    .ui-icon {
      font-size: 22px;
      margin-right: 10px;
    }
  }

  &__main {
    height: 72vh;
    padding: 30px;
    background: $white;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
    overflow-y: auto;
  }

  &__container {
    width: 720px;
  }

  &__form-label {
    font-weight: 600;
    font-size: $font-15;
  }

  &__templates {
    .ui-expander__button {
      padding: 8px 4px;
      height: 27px;
      margin-top: 5px;
    }
  }

  &__additional-actions {
    display: flex;
    margin: 0 0 13px;
  }

  &__additional-actions-suffix {
    padding: 4px 0;
  }

  &__new-showcase-button,
  &__new-customer-button {
    color: $blue-500;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    padding: 4px 4px;

    &:hover,
    &:focus {
      color: $blue-700;
    }

    .ui-icon {
      font-size: 8px;
      margin-right: 5px;
    }
  }

  &__performed-by-select {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &__new-customer-button {
    margin-bottom: 18px;
  }

  &__customer-select {
    width: 375px;
  }

  &__due-date-picker {
    display: block;
    width: 255px;
  }

  &__error {
    color: $error;
    font-size: 13px;
    font-weight: $font-semi-bold;
  }

  &__actions {
    background: $white;
    padding: 10px 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
}
