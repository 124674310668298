@import '/src/ui/index.scss';

$radio-size: 20px;

.ui-radio {
  display: flex;
  flex-direction: row;
  align-items: self-end;

  & input[type='radio'] {
    all: unset;
    border: 1px solid $gray-200;
    background: #fff;
    width: $radio-size;
    height: $radio-size;
    position: relative;
    border-radius: 50%;
    margin-right: px2rem(16);
    display: inline-block;

    &::before {
      content: '';
      background: $blue-500;
      width: 11px;
      height: 11px;
      display: block;
      position: absolute;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      border: 1px solid $gray-200;
      transition: opacity 0.2s;
    }
    &:checked::before {
      opacity: 1;
    }

    &:disabled {
      color: $gray-250;
      cursor: not-allowed;

      &:before {
        background: $gray-250;
      }
    }
  }

  &__label {
    display: flex;
    font-size: 14px;
    color: $black-100;
    line-height: 150%;
    cursor: pointer;

    &--checked {
      font-weight: $font-bold;
    }

    &--disabled {
      cursor: not-allowed;
      color: $gray-250;
    }
  }
}
