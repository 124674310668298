@import '/src/ui/index.scss';

body .editor-cloud-monitoring-options {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__option {
    display: flex;
  }

  &__key {
    margin-right: 4px;
  }

  &__value {
    width: auto;
    display: flex;
  }
}
