@import '/src/ui/index.scss';

.card-with-logo-footer {
  position: absolute;
  width: 100%;
  box-shadow: 1px -3px 13px -5px rgba(0, 0, 0, 0.05);
  left: 0;
  bottom: 0;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  background: white;

  &__left-button-icon,
  &__right-button-icon {
    font-size: $font-14;
    vertical-align: middle;
  }

  &__left-button-icon {
    margin-right: $s-6;
  }

  &__right-button-icon {
    margin-left: $s-6;
  }

  &__right-button {
    float: right;
    margin-left: 10px;
  }

  &__left-button {
    padding: 10px;
    font-size: $font-11;
    text-transform: uppercase;
  }
}
