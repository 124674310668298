@import '/src/ui/index.scss';

.file-browser {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-block: px2rem(12);
  gap: px2rem(13);
  width: 100%;

  &__file {
    overflow: scroll;
    height: 100%;
    position: relative;

    &__no-preview {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      font-size: $font-14;
      color: $gray-300;
      flex-direction: column;
    }
  }

  &__iframe {
    height: 100%;
    width: 100%;
    border: none;
  }

  &__popup {
    .ant-modal-title {
      margin-left: 40px;
    }
    .ant-modal-content {
      height: 80vh;
    }
    .ant-modal-body {
      height: 70vh;
    }
    .ant-modal-header {
      box-shadow: $box-shadow-2;
      padding-bottom: 20px;
    }
  }

  &__popup-icon {
    color: $black-100;
    font-size: $font-28;
    font-weight: $font-bold;
  }

  &__footer-right-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: px2rem(12);
  }
}

.file-browser-excel-view {
  &__buttons {
    display: flex;
    padding-bottom: 30px;
  }

  &__button {
    margin-right: 10px;
  }
}

.file-browser-doc-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  &__icon {
    margin-top: 20vh;
    font-size: 70px;
    color: $gray-250;
  }

  &__text {
    margin-top: 20px;
    color: $gray-250;
  }
}
