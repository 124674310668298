@import '/src/ui/index.scss';

body .assessment-controls {
  &__cloud-monitoring-controls {
    background: $blue-50;
    border: 1px solid $blue-500;
    border-radius: 4px;
    margin-bottom: 30px;
  }

  &__cloud-monitoring-controls-title {
    padding: 30px 30px 0;
    display: flex;
  }

  &__cloud-monitoring-controls-title-icon {
    margin: 2px 8px 0 0;
    font-size: $font-18;
    color: $gray-400;
  }

  &__cloud-monitoring-controls__wrapper {
    padding: 30px 30px 0;
  }

  &__not-shared-title {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  }

  &__not-shared-title-icon {
    font-size: 30px;
    margin-right: 10px;
  }
}
