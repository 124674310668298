@import '/src/ui/index.scss';

.onboarding-authentication {
  display: block;

  &__title {
    color: $black-100;
    font-size: 15px;
    font-weight: 500;
  }

  &__buttons-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: $s-12;
    padding: 15px 0 0 0;
  }

  &__button {
    color: $black-100;
    text-transform: none;
    font-weight: $font-light;
    padding: 0 12px;

    &.selected {
      border: $purple-200 2px solid;
    }
  }
}
