@import '/src/ui/index.scss';

body .customers-list {
  padding: px2rem(30);
  position: relative;

  &__wrapper {
    background: $white;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
  }

  &__actions {
    padding: 20px 30px;
    display: flex;
    align-items: center;

    .ui-input-control--search {
      width: 240px;
    }
  }

  &__actions-right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__table {
    .ant-table-tbody > tr:nth-child(odd):not(.ant-table-row-selected, :hover) > td {
      background: $white;
    }

    &--disable-row-click {
      .ant-table-tbody tr {
        cursor: initial;
      }
    }
  }
  &__button {
    display: flex;
    align-items: center;
    padding: 0 px2rem(20) 0 px2rem(16);
    min-width: auto;
  }

  &__onboarding-not-completed {
    width: px2rem(10);
    height: px2rem(10);
    border-radius: px2rem(10);
    background: $orange-300;
    display: inline-block;
    margin-right: px2rem(4);
  }

  .ant-pagination {
    padding-right: 16px;
  }
}
