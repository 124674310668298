@import '/src/ui/index.scss';

body .ui-date-picker {
  border-radius: 4px;

  .ant-picker-suffix {
    color: $gray-400;
  }

  &:hover {
    border-color: $gray-200;
  }

  &.ant-picker-focused {
    border-color: $purple-200;
    transition: $regular-transition;
    box-shadow: $purple-200 0 0 0 1px;
  }

  &.ant-picker-large {
    height: 48px;
    display: flex;

    .ant-picker-input {
      font-size: 16px;
    }

    .ant-picker-input > input {
      font-size: 13px;
    }
  }

  &--color-gray:not(.ant-picker-disabled) {
    background: $gray-50 !important;
    border: 1px solid $gray-200 !important;
  }
}
