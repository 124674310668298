@import '/src/ui/index.scss';

body .overview-statuses {
  display: flex;
  gap: 40px;
  margin-bottom: 30px;

  &__status {
    display: flex;
    align-items: center;
  }

  &__status-image-wrapper {
    background: $purple-100;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: 15px;
  }

  &__status-image {
    font-size: 22px;
    color: $purple-500;
  }

  &__status-text {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
  }

  &__status-counter {
    font-size: $font-20;
    font-weight: 600;
    line-height: 20px;
    margin-top: 4px;
  }
}
