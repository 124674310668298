@import '/src/ui/index.scss';

body .recent-page-findings {
  margin-bottom: 55px;

  .ant-table-tbody {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08);
  }

  .ant-table-tbody > tr:nth-child(odd):not(.ant-table-row-selected, :hover) > td {
    background: $white;
  }

  .ant-table-tbody tr {
    cursor: pointer;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__page-subheader {
    display: flex;
    margin: 0;
    .page-subheader__title {
      font-size: $font-20;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
  }

  &__view-all-button {
    margin-right: 25px;
    font-weight: $font-semi-bold;
  }

  .finding-subject {
    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
  }

  .findings-table-assessment-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 350px;

    .ui-tag--blue {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
