@import '/src/ui/index.scss';

body .editor-answer-creator-answer-type-selector {
  width: 230px;
  margin-bottom: 5px;

  &__dropdown {
    .ui-icon {
      font-size: 24px;
      margin-right: 4px;
    }

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;

    .ui-icon {
      font-size: 24px;
      margin-right: 4px;
    }
  }
}
