@import '/src/ui/index.scss';

body .editor-answer-bank {
  &__modal {
    top: 40px;

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
    }
  }

  &__footer-ok-button {
    width: 120px;
  }

  &__footer-cancel-button {
    align-items: center;
  }

  &__header {
    margin-bottom: 20px;
    padding: 24px 24px 14px;
    border-bottom: 1px solid $gray-200;
    box-shadow: 1px 4px 3px 0 rgba(0, 0, 0, 0.07);
  }

  &__header-title {
    font-size: $font-20;
    margin: 0;
  }

  &__header-description {
    margin-bottom: 8px;
  }

  &__list-header {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 7px;
  }

  &__search {
    margin-bottom: 10px;
  }

  &__list-wrapper {
    padding: 0 24px;
  }
}
