@import '/src/ui/index.scss';

body .editor-answer-type {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-200;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;

  * {
    user-select: none;
  }

  &:hover {
    background-color: $blue-100;
    cursor: pointer;
  }

  &--selected {
    background: $blue-50;
    border-color: $blue-500;
  }

  &__option {
    display: flex;
  }

  &__key {
    margin-right: 4px;
  }

  &__radio {
    input[type='radio'] {
      margin-right: 10px;
    }
  }

  &__value {
    width: 90%;
  }

  &__weight {
    background: $gray-50;
    width: 70px;
    border-radius: 4px;
    font-size: $font-12;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;

    &--red {
      color: $red-500;
    }

    &--orange {
      color: $orange-300;
    }

    &--green {
      color: $green-400;
    }
  }
}
