@import '/src/ui/index.scss';

body .assessment-finalized-remove-finding {
  font-size: 24px;
  padding: 0;
  color: $gray-500;
  position: absolute;
  top: 20px;
  right: 20px;

  &.ant-tooltip-disabled-compatible-wrapper {
    display: inline-block;
    height: 32px;
    padding: 0;

    .ant-btn {
      font-size: 24px;
      padding: 0;
      color: $gray-250;
    }
  }

  &__confirm-modal {
    .ant-modal-body {
      text-align: center;
      font-size: $font-20;
      font-weight: 600;
    }

    &-trash {
      margin-bottom: 10px;
    }
  }
}
