@import '/src/ui/index.scss';

.ui-file-list {
  position: relative;
  margin-bottom: 20px;

  &__list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
}
