@import 'ui';

.phone-prefix-select-item {
  text-align: left;
  padding: 2px 8px;
  display: grid;
  grid-template-columns: 20px 25px 1fr;

  &:hover {
    background-color: $gray-60;
    cursor: pointer;
  }

  * {
    color: $black-100;
  }
}

.prefix-icon-container {
  display: flex;
  & .fi {
    margin-right: 5px;
  }
}
