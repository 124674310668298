@import '/src/ui/index.scss';

.info-modal {
  width: 100% !important;
  max-width: 430px !important;

  .ant-modal-content {
    padding: 40px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 16px 24px 0 rgba(0, 0, 0, 0.06);
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 0;
  }

  &__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }


  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: $font-semi-bold;
    color: $text-color;
    gap: 8px;
  }

  &__text{
    margin-top: 16px;
    font-size: $font-14;
    color: $gray-300;
  }

  &__footer{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
