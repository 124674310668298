@import '/src/ui/index.scss';

body .recent-page {
  padding: 30px;

  &__page-header {
    display: flex;
    margin-bottom: 40px;

    .page-header__title {
      display: flex;
      align-items: center;
    }
  }

  &__period-selector {
    width: 150px;
    margin-left: 15px;
  }
}
