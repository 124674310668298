@import '/src/ui/index.scss';

body .page-header {
  margin-bottom: 20px;

  &__title {
    font-size: $font-28;
    color: $black-100;
    font-weight: 600;
    margin: 0;
    display: flex;
    align-items: center;
  }

  &--no-margin {
    margin-bottom: 0;
  }
}
