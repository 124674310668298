@import '../../../ui';

.showcase-preview-assessment {
  .assessment__page_header,
  .assessment-due-date,
  .assessment-status,
  .assessment-category-header__logo,
  .assessment-category-navigation,
  .assessment-actions__button,
  .assessment-categories__category-progress{
    display: none;
  }
  .assessment__right-panel {
    margin-top: 24px;
  }
}
