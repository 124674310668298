@import '/src/ui/index.scss';

body .assessment-recommendations {
  &__recommendation {
    border-bottom: 1px solid $gray-200;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }

  &__recommendation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__recommendation-description,
  &__recommendation-note {
    margin-bottom: 10px;

    textarea {
      background-color: $white;
    }
  }

  &__recommendation-due-date {
    margin-bottom: 20px;

    & .ant-select-selection-item {
      line-height: 13px;
      padding-right: 15px !important;
    }

    .ant-select-selector:before {
      text-transform: none !important;
      font-size: 11px !important;
      font-weight: 500 !important;
    }
  }
  &__recommendation-due-date-custom {
    width: auto;
    margin-bottom: 20px;

    .ui-input__label {
      color: $gray-400;
      font-size: $font-11;
      font-weight: $font-semi-bold;
      text-transform: uppercase;
    }

    input {
      padding: 4px 4px 4px 8px;
      height: 32px;
    }
  }

  &__recommendation-force-evidence {
    margin-bottom: 15px;
  }

  &__recommendation-note-button {
    color: $blue-500;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    padding: 4px 0;

    &:hover,
    &:focus {
      color: $blue-700;
    }

    .ui-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }

  &__add-recommendation-button {
    font-size: 12px;
    padding-left: 0;

    .ui-icon {
      font-size: 18px;
    }
  }
}
