@import '/src/ui/index.scss';

$alphaChanel: 0.8;

.ui-button-loader {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;

  &__indicator {
    align-self: center;
  }

  &--pink {
    background-color: rgba($pink-500, $alphaChanel);
  }

  &--green {
    background-color: rgba($Turquoise-400, $alphaChanel);
  }

  &--purple {
    background-color: rgba($purple-200, $alphaChanel);
  }

  &--blue {
    background-color: $white;

    .ui-loader-indicator {
      &__dot {
        background-color: $blue-700;

        &:nth-of-type(1) {
          opacity: 0.4;
        }
        &:nth-of-type(2) {
          opacity: 0.8;
        }
      }
    }
  }
}
