@import '../../ui';

.table-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-weight: $font-light;
  font-size: $font-11;
  color: $black-100;
  white-space: break-spaces;

  &__icon {
    &--default {
      width: 130px;
      height: auto;
    }

    &--recent img {
      width: 70px;
      height: 70px;
    }
  }

  &__text-wrap {
    &--recent {
      font-size: $font-13;
      color: $gray-400;
    }
  }

  &__text-with-button {
    display: flex;
    align-items: baseline;
  }

  &__button-add {
    text-transform: uppercase;
    font-size: $font-9;
    color: $blue-500;
    margin-left: 5px;
  }
}

// ant override for all empty states
body .ui-table .ant-table-tbody > tr:nth-child(2n + 1):not(.ant-table-row-selected) > td {
  background: #fff;
}
