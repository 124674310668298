@import '/src/ui/index.scss';

body .ui-secondary-button {
  background: $blue-500;
  border: 1px solid $blue-500;
  color: $white;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 7px;
  font-size: 11px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: $regular-transition;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: $blue-700;
    border-color: $blue-700;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &__icon {
    margin-right: 4px;
    font-size: 20px;
  }

  &__icon--with-counter {
    position: relative;

    &:after {
      content: attr(data-counter);
      background: $red-500;
      color: $white;
      font-family: Montserrat, sans-serif !important;
      font-size: 10px;
      border-radius: 14px;
      border: 1px solid $white;
      font-weight: 700;
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 8px;
      top: 0;
    }
  }

  &__loader {
    margin-right: 4px;
  }

  &--outline {
    background: transparent;
    color: $blue-500;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background: transparent;
      color: $blue-700;
      border-color: $blue-700;
    }
  }

  &--link {
    background: transparent;
    border: 0;
    color: $blue-500;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background: $blue-50;
      color: $blue-700;
    }
  }

  &--dashed {
    background: $blue-50;
    border: 1px dashed $blue-500;
    color: $blue-500;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background: $blue-200;
      color: $blue-700;
    }

    &.ui-secondary-button--color-purple {
      background: $purple-50;
      border: 1px dashed $purple-200;
      color: $purple-200;

      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        background: $purple-100;
        color: $purple-300;
      }
    }
  }

  &--size-small {
    padding: 4px 12px;
    font-size: 10px;
  }

  &--size-medium {
    padding: 8px 7px;
    font-size: 11px;
  }

  &--size-large {
    padding: 15px 7px;
    font-size: 13px;
  }

  &--full-width {
    width: 100%;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--tooltip {
    justify-content: left;
    transition: 200ms ease-out;
    overflow: hidden;

    .ui-secondary-button__icon {
      margin-right: 0 !important;
    }

    .ui-secondary-button__tooltip {
      opacity: 0.1;
      overflow: hidden;
      white-space: nowrap;
      transition: 500ms ease-out;
      margin-left: 4px;
    }

    &:hover,
    &:focus {
      .ui-secondary-button__tooltip {
        opacity: 1;
      }
    }
  }
}
