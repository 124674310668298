@import '../../../ui';

body .vendors-list-add-filter {
  margin-left: 20px;

  &__button {
    align-items: center;
    padding: 5px 12px;

    .ui-button__name {
      font-weight: $font-normal;
      margin-left: -5px;
      font-size: 13px;
      margin-top: 2px;
    }

    .ui-button__before-icon {
      font-size: $font-24;
      margin-right: 10px;
      margin-top: 9px;
      .ui-icon {
        font-weight: $font-normal;
      }
    }

    .ui-button__after-icon {
      font-size: $font-24;
      padding: 10px 0 0 5px;
      margin-left: 0px;
    }

    &:active:not(:disabled) {
      transform: none;
    }
  }
}
