@import '/src/ui/index.scss';

body .editor-answer-type-options {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__option {
    display: flex;
  }

  &__key {
    margin-right: 4px;
  }

  &__value {
    width: 70%;
  }

  &__weight {
    background: $gray-50;
    width: 70px;
    border-radius: 4px;
    font-size: $font-12;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;

    &--red {
      color: $red-500;
    }

    &--orange {
      color: $orange-300;
    }

    &--green {
      color: $green-400;
    }
  }
}
